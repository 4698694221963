import { get } from 'lodash';

import { TIPOS_AREAS, TIPOS_ECONOMIAS } from 'containers/AprovacaoProjetos/TabelaAreasAP/constantsAP';

import { LE_Enquadramentos } from './MetadataLE';

const { UNIFAMILIAR_AUTONOMA, UNIFAMILIAR_01_ECONO, UNIFAMILIAR_02_ECONO } = LE_Enquadramentos;

/**
 * Limpa campos ocultos/não utilizados.
 *
 * @param {object} data
 * @param {string} tipoForm
 *
 * @returns {object} Dados corrigidos
 */
export function limparForm(data, tipoForm) {
	let formdados = { ...data };
	const restricoesData = get(data, 'restricoesAdministrativas', data);

	const formUnifamiliarAutonoma = tipoForm === UNIFAMILIAR_AUTONOMA;
	const formUnifamiliarUmaOuDuasEconomias = [UNIFAMILIAR_01_ECONO, UNIFAMILIAR_02_ECONO].includes(tipoForm);

	const delEdificacaoUltrapassaAlturaDECEA = formdados.dmiRestricaoAlturaProximidadeAeroporto === 'nao';
	const delRestricaoAreaNaoEdificavel = formdados.dmiRestricaoPluvial === 'nao';
	const delPrevistoConstrucaoAreaNaoEdificavel = formdados.restricaoAreaNaoEdificavel === 'nao';
	const delQuotaALP = get(restricoesData, 'restricaoALPdoCondominio') === 'nao';
	const delRestricaoTracadoViarioPDDUA = formdados.restricaoTracadoViarioPDDUA === 'nao';
	const delDmiRestricaoAPP = formdados.dmiRestricaoAPP === 'nao';
	const delDmiRestricaoAreaVerde = formdados.dmiRestricaoAreaVerde === 'nao';
	const delDmiRestricaoAreaEscolar = formdados.dmiRestricaoAreaEscolar === 'nao';

	const delPredioTombadoInventariado = formdados.predioTombadoInventariado === 'nao';
	const delPossuiEvuTombado = formdados.classificacaoEdificacao === 'tombado';
	const delPossuiEvuEstruturacao = formdados.classificacaoEdificacao === 'estruturacao';
	const delTerrenoPossuiRuDefinido = formdados.classificacaoEdificacao === 'compatibilizacao';
	const delPossuiEvuCompatibilizacao = formdados.terrenoPossuiRuDefinido === 'nao';

	if (delPossuiEvuTombado) {
		delete formdados.possuiEvuEstruturacao;
		delete formdados.terrenoPossuiRuDefinido;
		delete formdados.possuiEvuCompatibilizacao;
	}
	if (delPossuiEvuEstruturacao) {
		delete formdados.possuiEvuTombado;
		delete formdados.terrenoPossuiRuDefinido;
		delete formdados.possuiEvuCompatibilizacao;
	}
	if (delTerrenoPossuiRuDefinido) {
		delete formdados.possuiEvuTombado;
		delete formdados.possuiEvuEstruturacao;
	}
	if (delPossuiEvuCompatibilizacao) {
		delete formdados.possuiEvuCompatibilizacao;
	}

	if (delPredioTombadoInventariado) {
		delete formdados.classificacaoEdificacao;
		delete formdados.possuiEvuTombado;
		delete formdados.possuiEvuEstruturacao;
		delete formdados.terrenoPossuiRuDefinido;
		delete formdados.possuiEvuCompatibilizacao;
	}

	// restrições em comum
	if (delEdificacaoUltrapassaAlturaDECEA) {
		delete formdados.edificacaoUltrapassaAlturaDECEA;
	}
	if (delRestricaoAreaNaoEdificavel) {
		delete formdados.restricaoAreaNaoEdificavel;
		delete formdados.previstoConstrucaoEmAreaNaoEdificavel;
	}
	if (delPrevistoConstrucaoAreaNaoEdificavel) {
		delete formdados.previstoConstrucaoEmAreaNaoEdificavel;
		delete formdados.previstoMuroEmAreaNaoEdificavel;
	}
	if (delRestricaoTracadoViarioPDDUA) {
		delete formdados.edificacaoIncideTracadoViarioPDDUA;
		delete formdados.possuiEstudoPDDUA;
	}
	if (delDmiRestricaoAPP) {
		delete formdados.construcaoSobreAppApan;
	}
	if (delDmiRestricaoAreaVerde) {
		delete formdados.restricaoEdificacaoAreaVerde;
	}
	if (delDmiRestricaoAreaEscolar) {
		delete formdados.projetoIncideSobreEscola;
	}

	/* 	const areasPlanilha = (get(formdados, 'detalhesAreas.areasPlanilha') || []).reduce((acc, area) => {
		acc[area] = true;
		return acc;
	}, {});

	let novoOuAumento = null;
	if (areasPlanilha.regularizar || areasPlanilha.regularizarGrpddua) {
		novoOuAumento = 'novo_reforma';
	} else if (areasPlanilha.construir) {
		novoOuAumento = 'novo';
	} else if (areasPlanilha.permanecer || areasPlanilha.reciclar) {
		novoOuAumento = 'aumento_diminuicao';
	}

	const isProjetoNovo = novoOuAumento === 'novo';

	if (isProjetoNovo) {
		delete formdados.detalhesAreas?.areaDemolir;
	} */

	// restrições específicas conforme tipo do form
	if (formUnifamiliarAutonoma) {
		if (delQuotaALP) {
			delete formdados.quotaALP;
		}
	} else if (formUnifamiliarUmaOuDuasEconomias) {
		formdados = limpaFormUnifamiliarUmaOuDuasEconomias(formdados, tipoForm);
		formdados = limparInfosProjeto(formdados);
	}
	formdados = limparInfosTerreno(formdados);

	// LIMPEZA DE CAMPOS FILHO DO TIPO FORMULARIO UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES
	(formdados.planoDiretor?.dadosSubunidades || []).forEach(subunidade => {
		if (subunidade.possuiAlturaDivisa === 'nao' && subunidade.alturaDivisaRegimeUrbanisticoAnexo) {
			delete subunidade.alturaDivisaRegimeUrbanisticoAnexo;
		}
		if (subunidade.possuiAlturaBase === 'nao' && subunidade.alturaBaseRegimeUrbanisticoAnexo) {
			delete subunidade.alturaBaseRegimeUrbanisticoAnexo;
		}
	});
	if (formdados.infosProjeto?.projetoImpactoUrbano === 'nao' && formdados.infosProjeto?.projetoViabilidaUrbanistico) {
		delete formdados.infosProjeto.projetoViabilidaUrbanistico;
	}
	if (formdados.infosProjeto?.projetoPossuiBaseCorpo === 'nao' && formdados.infosProjeto?.totalBaseProjeto) {
		delete formdados.infosProjeto.totalBaseProjeto;
	}
	if (formdados.infosProjeto?.projetoPossuiBaseCorpo === 'nao' && formdados.infosProjeto?.totalCorpoProjeto) {
		delete formdados.infosProjeto.totalCorpoProjeto;
	}
	if (formdados.infosProjeto?.possuiVagaCargaDescarga === 'nao' && formdados.infosProjeto?.qtdVagasCargaDescarga) {
		delete formdados.infosProjeto.qtdVagasCargaDescarga;
	}

	return formdados;
}

const limparInfosTerreno = data => {
	let formdados = data;

	const possuiAreaDeMenorPoligono = formdados?.possuiAreaDeMenorPoligono === 'sim';
	const possuiAreaAtingida = formdados?.possuiAreaAtingida === 'sim';
	const possuiAreaAtingidaDeMenorPoligono = formdados?.possuiAreaAtingidaDeMenorPoligono === 'sim';

	if (!possuiAreaDeMenorPoligono) {
		delete formdados.areaMenorPoligonoLote;
		delete formdados.areaMenorPoligonoLoteMais5;
	}
	if (!possuiAreaAtingida) {
		delete formdados.areaAtingidaMatricula;
		delete formdados.areaRemanescenteMatricula;
	}

	if (!possuiAreaDeMenorPoligono || !possuiAreaAtingida) {
		delete formdados.possuiAreaAtingidaDeMenorPoligono;
		delete formdados.areaAtingidaMenorPoligonal;
		delete formdados.areaRemanescenteMenorPoligonal5;
	}

	if (!possuiAreaAtingidaDeMenorPoligono) {
		delete formdados.areaAtingidaMenorPoligonal;
		delete formdados.areaRemanescenteMenorPoligonal5;
	}

	return formdados;
};

const limpaFormUnifamiliarUmaOuDuasEconomias = (localData, tipoForm) => {
	const delEdificacaoIncideTracadoViarioPDDUA = localData?.restricaoTracadoViarioPDDUA === 'nao';
	const delPossuiEstudoPDDUA = localData?.edificacaoIncideTracadoViarioPDDUA === 'nao';
	const delRestricaoEdificacaoAreaVerde = localData?.dmiRestricaoAreaVerde === 'nao';
	const delProjetoIncideSobreEscola = localData?.dmiRestricaoAreaEscolar === 'nao';

	const removerAdensavelNaoAdensavel =
		tipoForm === UNIFAMILIAR_01_ECONO && localData?.infosProjeto?.projetoAtividade?.item !== '1.2.';

	if (removerAdensavelNaoAdensavel) {
		const { detalhesAreas = {} } = localData;
		const { economias = [], areasTotais = {} } = detalhesAreas;
		let totalPorEconomias = 0;
		economias.forEach(economia => {
			const { pavimentos = [] } = economia;
			let totalPorPavimentos = 0;
			pavimentos.forEach(pavimento => {
				const { naturezasEspecies = [] } = pavimento;
				let totalPorNatEsp = 0;
				naturezasEspecies.forEach(natEsp => {
					let totalPorTipoArea = 0;
					TIPOS_AREAS.forEach(areaKey => {
						const area = natEsp[areaKey];
						if (area) {
							Object.keys(area).forEach(tipoEconomiaKey => {
								const tipoEconomia = area[tipoEconomiaKey];
								delete tipoEconomia?.mtAdensavel;
								delete tipoEconomia?.mtNaoAdensavel;
							});
							totalPorTipoArea = parseFloat(area?.residencial?.mtIsenta?.value || '0');
						}
					});
					totalPorNatEsp += totalPorTipoArea;
					natEsp.total = totalPorTipoArea;
				});
				totalPorPavimentos += totalPorNatEsp;
				pavimento.total = totalPorNatEsp;
			});
			totalPorEconomias += totalPorPavimentos;
			economia.total = totalPorPavimentos;
		});
		detalhesAreas.totalPorEconomias = totalPorEconomias;
		let total = 0;
		TIPOS_AREAS.forEach(areaKey => {
			const areaTotal = areasTotais[areaKey];
			if (areaTotal) {
				if (areaTotal) {
					TIPOS_ECONOMIAS.forEach(tipoEconomiaKey => {
						const tipoEconomia = areaTotal[tipoEconomiaKey];
						tipoEconomia.mtAdensavel = { total: 0 };
						tipoEconomia.mtNaoAdensavel = { total: 0 };
						tipoEconomia.total = tipoEconomia?.mtIsenta?.total;
					});
					areaTotal.total = areaTotal?.residencial?.mtIsenta?.total;
				}
				total += areaTotal?.residencial?.mtIsenta?.total;
			}
		});
		areasTotais.total = total;
	}

	if (delPossuiEstudoPDDUA) {
		delete localData.possuiEstudoPDDUA;
	}
	if (delEdificacaoIncideTracadoViarioPDDUA) {
		delete localData.edificacaoIncideTracadoViarioPDDUA;
		delete localData.possuiEstudoPDDUA;
	}
	if (delRestricaoEdificacaoAreaVerde) {
		delete localData.restricaoEdificacaoAreaVerde;
	}
	if (delProjetoIncideSobreEscola) {
		delete localData.projetoIncideSobreEscola;
	}

	return localData;
};

const limparInfosProjeto = data => {
	let formdados = data;
	const isProjetoPossuiBaseCorpo =
		formdados.projetoPossuiBaseCorpo === 'sim' ? true : formdados.projetoPossuiBaseCorpo === 'nao' ? false : undefined;

	if (isProjetoPossuiBaseCorpo === true) {
		delete formdados.taxaOcupacaoTotal;
		delete formdados.taxaOcupacaoTotalPermitido;
	} else if (isProjetoPossuiBaseCorpo === false) {
		delete formdados.totalBaseProjeto;
		delete formdados.totalPermitidoBase;
		delete formdados.totalCorpoProjeto;
		delete formdados.totalPermitidoCorpo;
	}

	return formdados;
};
