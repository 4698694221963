import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { cloneDeep, get, pick, set } from 'lodash';

import CheckboxComponent from 'components/CheckboxComponent';
import Comentario from 'components/Comentario';
import Confirm from 'components/Confirm';
import ErrorMessages from 'components/ErrorMessages';
import HistoricoRespostaAnterior from 'components/HistoricoRespostaAnterior';
import ShowDebug from 'components/ShowDebug';
import TooltipIcon from 'components/TooltipIcon';

import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE';

import { isDebug } from 'utils/tools';

import { includesTipoForm } from './InfosProjetoAP';
import TabelaAreasAP, { calcularTotaisTabelaAreasAP } from './TabelaAreasAP';
import { OPT_AREAS_PLANILHA, UAP_ENQUADRAMENTOS_ENUM } from './TabelaAreasAP/constantsAP';
import ResumoAreasAP from './TabelaAreasAP/ResumoAreasAP';

const { UNIFAMILIAR_AUTONOMA } = UAP_ENQUADRAMENTOS_ENUM;

export function InfosAreasAP({
	data: localData,
	dataAnterior,
	onChangeHandler,
	tipoForm = '',
	errosSubmissao: errosSubmissaoProps,
	disabled = false,
	changeComentarioHandler = null,
	usuarioInterno = false,
	readOnlyVersao = null
}) {
	const isUnidadeAutonoma = includesTipoForm([UNIFAMILIAR_AUTONOMA], tipoForm);

	const errosSubmissao = useMemo(() => {
		if (!errosSubmissaoProps) {
			return [];
		}
		if (Array.isArray(errosSubmissaoProps)) {
			return errosSubmissaoProps;
		}
		let ouput = [];
		Object.keys(errosSubmissaoProps).forEach(key => {
			let newErro = {
				campo: key,
				message: errosSubmissaoProps[key]
			};
			ouput.push(newErro);
		});
		return ouput;
	}, [errosSubmissaoProps]);

	const detalhesAreas = get(localData, 'detalhesAreas', {});

	const [showConfirm, setShowConfirm] = useState(false);
	const [temComentario, setTemComentario] = useState(false);

	const limparTabela = useCallback(() => {
		setShowConfirm(false);
		onChangeHandler && onChangeHandler({ name: 'detalhesAreas', value: {} });
	}, [onChangeHandler]);

	const localChangeHandlerAreasPlanilha = useCallback(
		value => {
			//garante a ordem das colunas da planilha
			let areasPlanilha = [];
			if (value.includes('permanecer')) {
				areasPlanilha.push('permanecer');
			}
			if (value.includes('reciclar')) {
				areasPlanilha.push('reciclar');
			}
			if (value.includes('regularizarGrpddua')) {
				areasPlanilha.push('regularizarGrpddua');
			}
			if (value.includes('regularizar')) {
				areasPlanilha.push('regularizar');
			}
			if (value.includes('construir')) {
				areasPlanilha.push('construir');
			}
			//Se houver novas colunas, deve verificar com cliente se precisa ordernar
			//Por enquanto há apenas as 5 acima
			value.forEach(v => {
				if (!areasPlanilha.includes(v)) {
					areasPlanilha.push(v);
				}
			});

			let alterouAreasSelecionadas = false;

			const areasSelecionadasAntes = get(localData, 'detalhesAreas.areasPlanilha', []);
			areasSelecionadasAntes.forEach(v => {
				if (!value.includes(v)) {
					// removeu área selecionada
					alterouAreasSelecionadas = true;
				}
			});
			value.forEach(v => {
				if (!areasSelecionadasAntes.includes(v)) {
					// adicionou área selecionada
					alterouAreasSelecionadas = true;
				}
			});

			let newDetalhesAreas = cloneDeep(localData.detalhesAreas || {});

			if (alterouAreasSelecionadas) {
				//recalcula os totais para as novas áreas selecionadas
				newDetalhesAreas.areasPlanilha = areasPlanilha;
				newDetalhesAreas = calcularTotaisTabelaAreasAP(newDetalhesAreas);
			}

			areasPlanilha.forEach(v => {
				if (!value.includes(v)) {
					areasPlanilha = areasPlanilha.filter(a => a !== v);
				}
			});

			let economiasAux = cloneDeep(get(newDetalhesAreas, 'economias', []));

			// remove áreas que não estão mais na planilha, cuidando para manter o total
			economiasAux = economiasAux.map(economia => {
				const { pavimentos } = economia;
				return {
					...economia,
					pavimentos: pavimentos.map(pavimento => {
						const { naturezasEspecies } = pavimento;
						return {
							...pavimento,
							naturezasEspecies: (naturezasEspecies || []).map(natEsp => pick(natEsp, [...areasPlanilha, 'total']))
						};
					})
				};
			});

			onChangeHandler &&
				onChangeHandler({
					name: 'detalhesAreas',
					value: { ...newDetalhesAreas, areasPlanilha, economias: economiasAux }
				});
		},
		[localData, onChangeHandler]
	);

	const localChangeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				const [valid] = changes;
				changes = valid;
			}
			const { name: controlName, value } = changes;

			if (controlName === 'errors') return;

			// set dados
			set(detalhesAreas, controlName, value);

			let tempTable = Object.assign({}, detalhesAreas);
			onChangeHandler && onChangeHandler({ name: 'detalhesAreas', value: tempTable });
		},
		[detalhesAreas, onChangeHandler]
	);

	const existenteApermanecerSelecionada = useMemo(
		() => detalhesAreas?.areasPlanilha?.includes('permanecer') || false,
		[detalhesAreas]
	);
	const existenteAreciclarSelecionada = useMemo(
		() => detalhesAreas?.areasPlanilha?.includes('reciclar') || false,
		[detalhesAreas]
	);
	const aRegularizarGrpdduaSelecionada = useMemo(
		() => detalhesAreas?.areasPlanilha?.includes('regularizarGrpddua') || false,
		[detalhesAreas]
	);
	const aRegularizarSelecionada = useMemo(
		() => detalhesAreas?.areasPlanilha?.includes('regularizar') || false,
		[detalhesAreas]
	);
	const aConstruirSelecionada = useMemo(
		() => detalhesAreas?.areasPlanilha?.includes('construir') || false,
		[detalhesAreas]
	);
	const isProjetoNovo = useMemo(
		() =>
			!existenteApermanecerSelecionada &&
			!existenteAreciclarSelecionada &&
			(aRegularizarGrpdduaSelecionada || !aRegularizarSelecionada || aConstruirSelecionada),
		[
			aConstruirSelecionada,
			aRegularizarGrpdduaSelecionada,
			aRegularizarSelecionada,
			existenteApermanecerSelecionada,
			existenteAreciclarSelecionada
		]
	);

	return (
		<fieldset>
			<legend>
				{`${isUnidadeAutonoma ? '4' : '5'}. Áreas do Projeto`}
				<TooltipIcon icon="fa-info-circle" iconColor="rgb(0 0 0 / 50%)">
					EXISTENTE: Edificação que possui HABITE-SE (carta de habitação) ou edificação comprovadamente existente há
					mais de 20 (vinte) anos, pelos registros cadastrais do Município ou por documentos comprobatórios; A
					CONSTRUIR: Quando não há construção nenhuma existente, conforme definição de EXISTENTE, e o requerente
					pretende licenciar para CONSTRUIR; A REGULARIZAR: ÁREAS aprovadas ou não, que foram executadas, mas não
					possuem HABITE-SE, ou ainda não se enquadram no Art.159-B do PDDUA, ou seja, não possuem comprovação de
					existência há mais de 20 anos.'
				</TooltipIcon>
			</legend>
			<div
				className={`${
					temComentario ? 'resposta-com-comentario pin-comentario' : usuarioInterno ? 'resposta-com-comentario' : ''
				}`}
			>
				{typeof changeComentarioHandler === 'function' ? null : (
					<div className="">
						<CheckboxComponent
							label="Selecione os tipos de áreas do seu projeto para preenchimento da planilha"
							name="areasPlanilha"
							value={detalhesAreas.areasPlanilha}
							options={OPT_AREAS_PLANILHA}
							required={true}
							showCodigo={false}
							layoutNovo={true}
							stackOptions={true}
							onChangeHandler={localChangeHandlerAreasPlanilha}
							labelClassName={'formulario-uap'}
							disabled={disabled}
						/>
						<HistoricoRespostaAnterior
							dataAnterior={dataAnterior?.detalhesAreas?.areasPlanilha}
							dataAtual={localData?.detalhesAreas?.areasPlanilha}
							usuarioInterno={usuarioInterno}
						/>
						<ErrorMessages
							errorList={(errosSubmissao || []).filter(e => (e.campo === 'areasPlanilha' ? e.message : null))}
						/>
					</div>
				)}

				{isDebug && <Karneau areasSelecionadas={detalhesAreas?.areasPlanilha} />}

				{/* Totais manuais */}
				<h2 className="legend_secundaria mt-3">Área a Demolir</h2>
				<div className="pergunta metragem">
					<NumberFieldLE
						name="areaDemolir"
						label="Área a Demolir (m²)"
						placeHolder="metragem m²"
						required={false}
						onChangeHandler={localChangeHandler}
						value={detalhesAreas?.areaDemolir}
						decimals={2}
						maxSize="10"
						readOnly={false}
						disabled={disabled}
					/>
					<HistoricoRespostaAnterior
						dataAnterior={dataAnterior?.detalhesAreas?.areaDemolir}
						dataAtual={localData?.detalhesAreas?.areaDemolir}
						usuarioInterno={usuarioInterno}
					/>
				</div>

				{(detalhesAreas?.areasPlanilha?.length || 0) > 0 && (
					<>
						{/* Tabela de areas */}
						<div className="row">
							<div className="col-12 mt-3">
								<TabelaAreasAP
									data={localData}
									dataAnterior={dataAnterior}
									name={'detalhesAreas'}
									onChangeHandler={onChangeHandler}
									tipoForm={tipoForm}
									isProjetoNovo={isProjetoNovo}
									disabled={disabled}
									usuarioInterno={usuarioInterno}
									readOnlyVersao={readOnlyVersao}
								/>
								{
									<span style={{ fontStyle: 'italic' }}>
										Mova a barra de rolagem vertical e horizontal para preencher a planilha
									</span>
								}
							</div>
						</div>
						{(errosSubmissao || []).some(e => e.campo === 'planilhaAreas') && (
							<div className="pergunta">
								<ErrorMessages
									errorList={(errosSubmissao || []).filter(e => (e.campo === 'planilhaAreas' ? e.message : null))}
								/>
							</div>
						)}
					</>
				)}

				{/* Botao de limpeza & confirm dele*/}
				{!disabled && (
					<button type="button" className="btn btn-primary mt-2" onClick={() => setShowConfirm(true)}>
						Limpar tabela <i className="fa fa-eraser"></i>
					</button>
				)}
				<ResumoAreasAP
					data={localData}
					dataAnterior={dataAnterior}
					tipoForm={tipoForm}
					errosSubmissao={errosSubmissao}
					usuarioInterno={usuarioInterno}
				/>
				{!disabled && showConfirm && (
					<Confirm
						loader={false}
						msg="Você deseja apagar todos os dados da tabela?"
						onConfirm={limparTabela}
						onReject={() => setShowConfirm(false)}
					/>
				)}
				<Comentario
					campoComentario={'resumoAreasAPComentario'}
					valueComentario={detalhesAreas?.['resumoAreasAPComentario']}
					onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
					setTemComentario={setTemComentario}
					temComentario={temComentario}
					readOnly={!!readOnlyVersao || !usuarioInterno}
				/>
			</div>
		</fieldset>
	);
}

InfosAreasAP.displayName = 'InfosAreasAP';
InfosAreasAP.propTypes = {
	data: PropTypes.object,
	dataAnterior: PropTypes.object,
	onChangeHandler: PropTypes.func,
	tipoForm: PropTypes.string,
	errosSubmissao: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]),
	disabled: PropTypes.bool,
	changeComentarioHandler: PropTypes.func,
	usuarioInterno: PropTypes.bool,
	readOnlyVersao: PropTypes.any
};

function Karneau({ areasSelecionadas }) {
	const resultados = [
		'nenhum resultado',
		'1 - Construção Nova',
		'2 - Construção Nova / Reforma',
		'3 - Reforma/Aumento e/ou Diminuição'
	];
	const p = areasSelecionadas?.includes('permanecer');
	const rc = areasSelecionadas?.includes('reciclar');
	const rgrp = areasSelecionadas?.includes('regularizarGrpddua');
	const rg = areasSelecionadas?.includes('regularizar');
	const c = areasSelecionadas?.includes('construir');
	const resultado = calcularResultado(p, rc, rgrp, rg, c);
	return (
		<div>
			<h3>Resultado: {resultados[resultado]}</h3>
			<ShowDebug
				data={testarKarneau()}
				console
				label="Apresentar combinações de Áreas da Planilha com tipoProjetoEdificacao"
			/>
		</div>
	);
}
Karneau.displayName = 'Karneau';
Karneau.propTypes = {
	areasSelecionadas: PropTypes.array
};

// Esta função está copiada no fluxo de Registro de Projeto no Expediente
// para determinar o valor da variável novoOuAumento nas caixinhas de função
// "Monta Registro Projeto UAP" e "Monta Registro Projeto ULE", sendo usada
// no fluxo de registra-projeto-licenciamento do expedientes para selecionar o
// tipoProjetoEdificacao:
// 1- Construção Nova;
// 2- Construção Nova / Reforma;
// 3- Reforma/Aumento e/ou Diminuição)
function calcularResultado(p, rc, rgrp, rg, c) {
	const resultado1 =
		(!p && !rc && rgrp && !rg && !c) ||
		(!p && !rc && !rgrp && rg && !c) ||
		(!p && !rc && !rgrp && !rg && c) ||
		(!p && !rc && rgrp && rg && !c) ||
		(!p && !rc && rgrp && !rg && c) ||
		(!p && !rc && !rgrp && rg && c) ||
		(!p && !rc && rgrp && rg && c);

	const resultado2 = false;

	const resultado3 =
		(!p && rc && !rgrp && !rg && !c) ||
		(p && rc && !rgrp && !rg && !c) ||
		(p && !rc && rgrp && !rg && !c) ||
		(p && !rc && !rgrp && rg && !c) ||
		(p && !rc && !rgrp && !rg && c) ||
		(!p && rc && rgrp && !rg && !c) ||
		(!p && rc && !rgrp && rg && !c) ||
		(!p && rc && !rgrp && !rg && c) ||
		(p && rc && rgrp && !rg && !c) ||
		(p && !rc && rgrp && rg && !c) ||
		(p && !rc && rgrp && !rg && c) ||
		(p && rc && !rgrp && rg && !c) ||
		(p && rc && !rgrp && !rg && c) ||
		(p && !rc && !rgrp && rg && c) ||
		(!p && rc && rgrp && rg && !c) ||
		(!p && rc && !rgrp && rg && c) ||
		(!p && rc && rgrp && !rg && c) ||
		(p && rc && rgrp && rg && !c) ||
		(p && !rc && rgrp && rg && c) ||
		(p && rc && !rgrp && rg && c) ||
		(p && rc && rgrp && !rg && c) ||
		(!p && rc && rgrp && rg && c) ||
		(p && rc && rgrp && rg && c);

	// Determinar o resultado final
	if (resultado1) {
		return 1; // Resultado 1
	} else if (resultado2) {
		return 2; // Resultado 2
	} else if (resultado3) {
		return 3; // Resultado 3
	}

	return 0; // Caso nenhum resultado seja verdadeiro
}

function testarKarneau() {
	const resultados = [0, 1, 2, 3];
	const valores = [false, true];
	const saida = [];
	resultados.forEach(r => {
		saida.push(`Combinações para Resultado ${r}:`);
		valores.forEach(p => {
			valores.forEach(rc => {
				valores.forEach(rgrp => {
					valores.forEach(rg => {
						valores.forEach(c => {
							const resultado = calcularResultado(p, rc, rgrp, rg, c);
							if (resultado === r) {
								saida.push(
									`${p ? '_p' : '!p'}, ${rc ? '_rc' : '!rc'}, ${rgrp ? '_rgrp' : '!rgrp'}, ${rg ? '_rg' : '!rg'}, ${
										c ? '_c' : '!c'
									}`
								);
							}
						});
					});
				});
			});
		});
	});
	return saida;
}
