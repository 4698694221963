import React from 'react';

import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';

import TooltipIcon from 'components/TooltipIcon';
import WarningMessage from 'components/WarningMessage';

import { isDebug } from 'utils/tools';

const RadioFieldLE = ({
	label,
	sublabel,
	required,
	name,
	value,
	options,
	readOnly,
	disabled,
	onChangeHandler,
	radioStack,
	labelEnum,
	tooltip,
	hasExternalLink,
	externalLink,
	externalLinkText,
	observacao,
	children
}) => {
	const onChangeRadio = e => {
		const { value: codigo } = e.target;
		onChangeHandler({ name, value: codigo });
		onChangeHandler({ name: 'errors', value: { [name]: validate(codigo, required, label) } });
	};
	return (
		<>
			<div className="d-flex" style={{ width: '100%' }}>
				<label>
					{labelEnum || label} {isDebug && name && <span className="debug-message">({name})</span>}
					{required && <span className="required">*</span>}
					{tooltip && <TooltipIcon label={null} text={tooltip} icon="fa-info-circle" iconColor="#4b4c4c" />}
					{hasExternalLink && externalLink && (
						<>
							<br />
							<a href={externalLink} target="_blank" rel="noopener noreferrer">
								{externalLinkText}
								<i className="fa fa-external-link" aria-hidden="true" style={{ marginLeft: '4px' }}></i>
							</a>
						</>
					)}
					{sublabel && <div>{sublabel}</div>}
					{children}
				</label>

				<div className="d-flex">
					{options.map(o => {
						const codigo = typeof o === 'object' ? o.codigo : o;
						const descricao = typeof o === 'object' ? o.descricao : o;
						const iconWarning = typeof o === 'object' ? o.iconWarning : null;
						const defaultValue = typeof o === 'object' ? o.defaultValue || false : false;
						if (defaultValue && !value) {
							onChangeRadio({ target: { name, value: codigo } });
						}
						return (
							<div className={`form-check ${radioStack ? '' : 'form-check-inline'}`} key={codigo}>
								<label className="form-check-label">
									<input
										type="radio"
										value={codigo}
										name={name}
										onClick={onChangeRadio}
										readOnly={readOnly}
										disabled={disabled}
										onChange={() => false}
										checked={value === codigo || (!value && defaultValue === true)}
										className="form-check-input"
									/>{' '}
									{iconWarning && value === codigo ? (
										<TooltipIcon
											label={descricao}
											text={iconWarning}
											icon="fa-exclamation-triangle"
											iconColor="#b39d5b"
										/>
									) : (
										descricao
									)}
								</label>
							</div>
						);
					})}
				</div>
			</div>
			{observacao && <p>{observacao}</p>}{' '}
			{options.map(o => {
				const codigo = typeof o === 'object' ? o.codigo : o;
				return o.observacao && value === codigo ? (
					<Alert
						key={codigo}
						variant={o.variant || 'warning'}
						style={{ whiteSpace: 'pre-wrap', marginTop: '10px', marginLeft: '0px' }}
					>
						<b>Observação: </b>
						{o.observacao}
					</Alert>
				) : null;
			})}
			{options
				.filter(o => typeof o === 'object' && value === o.codigo && !!o.warning)
				.map(o => (
					<WarningMessage key={o.codigo} style={{ marginLeft: '26px' }}>
						{o.warning}
					</WarningMessage>
				))}
		</>
	);
};
RadioFieldLE.displayName = 'RadioFieldLE';

RadioFieldLE.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool,
	radioStack: PropTypes.bool,
	tooltip: PropTypes.string,
	hasExternalLink: PropTypes.bool,
	externalLink: PropTypes.string,
	externalLinkText: PropTypes.string,
	observacao: PropTypes.any,
	children: PropTypes.any,
	sublabel: PropTypes.any
};

export default RadioFieldLE;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		}
	}
	return errors;
};
