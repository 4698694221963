import './LicenciamentoExpresso.scss';
import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import { size, flatMap, get, isEqual } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';
import { validarRestricoes } from 'components/RestricoesAdministrativasForm';
import ShowDebug from 'components/ShowDebug';

import { InfosAreasAP } from 'containers/AprovacaoProjetos/InfosAreasAP';
import actions from 'containers/Form/actions';
import { EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO } from 'containers/Form/Detalhe';

import useMessages from 'custom-hooks/useMessages';
import useMutableState from 'custom-hooks/useMutableState';

import { EXPEDIENTES_API_URL } from 'environments';

import { sendApi, accessApi } from 'utils/injectApi';
import { obtemDadosDocumento } from 'utils/tools';

import { InfosDispositivosControleLE } from './InfosDispositivosControleLE';
import { InfosExpedienteLE } from './InfosExpedienteLE';
import { InfosPlanoDiretorLE } from './InfosPlanoDiretorLE';
import { InfosProjetoLE } from './InfosProjetoLE';
import { InfosQuotasLE } from './InfosQuotasLE';
import { InfosRestricoesProjetoLE } from './InfosRestricoesProjetoLE';
import InfosTerrenoLE from './InfosTerrenoLE';
import { limparForm } from './LimparForm';
import { LE_Enquadramentos, TODOS_ENQUADRAMENTOS, METAFORMLE } from './MetadataLE';
import { validarForm } from './ValidarForm';
import '../AprovacaoProjetos/FormularioUAP.scss';

export function LicenciamentoExpressoDocEspecifico({ setShowFormulario, setShowConfirm }) {
	/* CUSTOM HOOKS */
	const formulario = useMutableState(['licenciamento', 'formulario']);
	const errorsAux = useMutableState(['licenciamento', 'errors']);
	const errors = size(errorsAux) === 0 ? [] : errorsAux;

	/* ESTADOS */
	const [localData, setLocalData] = useState({});
	const [loading, setLoading] = useState(false);
	const [blockSubmit, setBlockSubmit] = useState(false);
	const [tipoForm, setTipoForm] = useState();
	const { createMessage } = useMessages();
	const [jaAcessouEvus, setJaAcessouEvus] = useState(false);

	// const [localDataAnterior, setLocalDataAnterior] = useState({});
	const [iniciarLocalData, setIniciarLocalData] = useState(true);
	const [isEstadoAlterado, setIsEstadoAlterado] = useState(false);

	const dispatch = useDispatch();

	/* MEMOIZED FUNCTIONS */
	const changeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				/* eslint-disable-next-line no-unused-vars */
				const [valid, invalid] = changes;
				changes = valid;
			}

			const { name, value } = changes;

			if (name === 'errors') {
				return;
			}

			dispatch(actions.setErrors([]));
			setBlockSubmit(false);
			let dados = { ...localData, [name]: value };
			//limpeza
			let dadosLimpos = limparForm(dados, tipoForm);
			setLocalData(dadosLimpos);
			setIsEstadoAlterado(true);
		},
		[dispatch, localData, tipoForm]
	);

	useEffect(() => {
		const previousData = obtemFormularioLicenciamentoExpressoDados(formulario);
		setLocalData(previousData?.dados || {});
	}, [formulario]);

	useEffect(() => {
		if (formulario) {
			if (localData?.ultimoEvuValido) {
				setJaAcessouEvus(true);
			} else if (size(localData) > 0) {
				const expediente = get(formulario, 'formData.data.expediente');
				if (expediente) {
					obtemEvuValido(expediente.id || expediente._id)
						.then(
							evu => {
								if (evu) {
									changeHandler({ name: 'ultimoEvuValido', value: evu });
								}
								setJaAcessouEvus(true);
							},
							error => {
								console.error('error', error);
								setJaAcessouEvus(true);
							}
						)
						.catch(error => {
							console.error('error', error);
							setJaAcessouEvus(true);
						})
						.finally(() => {
							setJaAcessouEvus(true);
						});
				} else {
					setJaAcessouEvus(true);
				}
			}
		}
	}, [changeHandler, formulario, localData]);

	useEffect(() => {
		/**
	 * lógica para pegar o enquadramento e conforme lista de docs gerar um formulário específico:
	 - Habitação Unifamiliar em Condomínio (Unidade Autônoma) - 11
	 - Habitação Unifamiliar (01 economias) - 12
	 - Habitação Unifamiliar (02 economias) - 13
	 - Habitação Unifamiliar (até 02 economias) + Não Residencial - 14
	 - Não Residencial -15
	 - Condomínios até 10 UA sem EVU e/ou Dentro do Módulo - 16
	 * */
		const enquadramentoDefinicao = get(formulario, 'formData.data.enquadramento.definicao', null);
		let tpf = null;
		if (enquadramentoDefinicao && TODOS_ENQUADRAMENTOS.includes(enquadramentoDefinicao)) {
			tpf = enquadramentoDefinicao;
		} else {
			// TODO: remover condicional ao padronizar 'enquadramento.definicao'
			const enquadramento = get(formulario, 'formData.data.enquadramento.respostas.1', '11');
			const listaEnquadramentos = [
				{ definicao: LE_Enquadramentos.UNIFAMILIAR_AUTONOMA, ids: ['11', '1-11'] },
				{ definicao: LE_Enquadramentos.UNIFAMILIAR_01_ECONO, ids: ['12', '1-12'] },
				{ definicao: LE_Enquadramentos.UNIFAMILIAR_02_ECONO, ids: ['13', '1-13'] },
				{ definicao: LE_Enquadramentos.UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES, ids: ['14', '1-14'] },
				{ definicao: LE_Enquadramentos.NAO_RESIDENCIAL, ids: ['15', '1-15'] },
				{ definicao: LE_Enquadramentos.CONDOMINIO_10UA, ids: ['16', '1-16'] },
				{ definicao: 'le7', ids: ['17', '1-17'] }
			];

			tpf = listaEnquadramentos.find(e => e.ids.includes(enquadramento))?.definicao;
		}
		setTipoForm(tpf);
	}, [formulario]);

	useEffect(() => {
		if (formulario) {
			const previousData = obtemDadosDocumento(
				formulario,
				EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO,
				false,
				false,
				false
			);
			// const previousDataAnterior = obtemDadosDocumento(
			// 	formulario,
			// 	EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO,
			// 	false,
			// 	true,
			// 	false
			// );
			let dados = previousData?.dados || {};
			// let dadosAnterior = cloneDeep(previousDataAnterior?.dados || {});
			// se trocar tipoForm, limpa os dados (só ocorre em debug)
			if (tipoForm !== dados?.tipoForm) {
				dados = {};
				// dadosAnterior = {};
			}
			const reloadLocalData =
				(iniciarLocalData && !isEqual(localData, dados)) || (size(localData) > 0 && isEqual(localData, dados));
			// verificar se é um carregamento inicial ou se houve alterações entre Store e localData
			// alterações em documentos ativam alterações na Store, que disparam este useEffect, esta condicional evita o reset dos dados do localData durante a edição do Form. Específico, principalmente nos casos de anexo e remoção de documentos em SLOTS no Form. Específico
			if (reloadLocalData) {
				setIniciarLocalData(false);
				setLocalData(dados);
				// setLocalDataAnterior(dadosAnterior);
				setIsEstadoAlterado(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formulario, tipoForm]);

	const submit = async operacao => {
		const { draft, forceSave, gerarPdf } = operacao;
		let [validacao, ok] = [[], true];
		let isBloqueante = false;
		let formValidado = { validacao, isBloqueante };

		if (forceSave && draft) {
			salvar({ draft, erros: errors });
		} else {
			// validacao
			// validarInfosProjetoLE(localData, tipoForm);
			formValidado = validarForm(localData, tipoForm, formulario);
			const valRE = await validarRestricoes(localData, tipoForm, 'licenciamento_expresso-restricoes-administrativas');

			validacao = formValidado.validacao.concat(valRE);
			isBloqueante = formValidado.isBloqueante;
			ok = !isBloqueante && valRE.length === 0;

			if (ok) {
				salvar({ draft, gerarPdf });
			} else {
				dispatch(actions.setErrors(validacao));
			}

			setBlockSubmit(isBloqueante);
		}
	};

	const salvar = async params => {
		const { draft, erros, gerarPdf } = params;
		try {
			setLoading(true);
			const formId = get(formulario, 'formData.id');
			const url = `processo/${formId}/${EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO}${draft ? '?draft=true' : ''}`;
			const newData = { ...localData, tipoForm };

			// add economias caso seja 1 ou 2 e nao tenha o campo na tela
			if (tipoForm === LE_Enquadramentos.UNIFAMILIAR_01_ECONO) {
				newData.infosProjeto.numeroEconomiasTotal.value = '1';
			}
			if (tipoForm === LE_Enquadramentos.UNIFAMILIAR_02_ECONO) {
				newData.infosProjeto.numeroEconomiasTotal.value = '2';
			}

			const dadosRestricoesRevisor = get(formulario, 'formData.data.dadosLE0400');
			if (size(dadosRestricoesRevisor) > 0) {
				const { restricoesAdministrativas: draRev } = dadosRestricoesRevisor;
				const { restricoesAdministrativas: draReq } = newData;

				if (size(draRev)) {
					Object.keys(draReq).forEach(prop => {
						if (Object.keys(draRev).includes(prop)) {
							if (draRev[prop] !== draReq[prop]) {
								draReq[`${prop}AlteradaRequerente`] = true;
							} else {
								delete draReq[`${prop}AlteradaRequerente`];
							}
							delete formulario?.formData?.data?.dadosLE0400[prop];
							delete formulario?.formData?.data?.dadosLE0400?.restricoesAdministrativas[prop];
						} else {
							draReq[`${prop}AlteradaRequerente`] = true;
						}
					});
				} else {
					Object.keys(newData).forEach(prop => {
						if (Object.keys(dadosRestricoesRevisor).includes(prop)) {
							if (dadosRestricoesRevisor[prop] !== newData[prop]) {
								newData[`${prop}AlteradaRequerente`] = true;
							} else {
								delete newData[`${prop}AlteradaRequerente`];
							}
							delete formulario?.formData?.data?.dadosLE0400[prop];
						} else {
							if (METAFORMLE.RESTRICOES_ADMINISTRATIVAS[prop]) {
								newData[`${prop}AlteradaRequerente`] = true;
							}
						}
					});
				}
			}

			if (gerarPdf) {
				Object.keys(newData).forEach(prop => {
					if (newData[`${prop}AlteradaRevisor`]) {
						delete newData[`${prop}AlteradaRevisor`];
					}
				});
				if (newData.restricoesAdministrativas) {
					Object.keys(newData.restricoesAdministrativas).forEach(prop => {
						delete newData.restricoesAdministrativas[`${prop}AlteradaRevisor`];
					});
				}
			}

			const { data: dataResponse } = await sendApi(
				{ url, payload: { form: newData, observacoes: erros }, required: true, label: '' },
				false
			);
			dispatch(
				actions.alteraStatusDocumento({
					documento: dataResponse.documento,
					documentoDados: dataResponse.documentoDados
				})
			);
			createMessage(`Documento salvo${draft ? ' como rascunho' : ''} com sucesso`, 5);
		} catch (error) {
			createMessage('Problemas no salvamento do documento... verifique a console', 3);
			console.error('erro no servidor', error);
		} finally {
			setLoading(false);
			setShowFormulario(false);
		}
	};

	/* AUXILIARES PARA RENDER */
	const hasErros = size(errors) > 0;

	const showRestricoes = [
		LE_Enquadramentos.UNIFAMILIAR_AUTONOMA,
		LE_Enquadramentos.UNIFAMILIAR_01_ECONO,
		LE_Enquadramentos.UNIFAMILIAR_02_ECONO,
		LE_Enquadramentos.NAO_RESIDENCIAL,
		LE_Enquadramentos.UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES
	].includes(tipoForm);

	const showInfosTerreno = tipoForm !== LE_Enquadramentos.UNIFAMILIAR_AUTONOMA;

	const showDispositivosControle = tipoForm === LE_Enquadramentos.UNIFAMILIAR_AUTONOMA;

	const showPlanoDiretor = tipoForm !== LE_Enquadramentos.UNIFAMILIAR_AUTONOMA;

	const showQuotas = false;

	// const showRegimeUrbanistico = tipoForm === LE_Enquadramentos.NAO_RESIDENCIAL;

	/* RENDER */
	return (
		<div className="doc-licenciamento-expresso">
			{loading && <Loader />}
			<div className="le-card">
				<InfosExpedienteLE
					formulario={formulario}
					tipoForm={tipoForm}
					data={localData}
					onChangeHandler={changeHandler}
					onCertificadoSustentavel={certificado =>
						changeHandler({ name: 'certificadoSustentavel', value: certificado })
					}
				/>
			</div>

			{showRestricoes && (
				<div className="le-card">
					<InfosRestricoesProjetoLE
						tipoForm={tipoForm}
						data={localData}
						onChangeHandler={changeHandler}
						errosSubmissao={errors}
					/>
				</div>
			)}

			{showInfosTerreno && (
				<div className="le-card">
					<InfosTerrenoLE
						tipoForm={tipoForm}
						data={localData}
						onChangeHandler={changeHandler}
						errosSubmissao={errors}
					/>
				</div>
			)}

			{showDispositivosControle && (
				<div className="le-card">
					<InfosDispositivosControleLE
						tipoForm={tipoForm}
						data={localData}
						onChangeHandler={changeHandler}
						errosSubmissao={errors}
					/>
				</div>
			)}

			{showPlanoDiretor && (
				<div className="le-card">
					<InfosPlanoDiretorLE
						tipoForm={tipoForm}
						formulario={formulario}
						data={localData}
						onChangeHandler={changeHandler}
						errosSubmissao={errors}
					/>
				</div>
			)}

			{jaAcessouEvus && (
				<div className="le-card">
					<InfosProjetoLE
						tipoForm={tipoForm}
						data={localData}
						onChangeHandler={changeHandler}
						errosSubmissao={errors}
						semExpedienteUnico={!get(formulario, 'formData.data.expediente')}
					/>
				</div>
			)}

			{showQuotas && (
				<div className="le-card">
					<InfosQuotasLE tipoForm={tipoForm} data={localData} onChangeHandler={changeHandler} />
				</div>
			)}

			<div className="le-card">
				<InfosAreasAP
					tipoForm={tipoForm}
					data={localData}
					onChangeHandler={changeHandler}
					errosSubmissao={errorsAux}
					// dataAnterior={localDataAnterior}
					disabled={false}
					changeComentarioHandler={null}
					usuarioInterno={false}
					readOnlyVersao={null}
				/>
			</div>

			<div className="cjto-botoes">
				{isEstadoAlterado ? (
					<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => setShowConfirm(true)}>
						Cancelar
					</button>
				) : (
					<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => setShowFormulario(false)}>
						Fechar
					</button>
				)}

				<button
					type="button"
					className="btn btn-secondary mt-3 mr-2"
					onClick={() => submit({ draft: true, forceSave: hasErros })}
				>
					{hasErros ? 'Ignorar e Salvar Rascunho' : 'Salvar Rascunho'}
				</button>

				<button
					type="button"
					className="btn btn-primary mt-3"
					onClick={() => submit({ draft: false, forceSave: hasErros, gerarPdf: true })}
					disabled={blockSubmit}
				>
					Salvar e gerar PDF
				</button>
			</div>
			{hasErros && (
				<div className="ap-card erros">
					<h3 className="font-weight-bold text-danger m-3">Existem erros no formulário, verifique acima.</h3>
					<ErrorMessages showLink={true} errorList={Array.isArray(errorsAux) ? flatMap(errorsAux, v => v) : []} />
					{!blockSubmit && (
						<h4 className="warning-message">Continue se deseja protocolar mesmo com as inconsistências constatadas.</h4>
					)}
				</div>
			)}
			<ShowDebug data={localData} label="localData:" console />
			<ShowDebug data={errors} label="errors:" console />
			<ShowDebug data={errorsAux} label="errorsAux:" console />
		</div>
	);
}
LicenciamentoExpressoDocEspecifico.displayName = 'LicenciamentoExpressoDocEspecifico';
LicenciamentoExpressoDocEspecifico.propTypes = {
	setShowFormulario: PropTypes.func,
	setShowConfirm: PropTypes.func,
	readOnly: PropTypes.bool,
	forAdmin: PropTypes.bool
};
export default LicenciamentoExpressoDocEspecifico;

/**
 * obtem os dados preenchidos do formulário de licenciamento expresso
 * na aba documentos do portal
 * @param {*} formulario
 * @returns
 */
export function obtemFormularioLicenciamentoExpressoDados(formulario) {
	const { documentos, documentosDados } = formulario || {};
	const documento = (documentos || []).reduce((acc, doc) => {
		if (doc.extensao === EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO) {
			if (acc) {
				return doc.versao > acc.versao ? doc : acc;
			} else {
				return doc;
			}
		}
		return acc;
	}, null);
	const docDados = (documentosDados || []).find(dd => dd?.id === documento?.id || dd?.id === documento?.original);
	return docDados;
}

async function obtemEvuValido(expedienteId) {
	if (!expedienteId) {
		return null;
	}
	const url = `${EXPEDIENTES_API_URL}/eu/${expedienteId}/ultimo-evu-valido`;
	const res = await accessApi(url, true);
	const evu = res.data;
	return evu;
}
