// ALINHAR COM COLLECTION enquadramentosDefinicoes
const UNIFAMILIAR_AUTONOMA = 'le1',
	UNIFAMILIAR_01_ECONO = 'le2',
	UNIFAMILIAR_02_ECONO = 'le3',
	UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES = 'le4',
	NAO_RESIDENCIAL = 'le5';

export const LE_Enquadramentos = {
	UNIFAMILIAR_AUTONOMA,
	UNIFAMILIAR_01_ECONO,
	UNIFAMILIAR_02_ECONO,
	UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES,
	NAO_RESIDENCIAL
};

export const TODOS_ENQUADRAMENTOS = [
	LE_Enquadramentos.UNIFAMILIAR_AUTONOMA,
	LE_Enquadramentos.UNIFAMILIAR_01_ECONO,
	LE_Enquadramentos.UNIFAMILIAR_02_ECONO,
	LE_Enquadramentos.UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES,
	LE_Enquadramentos.NAO_RESIDENCIAL
];

const ENQUADRAMENTOS_SEM_AUTONOMA = [
	LE_Enquadramentos.UNIFAMILIAR_01_ECONO,
	LE_Enquadramentos.UNIFAMILIAR_02_ECONO,
	LE_Enquadramentos.UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES,
	LE_Enquadramentos.NAO_RESIDENCIAL
];

export const ITEM_HABITACAO_UNIFAMILIAR = '1.1.';
export const ITEM_HABITACAO_COLETIVA = '1.2.';

export const ATIVIDADES_LE5 = [
	'2.1.1.',
	'2.1.2.',
	'2.1.3.',
	'2.2.1.',
	'2.2.2.',
	'2.2.3.',
	'3.1.',
	'3.2.',
	'3.3.',
	'3.4.',
	'4.1.1.',
	'4.1.2.',
	'4.2.'
];

export const ATIVIDADES_LE4 = ['1.1.', '1.2.', ...ATIVIDADES_LE5];

/**
 * METADATA do formulário de licenciamento expresso
 * ajuda principalmente a validar os campos obrigatórios
 * de forma rápida
 */
export const METAFORMLE = {
	nomeLocal: {
		name: 'nomeLocal'
	},

	RESTRICOES_ADMINISTRATIVAS: {
		// Restrições em comum
		dmiRestricaoOrgaosExternos: {
			name: 'dmiRestricaoOrgaosExternos',
			required: true,
			label: 'Na DMWEB há alguma restrição relativa à IMPLANTAÇÃO POR ÓRGÃOS EXTERNOS DO MUNICÍPIO?',
			textoInformativo:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade"',
			tipoform: TODOS_ENQUADRAMENTOS
		},
		dmiRestricaoAlturaProximidadeAeroporto: {
			name: 'dmiRestricaoAlturaProximidadeAeroporto',
			required: true,
			label: 'Na DMWEB há alguma restrição relativa à ALTURA FACE PROXIMIDADE AEROPORTUÁRIA?',
			subcampos: ['edificacaoUltrapassaAlturaDECEA'],
			tipoform: TODOS_ENQUADRAMENTOS
		},
		edificacaoUltrapassaAlturaDECEA: {
			name: 'edificacaoUltrapassaAlturaDECEA',
			required: true,
			label: 'O projeto da edificação ultrapassa a altura permitida pelo DECEA?',
			isSubcampo: true,
			tipoform: TODOS_ENQUADRAMENTOS
		},
		anacRestricaoRuidoUnifamiliar: {
			name: 'anacRestricaoRuidoUnifamiliar',
			required: true,
			label:
				'Em se tratando de imóvel de habitação unifamiliar, este encontra-se dentro da curva de nível de ruído, de Aeroporto/Aeródromo, conforme tabela E-2 do RBAC n° 161 da ANAC?',
			textoInformativo:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade"',
			tipoform: [UNIFAMILIAR_AUTONOMA, UNIFAMILIAR_01_ECONO, UNIFAMILIAR_02_ECONO, UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES]
		},
		dmiRestricaoPluvial: {
			name: 'dmiRestricaoPluvial',
			required: true,
			label: 'Na DMWEB há alguma restrição relativa a REDE/VALO PLUVIAL E/OU ÁREA NÃO EDIFICÁVEL?',
			subcampos: ['restricaoAreaNaoEdificavel'],
			tipoform: TODOS_ENQUADRAMENTOS
		},
		restricaoAreaNaoEdificavel: {
			name: 'restricaoAreaNaoEdificavel',
			required: true,
			label: 'Há área NÃO EDIFICÁVEL no imóvel?',
			isSubcampo: true,
			textoInformativoNao:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade"',
			subcampos: ['previstoConstrucaoEmAreaNaoEdificavel', 'previstoMuroEmAreaNaoEdificavel'],
			tipoform: TODOS_ENQUADRAMENTOS
		},
		previstoConstrucaoEmAreaNaoEdificavel: {
			name: 'previstoConstrucaoEmAreaNaoEdificavel',
			required: true,
			label: 'No projeto está previsto construção sobre essa área NÃO EDIFICÁVEL?',
			isSubcampo: true,
			textoInformativo:
				'Em caso de documentos para análise da restrição administrativa, anexe o documento AUTORIZAÇÃO PARA EDIFICAÇÃO EM FAIXA NÃO EDIFICÁVEL DE DRENAGEM no botão "Adicionar documento" da aba DOCUMENTOS após concluir este formulário. A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade".',
			textoInformativoNao:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade".',
			tipoform: TODOS_ENQUADRAMENTOS
		},
		previstoMuroEmAreaNaoEdificavel: {
			name: 'previstoMuroEmAreaNaoEdificavel',
			required: true,
			label: 'No projeto está previsto muro sobre essa área NÃO EDIFICÁVEL?',
			isSubcampo: true,
			textoInformativo:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade".',
			textoInformativoNao:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade".',
			tipoform: TODOS_ENQUADRAMENTOS
		},
		restricaoEsgoto: {
			name: 'restricaoEsgoto',
			required: true,
			label: 'No imóvel incide REDE DE ESGOTO CLOACAL (DMAE)?',
			textoInformativo:
				'Para análise da restrição administrativa, anexe o documento TERMO DE RESPONSABILIDADE DO DMAE no botão "Adicionar documento" da aba DOCUMENTOS após concluir este formulário.',
			tipoform: TODOS_ENQUADRAMENTOS
		},
		restricaoIntervencaoVegetal: {
			name: 'restricaoIntervencaoVegetal',
			required: true,
			label: 'Há intervenção de VEGETAL (poda, remoção, compensação, transplante, etc.)?',
			textoInformativo:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade"',
			tipoform: TODOS_ENQUADRAMENTOS
		},

		// Restrições específicas Unifamiliar Autonoma
		restricaoALPdoCondominio: {
			name: 'restricaoALPdoCondominio',
			required: true,
			label:
				'Há quota de ALP (Área Livre Permeável) no projeto aprovado do condomínio para a área privativa (unidade privativa/autônoma)?',
			subcampos: ['quotaALP'],
			textoInformativo:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade"',
			tipoform: [UNIFAMILIAR_AUTONOMA]
		},

		// Restrições específicas Unifamiliar 1 ou 2 economias
		restricaoBloqueioEPAHC: {
			name: 'restricaoBloqueioEPAHC',
			required: true,
			label: 'Na DMWEB há alguma restrição relativa a BLOQUEIO PREVENTIVO EPAHC?',
			textoInformativo:
				'Para análise da restrição administrativa, anexe o documento CERTIDÃO DE INVENTÁRIO DA EPAHC/SMC no botão "Adicionar documento" da aba DOCUMENTOS após concluir este formulário.',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		restricaoTracadoViarioPDDUA: {
			name: 'restricaoTracadoViarioPDDUA',
			required: true,
			label: 'No imóvel incide TRAÇADO VIÁRIO DO PDDUA (recuo viário/alinhamento projetado/alargamento)?',
			subcampos: ['edificacaoIncideTracadoViarioPDDUA'],
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		edificacaoIncideTracadoViarioPDDUA: {
			name: 'edificacaoIncideTracadoViarioPDDUA',
			required: true,
			label:
				'O projeto da edificação incide sobre o TRAÇADO VIÁRIO DO PDDUA (recuo viário/alinhamento projetado/alargamento)?',
			subcampos: ['possuiEstudoPDDUA'],
			textoInformativoNao:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade"',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		possuiEstudoPDDUA: {
			name: 'possuiEstudoPDDUA',
			required: true,
			label: 'Possui Estudo de Viabilidade Urbanístico válido conforme Art. 159 do PDDUA?',
			textoInformativoNao:
				'Devido a incidência de TRAÇADO VIÁRIO DO PDDUA SEM ESTUDO DE VIABILIDADE URBANÍSTICO (EVU) APROVADO E VÁLIDO deverá apresentar Estudo Viabilidade Urbanística (EVU) aprovado e válido ou solicitar Aprovação de Projeto Arquitetônico juntamente com o EVU no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).',
			bloqueanteSeNao: true,
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		dmiRestricaoGravameViario: {
			name: 'dmiRestricaoGravameViario',
			required: true,
			label: 'Na DMWEB há alguma restrição relativa a GRAVAME VIÁRIO (logradouro projetado)?',
			textoInformativo:
				'Devido a incidência de GRAVAME VIÁRIO, este projeto não se enquadra na modalidade de Aprovação de Projeto Licenciamento Expresso. Deverá ser solicitada a  Aprovação de Projeto Arquitetônico no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico)',
			bloqueanteSeSim: true,
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		dmiRestricaoAPP: {
			name: 'dmiRestricaoAPP',
			required: true,
			label:
				'Na DMWEB há alguma restrição relativa a Área de Preservação Permanente (APP) e/ou Área de Proteção de Ambiente Natural (APAN)?',
			textoInformativoNao:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade".',
			bloqueanteSeSim: true,
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA,
			subcampos: ['construcaoSobreAppApan']
		},
		construcaoSobreAppApan: {
			name: 'construcaoSobreAppApan',
			required: true,
			label:
				'No projeto está previsto construção sobre a Área de Preservação Permanente (APP) e/ou Área de Proteção de Ambiente Natural (APAN)?',
			textoInformativo:
				'Devido a incidência de projeto sobre Área de Preservação Permanente (APP) ou Área de Proteção de Ambiente Natural (APAN), este projeto não se enquadra na modalidade de Aprovação de Projeto Licenciamento Expresso. Deverá ser solicitada a Aprovação de Projeto Arquitetônico no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).',
			textoInformativoNao:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade".',
			bloqueanteSeSim: true,
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		dmiRestricaoAreaVerde: {
			name: 'dmiRestricaoAreaVerde',
			required: true,
			label: 'Na DMWEB há alguma restrição relativa a PARQUE/ÁREA VERDE?',
			subcampos: ['restricaoEdificacaoAreaVerde'],
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		restricaoEdificacaoAreaVerde: {
			name: 'restricaoEdificacaoAreaVerde',
			required: true,
			label: 'No projeto da edificação incide sobre PARQUE/ÁREA VERDE?',
			textoInformativo:
				'Devido a incidência de PARQUE/ÁREA VERDE, este projeto não se enquadra na modalidade de Aprovação de Projeto Licenciamento Expresso. Deverá ser solicitada a Aprovação de Projeto Arquitetônico no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico)',
			bloqueanteSeSim: true,
			textoInformativoNao:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade"',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		dmiRestricaoAreaEscolar: {
			name: 'dmiRestricaoAreaEscolar',
			required: true,
			label: 'Na DMWEB há alguma restrição relativa a ÁREA DE ESCOLA?',
			subcampos: ['projetoIncideSobreEscola'],
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		projetoIncideSobreEscola: {
			name: 'projetoIncideSobreEscola',
			required: true,
			label: 'O projeto incide sobre a ÁREA DE ESCOLA?',
			textoInformativo:
				'Devido a incidência de ÁREA DE ESCOLA, este projeto não se enquadra na modalidade de Aprovação de Projeto Licenciamento Expresso. Deverá ser solicitada a Aprovação de Projeto Arquitetônico no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico)',
			bloqueanteSeSim: true,
			textoInformativoNao:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade"',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		possuiIndicesConstrutivos: {
			name: 'possuiIndicesConstrutivos',
			required: true,
			label: 'Há utilização de índices construtivos previsto no projeto (Solo Criado e/ou TPC) no projeto?',
			bloqueanteSeSim: true,
			textoInformativo:
				'Devido ao projeto possuir ÍNDICES CONSTRUTIVOS (SOLO CRIADO E/OU TPC), este projeto não se enquadra na modalidade de Aprovação de Projeto Licenciamento Expresso. Deverá ser solicitada a  Aprovação de Projeto Arquitetônico no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		restricaoBacia: {
			name: 'restricaoBacia',
			required: true,
			label:
				'Há BACIA DE AMORTECIMENTO ou RESERVATÓRIOS de CONTENÇÃO e/ou RETENÇÃO (em se tratando de não residencial e terrenos acima de 600m²)?',
			textoInformativo:
				'A restrição administrativa selecionada requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte "5. Termo de Responsabilidade"',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		predioTombadoInventariado: {
			name: 'predioTombadoInventariado',
			required: true,
			label: 'Trata-se de prédio TOMBADO ou INVENTARIADO (ESTRUTURAÇÃO ou COMPATIBILIZAÇÃO)?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA,
			subcampos: ['classificacaoEdificacao']
		},
		classificacaoEdificacao: {
			name: 'classificacaoEdificacao',
			required: true,
			label: 'A edificação é classificada como TOMBADO, ESTRUTURAÇÃO ou COMPATIBILIZAÇÃO?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA,
			subcampos: ['possuiEvuTombado', 'possuiEvuEstruturacao', 'terrenoPossuiRuDefinido']
		},
		possuiEvuTombado: {
			name: 'possuiEvuTombado',
			required: false,
			label: 'O projeto possui Estudo de Viabilidade Urbanístico (EVU) válido conforme Art. 159 do PDDUA?',
			textoInformativoNao:
				'Devido a edificação ser TOMBADA e NÃO TER ESTUDO DE VIABILIDADE URBANÍSTICO (EVU) APROVADO E VÁLIDO, deverá apresentar Estudo Viabilidade Urbanística (EVU) aprovado e válido ou solicitar Aprovação de Projeto Arquitetônico juntamente com o EVU no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA,
			bloqueanteSeNao: true
		},
		possuiEvuEstruturacao: {
			name: 'possuiEvuEstruturacao',
			required: false,
			label: 'O projeto possui Estudo de Viabilidade Urbanístico (EVU) válido conforme Art. 159 do PDDUA?',
			textoInformativoNao:
				'Devido a edificação ser de ESTRUTURAÇÃO e NÃO TER ESTUDO DE VIABILIDADE URBANÍSTICO (EVU) APROVADO E VÁLIDO, deverá apresentar Estudo Viabilidade Urbanística (EVU) aprovado e válido ou solicitar Aprovação de Projeto Arquitetônico juntamente com o EVU no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA,
			bloqueanteSeNao: true
		},
		terrenoPossuiRuDefinido: {
			name: 'terrenoPossuiRuDefinido',
			required: false,
			label: 'O terreno possui regime urbanístico definido?',
			textoInformativoNao:
				'Devido a edificação ser de COMPATIBILIZAÇÃO e NÃO POSSUIR REGIME URBANÍSTICO DEFINIDO, deverá apresentar Estudo Viabilidade Urbanística (EVU) aprovado e válido ou solicitar Aprovação de Projeto Arquitetônico juntamente com o EVU no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA,
			subcampos: ['possuiEvuCompatibilizacao'],
			bloqueanteSeNao: true
		},
		possuiEvuCompatibilizacao: {
			name: 'possuiEvuCompatibilizacao',
			required: false,
			label: 'O projeto possui Estudo de Viabilidade Urbanístico (EVU) válido conforme Art. 159 do PDDUA?',
			textoInformativoNao:
				'Devido a edificação ser de COMPATIBILIZAÇÃO e NÃO TER EVU APROVADO E VÁLIDO, deverá apresentar Estudo Viabilidade Urbanística (EVU) aprovado e válido ou solicitar Aprovação de Projeto Arquitetônico juntamente com o EVU no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA,
			bloqueanteSeNao: true
		}
	},

	INFOS_PROJETO: {
		// Campos de Projeto em comum
		// projetoNovoOuAumento: {
		// 	name: 'projetoNovoOuAumento',
		// 	required: true,
		// 	label: 'Projeto novo ou aumento/diminuição?',
		// 	tipoform: TODOS_ENQUADRAMENTOS
		// },
		numeroPavimentos: {
			name: 'numeroPavimentos',
			required: true,
			label: 'Número de pavimentos',
			tipoform: TODOS_ENQUADRAMENTOS
		},
		numeroDormitorios: {
			name: 'numeroDormitorios',
			required: true,
			label: 'Número de dormitórios',
			tipoform: LE_Enquadramentos.UNIFAMILIAR_AUTONOMA
		},
		recuoJardimIsento: {
			name: 'recuoJardimIsento',
			required: true,
			label: 'Conforme PDDUA, há isenção do Recuo de Jardim?',
			tipoform: TODOS_ENQUADRAMENTOS
		},
		projetoPossuiEvuValido: {
			name: 'projetoPossuiEvuValido',
			required: true,
			label: 'O projeto possui Estudo de Viabilidade Urbanística aprovado e válido?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		alturaMaximaAeroportoAerodromo: {
			name: 'alturaMaximaAeroportoAerodromo',
			required: true,
			label: 'Altura máxima pela guia Aeroporto (DECEA) informada na DMWEB (m)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		alturaTotalProjeto: {
			name: 'alturaTotalProjeto',
			required: true,
			label:
				'Altura do elemento (mastro, antena, iluminação) mais alto do projeto (m). Observar Portaria nº 957/GC3-09/07/15, Cap. VII',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		// Campos específicos Unifamiliar 1 ou 2 economias
		projetoAtividade: {
			name: 'projetoAtividade',
			required: true,
			label: 'Atividade',
			tipoform: [UNIFAMILIAR_01_ECONO, UNIFAMILIAR_02_ECONO]
		},
		alpIsenta: {
			name: 'alpIsenta',
			required: true,
			label: 'Conforme PDDUA, há isenção da Área Livre Permeável?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		projetoPossuiBaseCorpo: {
			name: 'projetoPossuiBaseCorpo',
			required: true,
			label: 'O projeto possui base e corpo?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		numeroEconomiasResidencial: {
			name: 'numeroEconomiasResidencial',
			required: true,
			label: 'Número de Economias - Residencial',
			tipoform: LE_Enquadramentos.UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES
		},
		numeroEconomiasNaoResidencial: {
			name: 'numeroEconomiasNaoResidencial',
			required: true,
			label: 'Número de Economias - Não Residencial',
			tipoform: [NAO_RESIDENCIAL, UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES]
		},
		trintaPorcentoIa: {
			name: 'trintaPorcentoIa',
			required: false,
			label: 'O projeto utilizará acréscimo de 30% de índice de aproveitamento conforme Art. 107, §5º do PDDUA?',
			tipoform: LE_Enquadramentos.NAO_RESIDENCIAL
		}
	},

	// Quotas: apenas Unifamiliar Autônoma (dentro de Infos Dispositivos de Controle)
	// Na Unifamiliar Autônoma, quotaALP abre dependendo de restricaoALPdoCondominio
	INFOS_QUOTAS: {
		quotaIndiceAproveitamento: {
			name: 'quotaIndiceAproveitamento',
			required: true,
			label: 'Índice de Aproveitamento - IA',
			tipoform: [UNIFAMILIAR_AUTONOMA]
		},
		quotaTaxaOcupacao: {
			name: 'quotaTaxaOcupacao',
			required: true,
			label: 'Taxa de Ocupação - TO (m²)',
			tipoform: [UNIFAMILIAR_AUTONOMA]
		},
		quotaALP: {
			name: 'quotaALP',
			required: true,
			label: 'Área Livre Permeável - ALP (m²)',
			tipoform: [UNIFAMILIAR_AUTONOMA]
		}
	},

	// Infos Alturas Máximas: apenas para Unifamiliar Autônoma (dentro de Infos Dispositivos de Controle)
	INFOS_ALTURAS_MAXIMAS: {
		alturaMaximaRegimeUrbanistico: {
			name: 'alturaMaximaRegimeUrbanistico',
			required: true,
			label: 'Altura máxima definida pelo Regime Urbanístico (m)',
			tipoform: [UNIFAMILIAR_AUTONOMA]
		},
		alturaMaximaAeroportoAerodromo: {
			name: 'alturaMaximaAeroportoAerodromo',
			required: true,
			label: 'Altura máxima pela guia Aeroporto (DECEA) informada na DMWEB (m)',
			tipoform: [UNIFAMILIAR_AUTONOMA]
		}
	},

	// Infos ambientais: comuns para todos os forms
	INFOS_AMBIENTAIS: {
		reusoAgua: {
			name: 'reusoAgua',
			required: false,
			// eslint-disable-next-line quotes
			label: "Reuso d'água?",
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		},
		energiaEletricaAlternativa: {
			name: 'energiaEletricaAlternativa',
			required: false,
			label: 'Energia elétrica alternativa?',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		},
		telhadoVerde: {
			name: 'telhadoVerde',
			required: false,
			label: 'Telhado Verde?',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		},
		tecnologiasConstrutivasSustentaveis: {
			name: 'tecnologiasConstrutivasSustentaveis',
			required: false,
			label: 'Tecnologias Construtivas e Procedimentos Sustentáveis?',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		},
		aquecimentoSolarAgua: {
			name: 'aquecimentoSolarAgua',
			required: false,
			label: 'Água',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		},
		aquecimentoSolarAmbiente: {
			name: 'aquecimentoSolarAmbiente',
			required: false,
			label: 'Ambiente',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		}
		/* Removido, OP #59436
			intervencaoAmbiental: {
			name: 'intervencaoAmbiental',
			required: true,
			label: 'Intervenção Ambiental (Poda, remoção, compensação, etc)?',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		},
		areaLivrePermeavel: {
			name: 'areaLivrePermeavel',
			required: true,
			label: 'ALP - Área Livre Permeável?',
			tipoform: ['le1', 'le2', 'le3', 'le4', 'le5', 'le6']
		} */
	},

	// Infos Terreno: todos exceto Unifamiliar Autônoma (condomínio)
	INFOS_TERRENO: {
		areaTotalMatriculaLote: {
			name: 'areaTotalMatriculaLote',
			required: true,
			label: 'Área total da(s) matrícula(s) do(s) lote(s) (m²)',
			tipoform: TODOS_ENQUADRAMENTOS
		},
		possuiAreaDeMenorPoligono: {
			name: 'possuiAreaDeMenorPoligono',
			required: true,
			label: 'O terreno possui área de menor polígono?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA,
			subcampos: [
				'areaMenorPoligonoLote',
				'areaMenorPoligonoLoteMais5',
				'possuiAreaAtingidaDeMenorPoligono',
				'areaAtingidaMenorPoligonal',
				'areaRemanescenteMenorPoligonal5'
			]
		},
		areaMenorPoligonoLote: {
			name: 'areaMenorPoligonoLote',
			required: true,
			label: 'Área do menor polígono do lote (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		areaMenorPoligonoLoteMais5: {
			name: 'areaMenorPoligonoLoteMais5',
			required: true,
			label: 'Área do menor polígono do lote +5% (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		possuiAreaAtingida: {
			name: 'possuiAreaAtingida',
			required: true,
			label: 'O terreno possui área atingida?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA,
			subcampos: [
				'areaAtingidaMatricula',
				'areaRemanescenteMatricula',
				'possuiAreaAtingidaDeMenorPoligono',
				'areaAtingidaMenorPoligonal',
				'areaRemanescenteMenorPoligonal5'
			]
		},
		areaAtingidaMatricula: {
			name: 'areaAtingidaMatricula',
			required: true,
			label: 'Área atingida da matrícula (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		areaRemanescenteMatricula: {
			name: 'areaRemanescenteMatricula',
			required: true,
			label: 'Área remanescente da matrícula (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},

		possuiAreaAtingidaDeMenorPoligono: {
			name: 'possuiAreaAtingidaDeMenorPoligono',
			required: true,
			label: 'O terreno possui área atingida de menor polígono?',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA,
			subcampos: ['areaAtingidaMenorPoligonal', 'areaRemanescenteMenorPoligonal5']
		},
		areaAtingidaMenorPoligonal: {
			name: 'areaAtingidaMenorPoligonal',
			required: true,
			label: 'Área atingida da menor poligonal (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		areaRemanescenteMenorPoligonal5: {
			name: 'areaRemanescenteMenorPoligonal5',
			required: true,
			label: 'Área remanescente do menor polígono +5% (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		},
		areaAplicacaoRU: {
			name: 'areaAplicacaoRU',
			required: true,
			label: 'Área para aplicação do Regime Urbanístico (m²)',
			tipoform: ENQUADRAMENTOS_SEM_AUTONOMA
		}
	}
};
