import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { get, set, omit, clone } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import ShowDebug from 'components/ShowDebug';

import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE.js';
import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE.js';

import { diferenca, multiplica, maiorQue } from 'utils/tools';

const OPT_SIM_NAO = [
	{ codigo: 'sim', descricao: 'Sim' },
	{ codigo: 'nao', descricao: 'Não' }
];

export function InfosTerrenoLE({ data: localData, onChangeHandler, errosSubmissao }) {
	const [erros, setErros] = useState();

	const possuiAreaDeMenorPoligono = localData?.possuiAreaDeMenorPoligono === 'sim';
	const possuiAreaAtingida = localData?.possuiAreaAtingida === 'sim';
	const possuiAreaAtingidaDeMenorPoligono = localData?.possuiAreaAtingidaDeMenorPoligono === 'sim';

	if (possuiAreaDeMenorPoligono) {
		const areaMaisCinco = multiplica(localData?.areaMenorPoligonoLote?.value, '1.05');
		localData.areaMenorPoligonoLoteMais5 = { value: areaMaisCinco };
	}
	if (possuiAreaAtingida) {
		const remanescenteMatricula = diferenca(
			localData?.areaTotalMatriculaLote?.value,
			localData?.areaAtingidaMatricula?.value
		);

		if (remanescenteMatricula >= 0) {
			localData.areaRemanescenteMatricula = { value: remanescenteMatricula };
		} else {
			localData.areaRemanescenteMatricula = { value: '0' };
		}

		let rmp = diferenca(localData?.areaMenorPoligonoLote?.value, localData?.areaAtingidaMenorPoligonal?.value);
		if (rmp < 0) {
			rmp = 0;
		}
		const remanescenteMenorPoligonal = multiplica(rmp, '1.05');
		localData.areaRemanescenteMenorPoligonal5 = { value: remanescenteMenorPoligonal };
	}

	/**
	 * Cálculos e regras para aplicação do Regime Urbanístico
	 */

	const todosTemValor = properties => {
		if (!properties.includes('areaTotalMatriculaLote')) {
			properties = ['areaTotalMatriculaLote', ...properties];
		}
		return properties.reduce((acc, prop) => {
			const tem = localData[prop]?.value && localData[prop]?.value !== '0.00';
			return acc && tem;
		}, true);
	};

	// #region CÁLCULOS AP replicados aqui no lugar dos antigos da LE
	// remove valor para só mostrar o cálculo quando todos os campos estiverem preenchidos
	localData.areaAplicacaoRU = {};

	if (!possuiAreaDeMenorPoligono && !possuiAreaAtingida) {
		// 1) se não possui "ÁREA DE MENOR POLÍGONO" e não possui "ÁREA ATINGIDA"
		// Área para aplicação RU = Área de matrícula do lote
		if (todosTemValor(['areaTotalMatriculaLote'])) {
			localData.areaAplicacaoRU = { ...localData.areaTotalMatriculaLote };
		}
	} else if (possuiAreaDeMenorPoligono && !possuiAreaAtingida) {
		// 2) Se possui "ÁREA DO MENOR POLÍGONO" e não possui "ÁREA ATINGIDA"
		// Área para aplicação RU = Área do menor polígono do lote + 5% (até o limite da área total da matrícula do lote)
		if (todosTemValor(['areaMenorPoligonoLote'])) {
			if (maiorQue(localData.areaMenorPoligonoLoteMais5?.value, localData.areaTotalMatriculaLote?.value)) {
				localData.areaAplicacaoRU.value = localData.areaTotalMatriculaLote?.value;
			} else {
				localData.areaAplicacaoRU = { ...localData.areaMenorPoligonoLoteMais5 };
			}
		}
	} else if (!possuiAreaDeMenorPoligono && possuiAreaAtingida) {
		// 3) Se não possui "ÁREA DE MENOR POLÍGONO" e possui "ÁREA ATINGIDA"
		// Área para aplicação RU = "ÁREA REMANESCENTE DA MATRÍCULA"
		if (todosTemValor(['areaAtingidaMatricula'])) {
			localData.areaAplicacaoRU = { ...localData.areaRemanescenteMatricula };
		}
	} else if (possuiAreaDeMenorPoligono && possuiAreaAtingida) {
		// 4) Se possui "ÁREA DE MENOR POLÍGONO" e possui "ÁREA ATINGIDA", a área para aplicação RU será o menor valor entre
		// "ÁREA REMANESCENTE DA MATRÍCULA" e "ÁREA REMANESCENTE DO MENOR POLÍGONO + 5%".

		// a diferença entre possuir ou não "ÁREA ATINGIDA DE MENOR POLIGONAL" é que o cálculo dessa "AREA REMANESCENTE DE MENOR POLIGONAL" muda:
		// 4.1) Se possui "ÁREA ATINGIDA DE MENOR POLIGONAL"
		// AREA REMANESCENTE DE MENOR POLIGONAL = diferença entre "ÁREA MENOR POLIGONO LOTE" e "ÁREA ATINGIDA DE MENOR POLIGONAL"

		// 4.2) Se não possui "ÁREA ATINGIDA DE MENOR POLIGONAL"
		// AREA REMANESCENTE DE MENOR POLIGONAL = diferença entre "ÁREA MENOR POLIGONO LOTE" e "ÁREA ATINGIDA DA MATRÍCULA"

		const valoresNecessarios = ['areaMenorPoligonoLote', 'areaAtingidaMatricula'];
		if (possuiAreaAtingidaDeMenorPoligono) {
			valoresNecessarios.push('areaAtingidaMenorPoligonal');
		}
		if (todosTemValor(valoresNecessarios)) {
			const areaRemanescenteMenorPoligonal5 = localData?.areaRemanescenteMenorPoligonal5?.value || '0';
			const areaRemanescenteMatricula = localData?.areaRemanescenteMatricula?.value || '0';
			if (maiorQue(areaRemanescenteMenorPoligonal5, areaRemanescenteMatricula)) {
				localData.areaAplicacaoRU = { value: areaRemanescenteMatricula };
			} else {
				localData.areaAplicacaoRU = { value: areaRemanescenteMenorPoligonal5 };
			}
		}
	}

	// tudo limitado à área total da matrícula do lote
	if (maiorQue(localData.areaAplicacaoRU?.value, localData.areaTotalMatriculaLote?.value)) {
		localData.areaAplicacaoRU.value = localData.areaTotalMatriculaLote?.value;
	}
	// #endregion

	// limitado à área total da matrícula do lote
	if (maiorQue(localData.areaAplicacaoRU?.value, localData.areaTotalMatriculaLote?.value)) {
		localData.areaAplicacaoRU.value = localData.areaTotalMatriculaLote?.value;
	}

	const localChangeHandler = event => {
		const [valid] = event;
		const { name, value } = valid;
		let err = clone(erros);
		if (
			name &&
			value?.value &&
			['areaMenorPoligonoLote', 'areaAtingidaMatricula', 'areaAtingidaMenorPoligonal'].includes(name)
		) {
			if (maiorQue(value.value, localData.areaTotalMatriculaLote?.value)) {
				set(err, name, ['O valor não pode ser maior do que a área total da matrícula do lote']);
			} else {
				err = omit(err, [name]);
			}
		} else if (name && value?.value && name === 'areaTotalMatriculaLote') {
			if (localData.areaAtingidaMatricula?.value) {
				if (maiorQue(localData.areaAtingidaMatricula?.value, value.value)) {
					set(err, 'areaAtingidaMatricula', ['O valor não pode ser maior do que a área total da matrícula do lote']);
				} else {
					err = omit(err, 'areaAtingidaMatricula');
				}
			}
			if (localData.areaMenorPoligonoLote?.value) {
				if (maiorQue(localData.areaMenorPoligonoLote?.value, value.value)) {
					set(err, 'areaMenorPoligonoLote', ['O valor não pode ser maior do que a área total da matrícula do lote']);
				} else {
					err = omit(err, 'areaMenorPoligonoLote');
				}
			}
			if (localData.areaAtingidaMenorPoligonal?.value) {
				if (maiorQue(localData.areaAtingidaMenorPoligonal?.value, value.value)) {
					set(err, 'areaAtingidaMenorPoligonal', [
						'O valor não pode ser maior do que a área total da matrícula do lote'
					]);
				} else {
					err = omit(err, 'areaAtingidaMenorPoligonal');
				}
			}
		}

		setErros(err);
		onChangeHandler([valid]);
	};

	return (
		<fieldset>
			<legend>2. Área do terreno objeto do Projeto</legend>
			<div className="info-terreno-le">
				<div className="pergunta metragem">
					<NumberFieldLE
						name="areaTotalMatriculaLote"
						label="Área total da(s) matrícula(s) do(s) lote(s) (m²)"
						placeHolder="metragem m²"
						required={true}
						onChangeHandler={localChangeHandler}
						value={localData.areaTotalMatriculaLote}
						decimals={2}
						maxSize="10"
					/>
					<ErrorMessages
						errorList={
							get(erros, ['areaTotalMatriculaLote']) ||
							(errosSubmissao || []).filter(e => e.campo === 'areaTotalMatriculaLote')
						}
					/>
				</div>
				<div className="pergunta">
					<RadioFieldLE
						name="possuiAreaDeMenorPoligono"
						label="O terreno possui área de menor polígono?"
						options={OPT_SIM_NAO}
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.possuiAreaDeMenorPoligono}
					/>
					<ErrorMessages
						errorList={
							get(erros, ['possuiAreaDeMenorPoligono']) ||
							(errosSubmissao || []).filter(e => e.campo === 'possuiAreaDeMenorPoligono')
						}
					/>
				</div>
				{possuiAreaDeMenorPoligono && (
					<>
						<div className="pergunta metragem">
							<NumberFieldLE
								name="areaMenorPoligonoLote"
								label="Área do menor polígono do lote (m²)"
								placeHolder="metragem m²"
								required={true}
								onChangeHandler={localChangeHandler}
								value={localData.areaMenorPoligonoLote}
								decimals={2}
								maxSize="10"
							/>
							<ErrorMessages
								errorList={
									get(erros, ['areaMenorPoligonoLote']) ||
									(errosSubmissao || []).filter(e => e.campo === 'areaMenorPoligonoLote')
								}
							/>
						</div>
						<div className="pergunta metragem">
							<NumberFieldLE
								name="areaMenorPoligonoLoteMais5"
								label="Área do menor polígono do lote +5% (m²)"
								placeHolder=""
								required={true}
								onChangeHandler={onChangeHandler}
								value={localData.areaMenorPoligonoLoteMais5}
								decimals={2}
								maxSize="10"
								readOnly={true}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['areaMenorPoligonoLoteMais5']) ||
									(errosSubmissao || []).filter(e => e.campo === 'areaMenorPoligonoLoteMais5')
								}
							/>
						</div>
					</>
				)}
				<div className="pergunta">
					<RadioFieldLE
						name="possuiAreaAtingida"
						label="O terreno possui área atingida?"
						options={OPT_SIM_NAO}
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.possuiAreaAtingida}
					/>
					<ErrorMessages
						errorList={
							get(erros, ['possuiAreaAtingida']) || (errosSubmissao || []).filter(e => e.campo === 'possuiAreaAtingida')
						}
					/>
				</div>
				{possuiAreaAtingida && (
					<>
						<div className="pergunta metragem">
							<NumberFieldLE
								name="areaAtingidaMatricula"
								label="Área atingida da matrícula (m²)"
								placeHolder="metragem m²"
								required={true}
								onChangeHandler={localChangeHandler}
								value={localData.areaAtingidaMatricula}
								decimals={2}
								maxSize="10"
							/>
							<ErrorMessages
								errorList={
									get(erros, ['areaAtingidaMatricula']) ||
									(errosSubmissao || []).filter(e => e.campo === 'areaAtingidaMatricula')
								}
							/>
						</div>
						<div className="pergunta metragem">
							<NumberFieldLE
								name="areaRemanescenteMatricula"
								label="Área remanescente da matrícula (m²)"
								placeHolder=""
								required={true}
								onChangeHandler={onChangeHandler}
								value={localData.areaRemanescenteMatricula}
								decimals={2}
								maxSize="10"
								readOnly={true}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['areaRemanescenteMatricula']) ||
									(errosSubmissao || []).filter(e => e.campo === 'areaRemanescenteMatricula')
								}
							/>
						</div>
						{possuiAreaDeMenorPoligono && (
							<div className="pergunta">
								<RadioFieldLE
									name="possuiAreaAtingidaDeMenorPoligono"
									label="O terreno possui área atingida de menor polígono?"
									options={OPT_SIM_NAO}
									required={true}
									onChangeHandler={onChangeHandler}
									value={localData.possuiAreaAtingidaDeMenorPoligono}
								/>
								<ErrorMessages
									errorList={
										get(erros, ['possuiAreaAtingidaDeMenorPoligono']) ||
										(errosSubmissao || []).filter(e => e.campo === 'possuiAreaAtingidaDeMenorPoligono')
									}
								/>
								{possuiAreaAtingidaDeMenorPoligono && (
									<div className="pergunta metragem">
										<NumberFieldLE
											name="areaAtingidaMenorPoligonal"
											label="Área atingida da menor poligonal (m²)"
											placeHolder="metragem m²"
											required={true}
											onChangeHandler={localChangeHandler}
											value={localData.areaAtingidaMenorPoligonal}
											decimals={2}
											maxSize="10"
										/>
										<ErrorMessages
											errorList={
												get(erros, ['areaAtingidaMenorPoligonal']) ||
												(errosSubmissao || []).filter(e => e.campo === 'areaAtingidaMenorPoligonal')
											}
										/>
									</div>
								)}
							</div>
						)}
						{possuiAreaDeMenorPoligono && (
							<div className="pergunta metragem">
								<NumberFieldLE
									name="areaRemanescenteMenorPoligonal5"
									label="Área remanescente do menor polígono +5% (m²)"
									placeHolder=""
									required={true}
									onChangeHandler={onChangeHandler}
									value={localData.areaRemanescenteMenorPoligonal5}
									decimals={2}
									maxSize="10"
									readOnly={true}
								/>
								<ErrorMessages
									errorList={
										get(erros, ['areaRemanescenteMenorPoligonal5']) ||
										(errosSubmissao || []).filter(e => e.campo === 'areaRemanescenteMenorPoligonal5')
									}
								/>
							</div>
						)}
					</>
				)}

				<div className="pergunta metragem">
					<NumberFieldLE
						name="areaAplicacaoRU"
						label="Área para aplicação do Regime Urbanístico (m²)"
						placeHolder=""
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.areaAplicacaoRU}
						decimals={2}
						maxSize="10"
						readOnly={true}
					/>
					<ErrorMessages
						errorList={
							get(erros, ['areaAplicacaoRU']) || (errosSubmissao || []).filter(e => e.campo === 'areaAplicacaoRU')
						}
					/>
				</div>
			</div>
			<ShowDebug data={localData} label={'localData'} console />
		</fieldset>
	);
}
export default InfosTerrenoLE;

InfosTerrenoLE.displayName = 'InfosTerrenoLE';
InfosTerrenoLE.propTypes = {
	tipoForm: PropTypes.string,
	data: PropTypes.object,
	onChangeHandler: PropTypes.func,
	errosSubmissao: PropTypes.array
};
