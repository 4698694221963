import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';

import { get, size } from 'lodash';

import Confirm from 'components/Confirm';

import useMutableState from 'custom-hooks/useMutableState';

import {
	EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO,
	EXTENSAO_DOC_ESPECIFICO_HABITESE,
	EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS,
	EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO,
	EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS,
	EXTENSOES_DOCUMENTOS_ESPECIFICOS
} from '../Detalhe';

const FORMULARIO_EM_RASCUNHO = 'Formulario em rascunho';
const AGUARDANDO_CRIACAO_PROCESSO_SEI = 'Aguardando criação do processo';
const ARQUIVO_ANEXADO_AO_PROCESSO = 'Anexado ao Processo';
const INVALIDADO_PELO_AGENTE = 'Invalidado pelo agente';
const AINDA_SEM_DOCUMENTO = 'Ainda sem documento';
const EM_COMPLEMENTACAO = 'Em complementacao';
export const DocumentoSlotModal = ({
	documento,
	documentoDados,
	usuarioDonoTask,
	componentDocumento: ComponentDocumento,
	urlEmComplementacao = '',
	labelCriarDocumento = 'Criar Documento',
	labelDocumentoRascunho = 'Documento em rascunho',
	labelDocumentoInvalidado = 'Documento inválidado',
	modalTitulo = 'Preencher Documento',
	modalSubTitulo,
	removeFile,
	limpaDocumentosDados,
	tipoFormularioEspecifico,
	requerimentoEmRascunho
}) => {
	const [showFormulario, setShowFormulario] = useState(false);
	const [posProcessFormulario, setPosProcessFormulario] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [showMessageConfirm, setShowMessageConfirm] = useState('');
	const [salvarEGerarPdf, setSalvarEGerarPdf] = useState(false);
	const usuarioInterno = useMutableState(['licenciamento', 'usuarioInterno']);

	const jaTemArquivo = !!documento.filename;
	const aguardandoCriacaoProcessoSei = !documento.idDocumentoSei && requerimentoEmRascunho;
	const emProcessamento = jaTemArquivo && !documento.idDocumentoSei;
	const emComplementacao = documento.invalido && !documento.substituido;

	let estado = '';
	if (jaTemArquivo) {
		if (aguardandoCriacaoProcessoSei) {
			estado = AGUARDANDO_CRIACAO_PROCESSO_SEI;
		} else if (emProcessamento) {
			estado = EM_COMPLEMENTACAO;
		} else if (emComplementacao) {
			estado = EM_COMPLEMENTACAO;
		} else {
			estado = ARQUIVO_ANEXADO_AO_PROCESSO;
		}
	} else if (documentoDados) {
		estado = FORMULARIO_EM_RASCUNHO;
		if (!documentoDados.draft && documento.original && documentoDados.id === documento.original) {
			estado = INVALIDADO_PELO_AGENTE;
		}
	} else {
		estado = AINDA_SEM_DOCUMENTO;
	}

	const blocoPorEstado = estado => {
		let jsx;
		switch (estado) {
			case AINDA_SEM_DOCUMENTO:
				jsx = (
					<button
						type="button"
						className="btn btn-link"
						onClick={() => (usuarioDonoTask ? setShowFormulario(true) : null)}
					>
						{labelCriarDocumento}
					</button>
				);
				break;

			case FORMULARIO_EM_RASCUNHO: {
				const temDados = size(documentoDados?.dados) > 0;
				jsx = (
					<>
						<button
							type="button"
							className="btn btn-link"
							onClick={() => (usuarioDonoTask ? setShowFormulario(true) : null)}
						>
							{labelDocumentoRascunho}
						</button>
						<div style={{ float: 'right' }}>
							{EXTENSOES_DOCUMENTOS_ESPECIFICOS.includes(documento.extensao) && temDados && (
								<button type="button" className="btn-link" onClick={() => limpaDocumentosDados(documento)}>
									<i className="fa fa-eraser" style={{ fontSize: '18px' }} />
								</button>
							)}
						</div>
					</>
				);
				break;
			}

			case INVALIDADO_PELO_AGENTE:
				jsx = (
					<button
						type="button"
						className="btn btn-link"
						onClick={() => (usuarioDonoTask ? setShowFormulario(true) : null)}
					>
						{labelDocumentoInvalidado}
					</button>
				);
				break;

			case AGUARDANDO_CRIACAO_PROCESSO_SEI:
			case EM_COMPLEMENTACAO:
				jsx = (
					<>
						<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} />
						<i
							className="fa fa-over fa-exclamation-circle"
							style={{
								color: '#09afa7'
							}}
						>
							<div className="icon-tooltip">Arquivo salvo no servidor. Aguardando criação do processo.</div>
						</i>
						<a className="btn-link" target="_blank" rel="noopener noreferrer" href={urlEmComplementacao}>
							{documento.originalName}
						</a>
						{usuarioDonoTask && (
							<div style={{ float: 'right' }}>
								{EXTENSOES_DOCUMENTOS_ESPECIFICOS.includes(documento.extensao) &&
									[AGUARDANDO_CRIACAO_PROCESSO_SEI].includes(estado) && (
										<button type="button" className="btn-link" onClick={() => limpaDocumentosDados(documento)}>
											<i className="fa fa-eraser" style={{ fontSize: '18px' }} />
										</button>
									)}
								<button type="button" className="btn-link" onClick={() => setShowFormulario(true)}>
									<i className="fa fa-pencil-square-o" style={{ fontSize: '18px' }} />
								</button>
								{!EXTENSOES_DOCUMENTOS_ESPECIFICOS.includes(documento.extensao) && (
									<button type="button" className="btn-link" onClick={() => removeFile(documento)}>
										<i className="fa fa-trash-o" style={{ fontSize: '18px' }} />
									</button>
								)}
							</div>
						)}
					</>
				);
				break;

			case ARQUIVO_ANEXADO_AO_PROCESSO:
				jsx = (
					<>
						<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} />
						<i
							className="fa fa-over fa-check"
							style={{
								color: 'green'
							}}
						>
							<div className="icon-tooltip">Arquivo anexado ao processo.</div>
						</i>
						<a href={get(documento, 'urlConsultaPublicaSei')} target="_blank" rel="noopener noreferrer">
							{documento.originalName}
							<i style={{ marginLeft: '8px' }} className="fa fa-external-link" aria-hidden="true" />
						</a>
					</>
				);
				break;

			default:
				jsx = '&nbsp';
		}
		return jsx;
	};

	return (
		<>
			<div className="form-control" style={{ marginTop: '6px', border: '2px solid #c5c5c9', padding: '16px' }}>
				{blocoPorEstado(estado)}
			</div>
			{confirm && (
				<Confirm
					msg="Tem certeza que deseja sair? Informações não salvas serão perdidas"
					onConfirm={() => {
						setShowFormulario(false);
						setConfirm(false);
					}}
					onReject={() => setConfirm(false)}
					loader={false}
				/>
			)}
			{size(showMessageConfirm) > 0 && (
				<Confirm
					msg={showMessageConfirm}
					onConfirm={() => {
						setSalvarEGerarPdf(true);
						setShowMessageConfirm('');
					}}
					onReject={() => setShowMessageConfirm('')}
					loader={false}
				/>
			)}
			{(showFormulario || posProcessFormulario) && (
				<Modal
					show={showFormulario || posProcessFormulario}
					onHide={() => setConfirm(true)}
					dialogClassName={
						[
							EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS,
							EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO,
							EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS
						].includes(tipoFormularioEspecifico)
							? 'modal-le modal-90w'
							: tipoFormularioEspecifico === EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO
							? 'modal-demolicao'
							: tipoFormularioEspecifico === EXTENSAO_DOC_ESPECIFICO_HABITESE
							? 'modal-vistoria'
							: 'modal-90w'
					}
					aria-labelledby={modalTitulo}
					backdrop="static"
				>
					<Modal.Header closeButton>
						{[
							EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS,
							EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO,
							EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS
						].includes(tipoFormularioEspecifico) ? (
							<>
								<div id="documentoModal">
									<span className="tituloLE">{modalTitulo}</span>
									<span className="subTituloLE">{modalSubTitulo}</span>
								</div>{' '}
							</>
						) : (
							<Modal.Title id="documentoModal">{modalTitulo}</Modal.Title>
						)}
					</Modal.Header>
					<Modal.Body>
						<ComponentDocumento
							setShowFormulario={setShowFormulario}
							showFormulario={showFormulario}
							setShowConfirm={setConfirm}
							showConfirm={confirm}
							setPosProcessFormulario={setPosProcessFormulario}
							posProcessFormulario={posProcessFormulario}
							setShowMessageConfirm={setShowMessageConfirm}
							setSalvarEGerarPdf={setSalvarEGerarPdf}
							salvarEGerarPdf={salvarEGerarPdf}
							usuarioInterno={usuarioInterno}
							removeFile={removeFile}
						/>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};

DocumentoSlotModal.displayName = 'DocumentoSlotModal';

DocumentoSlotModal.propTypes = {
	documento: PropTypes.object.isRequired,
	documentoDados: PropTypes.object,
	componentDocumento: PropTypes.any.isRequired,
	usuarioDonoTask: PropTypes.bool,
	urlEmComplementacao: PropTypes.string,
	labelCriarDocumento: PropTypes.string,
	labelDocumentoRascunho: PropTypes.string,
	labelDocumentoInvalidado: PropTypes.string,
	modalTitulo: PropTypes.string,
	modalSubTitulo: PropTypes.string,
	removeFile: PropTypes.func,
	limpaDocumentosDados: PropTypes.func,
	tipoFormularioEspecifico: PropTypes.string,
	requerimentoEmRascunho: PropTypes.bool
};

export default DocumentoSlotModal;
