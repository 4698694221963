import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { clone, forEach, get, omit, set } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import WarningMessage from 'components/WarningMessage';

import { TextField } from 'containers/Form/metadata-template/fields';
import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE.js';
import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE.js';
import PlanoDiretorAtividades from 'containers/PlanoDiretor/PlanoDiretorAtividade';
import { TABELA_RESTRICAO_IMPLANTACAO } from 'containers/PlanoDiretor/PlanoDiretorConstantes';
import { MSG_BASE_DESATIVADA } from 'containers/PlanoDiretor/PlanoDiretorRegimeUrbanistico';

import useQueryStore from 'custom-hooks/useQueryStore';

import { API_URL, PLANO_DIRETOR_API_URL } from 'environments';

import { accessApi } from 'utils/injectApi';
import { multiplica, soma, trocaPontoPorVirgula } from 'utils/tools';

// import AtividadeSubUnidade from './AtividadeSubUnidade';
import { PLANODIRETOR_CONTROLNAME } from './InfosPlanoDiretorLE';
import { ATIVIDADES_LE4, ATIVIDADES_LE5, ITEM_HABITACAO_COLETIVA, LE_Enquadramentos } from './MetadataLE';

const OPT_SIM_NAO = [
	{ codigo: 'sim', descricao: 'Sim' },
	{ codigo: 'nao', descricao: 'Não' }
];

export function InfosProjetoSubUnidades({
	data: localData,
	onChangeHandler,
	dadosSubunidade,
	tipoForm,
	atividadeModificada,
	atividadeModificadaCallback,
	errosSubmissao
}) {
	const isNaoResidencial = tipoForm === LE_Enquadramentos.NAO_RESIDENCIAL;
	const isUnifamiliarENaoResidencial = tipoForm === LE_Enquadramentos.UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES;
	const isUnifamiliarUmaOuDuas = [
		LE_Enquadramentos.UNIFAMILIAR_01_ECONO,
		LE_Enquadramentos.UNIFAMILIAR_02_ECONO
	].includes(tipoForm);
	// código da atividade, usado se for unifamiliares 1/2
	const atvCod = get(dadosSubunidade, 'dadosAtividades.0.atividadeAnexo52.codigo');
	// código do grupamento, usado se for unifamiliares 1/2
	const gaCod = get(dadosSubunidade, 'gaRegimeUrbanisticoAnexo.codigo');
	// hardcode atividade coletiva objectid eh 3 usado se unifamiliares 1/2
	// objectid eh o indice, nao da pra usar, alterado pra usar a prop item
	const isColetiva = get(dadosSubunidade, 'dadosAtividades.0.atividadeAnexo52.item') === ITEM_HABITACAO_COLETIVA;
	// codigo, descricao usado se unifamiliares 1/2
	const implantacao = get(dadosSubunidade, 'dadosAtividades.0.implantacaoAnexo53', {});

	const [showPanel] = useState(true); //, setShowPanel
	const { data: implantacaoAnexo53Store } = useQueryStore(
		`${API_URL}/collections/anexo53implantacao/`,
		'implantacaoAnexo53'
	);

	const [showAvisoAreaAplicacaoRu, setShowAvisoAreaAplicacaoRu] = useState(false);
	const [showAvisoAlturaDivisa, setShowAvisoAlturaDivisa] = useState(false);
	const [showAvisoAlturaBase, setShowAvisoAlturaBase] = useState(false);
	const [showAvisoAlturaMaxima, setShowAvisoAlturaMaxima] = useState(false);

	// dados do plano diretor
	const planoDiretorData = get(localData, PLANODIRETOR_CONTROLNAME, {});

	// nome
	const nomeid = get(dadosSubunidade, 'subunidade.subunidade');
	// altura readonly
	/* const alturaRegimeUrbanisticoAnexo = set({}, 'value', get(dadosSubunidade, 'alturaRegimeUrbanisticoAnexo.codigo')); */

	// anexo 7.2
	const anexo72 = get(dadosSubunidade, 'possuiAnexo72');
	// para calculos
	const [taxaOcupacao, setTaxaOcupacao] = useState(0);
	const [indiceAproveitamentoRU, setIndiceAproveitamentoRU] = useState(0);
	const [areaTerrenoAtingida, setAreaTerrenoAtingida] = useState(0);
	const [flag, setFlag] = useState(false);

	const [recalcular, setRecalcular] = useState(false);

	// divisa
	const limiteDivisaRegimeUrbanisticoAnexo = get(dadosSubunidade, 'divisaRegimeUrbanisticoAnexo');

	// base
	const limiteBaseRegimeUrbanisticoAnexo = get(dadosSubunidade, 'baseRegimeUrbanisticoAnexo');

	// altura maxima possivel para o projeto
	const certificadoSustentavel = get(localData, 'certificadoSustentavel');
	const limiteAlturaMaxima = useMemo(() => {
		let limiteRU = clone(get(dadosSubunidade, 'alturaRegimeUrbanisticoAnexo', {}));
		if (certificadoSustentavel && !limiteRU.alteradoCertificado) {
			const novoLimite = multiplica(soma(certificadoSustentavel.valor, '1'), limiteRU.codigo);
			limiteRU.codigo = novoLimite;
			limiteRU.descricao = trocaPontoPorVirgula(novoLimite);
			limiteRU.alteradoCertificado = true;
		}
		return limiteRU;
	}, [dadosSubunidade, certificadoSustentavel]);

	const calculos = useCallback(
		dadosSubunidade => {
			// indice de aproveitamento
			dadosSubunidade.indiceAproveitamento = { value: '0' };
			// habitacao coletiva
			if (isColetiva && isUnifamiliarUmaOuDuas) {
				set(dadosSubunidade, 'indiceAproveitamento.value', multiplica(areaTerrenoAtingida, indiceAproveitamentoRU));
			}

			// to da base
			let resultadoTOBase;
			if (anexo72 === 'sim') {
				resultadoTOBase = multiplica(areaTerrenoAtingida, 0.9);
				// resultadoTOCorpo = multiplica(areaTerrenoAtingida, 0.75);
			} else {
				resultadoTOBase = multiplica(areaTerrenoAtingida, taxaOcupacao);
				// resultadoTOCorpo = multiplica(areaTerrenoAtingida, taxaOcupacao);
			}
			// to do corpo
			let resultadoTOCorpo = multiplica(areaTerrenoAtingida, 0.75);
			const codAnexo71 = parseFloat(get(dadosSubunidade, 'subunidade.volumetria', 0));
			if (1 === codAnexo71) {
				resultadoTOCorpo = multiplica(areaTerrenoAtingida, 0.666);
			} else if (21 === codAnexo71) {
				resultadoTOCorpo = multiplica(areaTerrenoAtingida, 0.2);
			} else if (23 === codAnexo71) {
				resultadoTOCorpo = multiplica(areaTerrenoAtingida, 0.5);
			}
			// to da subunidade
			let resultadoTOSubunidade = multiplica(areaTerrenoAtingida, taxaOcupacao);

			dadosSubunidade.resultadoTOSubunidade = { value: resultadoTOSubunidade };
			dadosSubunidade.resultadoTOBase = { value: resultadoTOBase };
			dadosSubunidade.resultadoTOCorpo = { value: resultadoTOCorpo };
			// nao residenciais
			if (isUnifamiliarENaoResidencial || isNaoResidencial) {
				// atividades dessa subunidade
				const atividades = get(dadosSubunidade, 'dadosAtividades', []);
				let totalEconomias = '0';
				let totalAreaAd = '0';
				forEach(atividades, a => {
					const eco = get(a, 'qtdEconomias.value', '0');
					totalEconomias = soma(eco, totalEconomias, { decimais: 0 });
					const ad = get(a, 'areaAdensavel.value', '0');
					totalAreaAd = soma(ad, totalAreaAd);
				});
				set(dadosSubunidade, 'totalAreaAdensavel.value', totalAreaAd);
				set(dadosSubunidade, 'totalQtdEconomias.value', totalEconomias);
				set(dadosSubunidade, 'indiceAproveitamento.value', multiplica(indiceAproveitamentoRU, areaTerrenoAtingida));
			}
		},
		[
			anexo72,
			areaTerrenoAtingida,
			indiceAproveitamentoRU,
			isColetiva,
			isNaoResidencial,
			isUnifamiliarENaoResidencial,
			isUnifamiliarUmaOuDuas,
			taxaOcupacao
		]
	);

	const localChangeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				const [valid] = changes;
				changes = valid;
			}
			let { name, value } = changes;

			if (name === 'errors') {
				return;
			}
			if (name && name.indexOf('_') !== -1) {
				const i = name.indexOf('_');
				name = name.substring(0, i);
			}

			const subid = dadosSubunidade.id;
			const subunidades = get(planoDiretorData, ['dadosSubunidades'], []);
			const index = subunidades.findIndex(ds => ds.id === subid);

			// se nao possui altura de divisa e base, zera os campos internos
			if (name === 'possuiAlturaDivisaBase' && value === 'nao') {
				delete dadosSubunidade.alturaDivisaRegimeUrbanisticoAnexo;
				delete dadosSubunidade.alturaBaseRegimeUrbanisticoAnexo;
			}

			const subuni = Object.assign({}, dadosSubunidade, { [name]: value });

			calculos(subuni);

			set(subunidades, [index], subuni);
			set(planoDiretorData, 'dadosSubunidades', [...subunidades]);
			onChangeHandler && onChangeHandler({ name: PLANODIRETOR_CONTROLNAME, value: { ...planoDiretorData } });
		},
		[calculos, dadosSubunidade, onChangeHandler, planoDiretorData]
	);

	const addRestricaoImplantacao = useCallback(
		async (cru, catv) => {
			const dadosAtividade = get(dadosSubunidade, 'dadosAtividades.0');

			const url = `${PLANO_DIRETOR_API_URL}/restricao/${parseFloat(cru)}/${catv}`;

			try {
				const { data: codRI } = await accessApi(url, true);

				const ctabela = TABELA_RESTRICAO_IMPLANTACAO.find(item => item.descricao === codRI)?.codigo;
				const ri = (implantacaoAnexo53Store || []).find(item => item.codigo === ctabela);

				if (ri) {
					set(dadosAtividade, 'implantacaoAnexo53', ri);
					set(dadosAtividade, 'riAutoSelected', true);
					localChangeHandler && localChangeHandler({ name: 'dadosAtividades', value: [dadosAtividade] });
				}
			} catch (e) {
				localChangeHandler &&
					localChangeHandler({
						name: 'dadosAtividades.0',
						value: omit(dadosAtividade, ['implantacaoAnexo53', 'riAutoSelected'])
					});
			}
		},
		[dadosSubunidade, implantacaoAnexo53Store, localChangeHandler]
	);

	// pega a restricao de implantacao para unifamiliares 1/2
	if (isUnifamiliarUmaOuDuas && atividadeModificada && atvCod && gaCod && !flag) {
		setFlag(true);
		addRestricaoImplantacao(gaCod, atvCod).finally(() => {
			atividadeModificadaCallback && atividadeModificadaCallback();
		});
	}

	useEffect(() => {
		let somaAreasAtingidas = '0';
		const temp = get(planoDiretorData, ['dadosSubunidades'], []);
		temp.forEach(sub => {
			somaAreasAtingidas = soma(sub.areaTerrenoAtingida?.value, somaAreasAtingidas);
		});

		if (somaAreasAtingidas !== localData?.areaAplicacaoRU?.value) {
			setShowAvisoAreaAplicacaoRu(true);
		} else {
			setShowAvisoAreaAplicacaoRu(false);
		}
	}, [localData, planoDiretorData, dadosSubunidade]);

	useEffect(() => {
		const desabilitarConferenciaAltura =
			get(dadosSubunidade, 'contemplaAcomodTelhadoSotaoMezanino') === 'sim' ||
			get(dadosSubunidade, 'contemplaRooftops') === 'sim';

		if (
			parseFloat(get(dadosSubunidade, 'alturaDivisaRegimeUrbanisticoAnexo.value')) >
			parseFloat(limiteDivisaRegimeUrbanisticoAnexo?.codigo)
		) {
			setShowAvisoAlturaDivisa(true);
		} else {
			setShowAvisoAlturaDivisa(false);
		}
		if (
			parseFloat(get(dadosSubunidade, 'alturaBaseRegimeUrbanisticoAnexo.value')) >
			parseFloat(limiteBaseRegimeUrbanisticoAnexo?.codigo)
		) {
			setShowAvisoAlturaBase(true);
		} else {
			setShowAvisoAlturaBase(false);
		}
		if (
			!desabilitarConferenciaAltura &&
			parseFloat(get(dadosSubunidade, 'alturaProjeto.value')) > parseFloat(limiteAlturaMaxima?.codigo)
		) {
			setShowAvisoAlturaMaxima(true);
		} else {
			setShowAvisoAlturaMaxima(false);
		}
	}, [
		dadosSubunidade,
		limiteDivisaRegimeUrbanisticoAnexo,
		limiteBaseRegimeUrbanisticoAnexo,
		planoDiretorData,
		limiteAlturaMaxima
	]);

	// effect dos states de valores
	useEffect(() => {
		const anexo72 = get(dadosSubunidade, 'possuiAnexo72');
		// taxa de ocupacao
		const to = anexo72 ? get(dadosSubunidade, 'toRegimeUrbanisticoAnexo.codigo') : '0';
		// indice aproveitamento do regime urbanistico
		const ia = get(dadosSubunidade, 'iaRegimeUrbanisticoAnexo.codigo');
		// area do terreno atingida
		const at = get(dadosSubunidade, 'areaTerrenoAtingida.value', '0');
		setTaxaOcupacao(to);
		setIndiceAproveitamentoRU(ia);
		setAreaTerrenoAtingida(at);
	}, [dadosSubunidade]);

	// effects para setar os recalculos
	useEffect(() => {
		setRecalcular(true);
	}, [taxaOcupacao, indiceAproveitamentoRU, areaTerrenoAtingida]);

	// effect para realizar o recaltulo
	useEffect(() => {
		if (recalcular) {
			localChangeHandler({});
			setRecalcular(false);
		}
	}, [recalcular, localChangeHandler]);

	const isPossuiAlturaDivisa = dadosSubunidade?.possuiAlturaDivisa === 'sim';
	const isPossuiAlturaBase = dadosSubunidade?.possuiAlturaBase === 'sim';

	return (
		<div className="conjunto-dados-projeto-subunidade">
			<div>
				<div>
					<h2 className="titulo-subunidade-le">Subunidade {nomeid}</h2>
				</div>
				{showPanel && (
					<>
						{(isNaoResidencial || isUnifamiliarENaoResidencial) && (
							<PlanoDiretorAtividades
								data={dadosSubunidade}
								setDataHandler={(value, name) => localChangeHandler({ name, value })}
								showAtividadesItems={isNaoResidencial ? ATIVIDADES_LE5 : ATIVIDADES_LE4}
							/>
						)}
						{(errosSubmissao || []).some(e => e.campo === 'atividadesObrigatorias') && (
							<ErrorMessages
								errorList={(errosSubmissao || []).filter(e =>
									e.campo === 'atividadesObrigatorias' ? e.message : null
								)}
							/>
						)}
						<h3>Dados projeto</h3>
						<div className="pergunta metragem">
							<NumberFieldLE
								label={`Área para aplicação do Regime Urbanístico que incide na subunidade ${nomeid} (m²)`}
								name="areaTerrenoAtingida"
								value={get(dadosSubunidade, 'areaTerrenoAtingida')}
								readOnly={false}
								onChangeHandler={localChangeHandler}
								maxSize="10"
								decimals={2}
								placeHolder="0,00"
								required={true}
							/>
							{(errosSubmissao || []).some(e => e.campo === 'areaTerrenoAtingida') ? (
								<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'areaTerrenoAtingida')} />
							) : showAvisoAreaAplicacaoRu ? (
								<WarningMessage>
									<span>
										{/* <i className="fa fa-exclamation-triangle" style={{ marginRight: '4px' }} /> */}A soma das áreas
										que incidem em todas as subunidades deve ser igual a Área para aplicação do Regime Urbanístico (m²)
									</span>
								</WarningMessage>
							) : null}
						</div>
						<div className="pergunta metragem">
							<NumberFieldLE
								label="Altura do projeto conforme o Art. 112, §1º, III do PDDUA (m)"
								name={'alturaProjeto'}
								value={get(dadosSubunidade, 'alturaProjeto')}
								readOnly={false}
								onChangeHandler={localChangeHandler}
								maxSize="10"
								decimals={2}
								placeHolder="0,00"
								required={true}
							/>
							{(errosSubmissao || []).some(e => dadosSubunidade.id === e.idSub && e.campo === 'alturaProjeto') ? (
								<ErrorMessages
									errorList={(errosSubmissao || []).filter(
										e => dadosSubunidade.id === e.idSub && e.campo === 'alturaProjeto'
									)}
								/>
							) : showAvisoAlturaMaxima ? (
								<WarningMessage>
									<span>
										{`A altura do projeto deve ser menor ou igual ao limite do Plano Diretor (${limiteAlturaMaxima?.descricao} m), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.`}
									</span>
								</WarningMessage>
							) : null}
						</div>

						<div className="pergunta">
							<RadioFieldLE
								label='O projeto contempla o benefício do Art. 113, Inc. II, alínea "c" (acomodação do telhado) e/ou alínea "e" (sótão ou mezanino, etc.) do PDDUA?'
								name={`contemplaAcomodTelhadoSotaoMezanino_${nomeid}`}
								value={get(dadosSubunidade, 'contemplaAcomodTelhadoSotaoMezanino')}
								options={OPT_SIM_NAO}
								onChangeHandler={localChangeHandler}
								required={true}
							/>
							<ErrorMessages
								errorList={(errosSubmissao || []).filter(
									e => dadosSubunidade.id === e.idSub && e.campo === 'contemplaAcomodTelhadoSotaoMezanino'
								)}
							/>
						</div>
						{!isUnifamiliarUmaOuDuas && (
							<div className="pergunta">
								<RadioFieldLE
									label="O projeto contempla a existência de rooftops nos termos do Decreto Municipal nº 20.746/20?"
									name={`contemplaRooftops_${nomeid}`}
									value={get(dadosSubunidade, 'contemplaRooftops')}
									options={OPT_SIM_NAO}
									onChangeHandler={localChangeHandler}
									required={true}
								/>
								<WarningMessage>
									<ErrorMessages
										errorList={(errosSubmissao || []).filter(
											e => dadosSubunidade.id === e.idSub && e.campo === 'contemplaRooftops'
										)}
									/>
								</WarningMessage>
							</div>
						)}
						{isUnifamiliarUmaOuDuas && (
							<div className="pergunta" style={{ position: 'relative' }}>
								<TextField
									label="Restrição quanto à implantação de atividades (Anexo 5.3)"
									value={implantacao?.descricao}
									readOnly={true}
									disabled={true}
									tippy={implantacao?.descricao ? 'Preenchido automaticamente pelo sistema' : null}
								/>
							</div>
						)}
						<div className="pergunta">
							<RadioFieldLE
								name={`possuiAlturaDivisa_${nomeid}`}
								label="O projeto possui altura de divisa?"
								value={dadosSubunidade.possuiAlturaDivisa}
								options={OPT_SIM_NAO}
								onChangeHandler={localChangeHandler}
								required={true}
							/>
							<ErrorMessages
								errorList={(errosSubmissao || []).filter(
									e => dadosSubunidade.id === e.idSub && e.campo === 'possuiAlturaDivisa'
								)}
							/>
						</div>

						{isPossuiAlturaDivisa && (
							<>
								<div className="pergunta metragem">
									<NumberFieldLE
										label="Altura da Divisa (m)"
										name="alturaDivisaRegimeUrbanisticoAnexo"
										value={get(dadosSubunidade, 'alturaDivisaRegimeUrbanisticoAnexo')}
										readOnly={false}
										maxSize="10"
										onChangeHandler={localChangeHandler}
										decimals={2}
										required={true}
									/>
									{(errosSubmissao || []).some(
										e => dadosSubunidade.id === e.idSub && e.campo === 'alturaDivisaRegimeUrbanisticoAnexo'
									) ? (
										<ErrorMessages
											errorList={(errosSubmissao || []).filter(
												e => dadosSubunidade.id === e.idSub && e.campo === 'alturaDivisaRegimeUrbanisticoAnexo'
											)}
										/>
									) : showAvisoAlturaDivisa ? (
										<WarningMessage>
											{/* <i className="fa fa-exclamation-triangle" style={{ marginRight: '4px' }} /> */}
											{`A altura da divisa deve
										ser menor ou igual ao limite do Plano Diretor (${limiteDivisaRegimeUrbanisticoAnexo?.descricao} m), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.`}
										</WarningMessage>
									) : null}
								</div>
							</>
						)}
						{dadosSubunidade?.hideAlturaBase ? (
							<div className="pergunta metragem">
								<WarningMessage>
									<span>{MSG_BASE_DESATIVADA}</span>
								</WarningMessage>
							</div>
						) : (
							<div className="pergunta">
								<RadioFieldLE
									name={`possuiAlturaBase_${nomeid}`}
									label="O projeto possui altura de base?"
									value={dadosSubunidade.possuiAlturaBase}
									options={OPT_SIM_NAO}
									onChangeHandler={localChangeHandler}
									required={true}
								/>
								<ErrorMessages
									errorList={(errosSubmissao || []).filter(
										e => dadosSubunidade.id === e.idSub && e.campo === 'possuiAlturaBase'
									)}
								/>
							</div>
						)}
						{isPossuiAlturaBase && (
							<>
								<div className="pergunta metragem">
									<NumberFieldLE
										label="Altura da Base (m)"
										name="alturaBaseRegimeUrbanisticoAnexo"
										value={get(dadosSubunidade, 'alturaBaseRegimeUrbanisticoAnexo')}
										readOnly={false}
										onChangeHandler={localChangeHandler}
										maxSize="10"
										decimals={2}
										required={true}
									/>
									{(errosSubmissao || []).some(
										e => dadosSubunidade.id === e.idSub && e.campo === 'alturaBaseRegimeUrbanisticoAnexo'
									) ? (
										<ErrorMessages
											errorList={(errosSubmissao || []).filter(
												e => dadosSubunidade.id === e.idSub && e.campo === 'alturaBaseRegimeUrbanisticoAnexo'
											)}
										/>
									) : showAvisoAlturaBase ? (
										<WarningMessage>
											{`A altura da base deve ser menor ou igual ao limite do Plano Diretor (${limiteBaseRegimeUrbanisticoAnexo?.descricao} m), exceto em caso de
												Estudo de Viabilidade Urbanístico aprovado e válido.`}
										</WarningMessage>
									) : null}
								</div>
							</>
						)}
						{(isColetiva || isNaoResidencial || isUnifamiliarENaoResidencial) && (
							<div className="pergunta metragem">
								<NumberFieldLE
									label="Resultado da Área aplicação do Regime Urbanístico x Índice de Aproveitamento (IA) da Subunidade (m²)"
									value={dadosSubunidade.indiceAproveitamento}
									readOnly={true}
									maxSize="10"
									decimals={2}
								/>
							</div>
						)}
						<div className="pergunta metragem">
							<NumberFieldLE
								label="Resultado da Área aplicação do Regime Urbanístico x T.O. da Subunidade (m²)"
								value={dadosSubunidade.resultadoTOSubunidade}
								readOnly={true}
								maxSize="10"
								decimals={2}
							/>
						</div>
						<div className="pergunta metragem">
							<NumberFieldLE
								label="Resultado da Área aplicação do Regime Urbanístico x T.O. da Base  (m²)"
								value={dadosSubunidade.resultadoTOBase}
								readOnly={true}
								maxSize="10"
								decimals={2}
							/>
						</div>
						<div className="pergunta metragem">
							<NumberFieldLE
								label="Resultado da Área aplicação do Regime Urbanístico x T.O. do Corpo  (m²)"
								value={dadosSubunidade.resultadoTOCorpo}
								readOnly={true}
								maxSize="10"
								decimals={2}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

InfosProjetoSubUnidades.displayName = 'InfosProjetoSubUnidades';
InfosProjetoSubUnidades.propTypes = {
	data: PropTypes.object,
	onChangeHandler: PropTypes.func,
	tipoForm: PropTypes.string,
	dadosSubunidade: PropTypes.object,
	atividadeModificada: PropTypes.bool,
	atividadeModificadaCallback: PropTypes.func,
	errosSubmissao: PropTypes.array
};
