import { get, isNil, size, forEach, isEmpty, trim } from 'lodash';

import { ATIVIDADES_EVU, TABELA_RESTRICAO_IMPLANTACAO } from 'containers/PlanoDiretor/PlanoDiretorConstantes';

import { isDebug, maiorQue, multiplica, soma, trocaPontoPorVirgula } from 'utils/tools';

import { PLANODIRETOR_CONTROLNAME } from './InfosPlanoDiretorAP';
import {
	includesTipoForm,
	INFOSPROJETO_CONTROLNAME,
	isAtividadeDepositoRevendaGas,
	isAtividadesEnsino,
	isDecretoEntrenimentoNoturno
} from './InfosProjetoAP';
import { METAFORMAP } from './MetadataAP';
import {
	PLANILHA_PROPERTY,
	validaPlanilhaIndicesConstrutivos
} from './PlanilhaIndicesConstrutivos/PlanilhaIndicesConstrutivos';
import {
	ATV_COLETIVA,
	ATV_MULTIFAMILIAR,
	ATV_NAO_RESIDENCIAL,
	ATV_NAO_RESIDENCIAL_GERAL,
	ATV_UNIFAMILIAR,
	AVISO_ESTUDO,
	UAP_ENQUADRAMENTOS_ENUM
} from './TabelaAreasAP/constantsAP';

const {
	UNIFAMILIAR_AUTONOMA,
	UNIFAMILIAR_01_ECONO,
	UNIFAMILIAR_02_ECONO,
	MULTIFAMILIAR,
	NAO_RESIDENCIAL,
	MULTIFAMILIAR_NAO_RESIDENCIAL,
	UNIFAMILIAR_NAO_RESIDENCIAL,
	HABITACAO_COLETIVA
} = UAP_ENQUADRAMENTOS_ENUM;

let vetorObrigatorios = [];
let camposOcultos = [];
let isQuartoDistrito = false;

/**
 * Valida o form conforme regras de negócio.
 *
 * @param {object} data
 * @param {string} tipoForm
 * @returns {formvalidado}
 *
 * @typedef formValidado
 * @property {string[]} validacao Array de erros
 * @property {boolean} isBloqueante Determina se algum dos erros bloqueia o envio do form
 */
export function validarForm(data, tipoForm, formulario) {
	if (!tipoForm) return { validacao: [], isBloqueante: false };

	const isUnifamiliarAutonoma = includesTipoForm([UNIFAMILIAR_AUTONOMA], tipoForm);
	const isUnifamiliarUmaOuDuasEconomias = includesTipoForm(
		[UNIFAMILIAR_01_ECONO, UNIFAMILIAR_02_ECONO, HABITACAO_COLETIVA],
		tipoForm
	);
	const isNaoResidencialOuMultifamiliar = includesTipoForm([NAO_RESIDENCIAL, MULTIFAMILIAR], tipoForm);

	isQuartoDistrito = get(formulario, 'formData.data.enderecoCdlList', []).some(cdl => cdl.regiao === 'quarto_distrito');

	let formdados = { ...data };
	let validacao = [];
	let isBloqueante = false;

	const detalhesAreas = get(formdados, 'detalhesAreas', {});
	// const areasPlanilha = get(detalhesAreas, 'areasPlanilha');
	// const areasTotais = get(detalhesAreas, 'areasTotais');
	const economias = get(detalhesAreas, 'economias');
	// const areaDemolir = get(detalhesAreas, 'areaDemolir');
	const obtemTotal = property => {
		const total = (economias || []).reduce((accEco, economia) => {
			const valor = economia.pavimentos.reduce((accPav, pavimento) => {
				const valor = pavimento.naturezasEspecies.reduce((accNat, naturezaEspecie) => {
					const valor = Object.keys(naturezaEspecie).reduce((accArea, areaKey) => {
						const area = naturezaEspecie[areaKey];
						const valor = Object.keys(area).reduce((accTipo, tipoKey) => {
							const tipo = area[tipoKey];
							return accTipo + parseFloat(tipo?.[property]?.value || 0);
						}, 0);
						return accArea + valor;
					}, 0);
					return accNat + valor;
				}, 0);
				return accPav + valor;
			}, 0);
			return accEco + valor;
		}, 0);
		return { value: total.toFixed(2) };
	};

	const totalAdensavel = obtemTotal('mtAdensavel');
	const totalNaoAdensavel = obtemTotal('mtNaoAdensavel');
	// const totalIsenta = obtemTotal('mtIsenta');
	// const areaTotal = { value: get(areasTotais, 'total', 0).toFixed(2) };

	let errosObrigatorios = [];
	vetorObrigatorios = [];

	// 4 distrito tem menos validacoes obrigatorias
	errosObrigatorios = validaCamposObrigatorios(data, tipoForm, errosObrigatorios, validacao);
	if (size(errosObrigatorios)) {
		isBloqueante = true;
		adicionaErros(validacao, errosObrigatorios);
	}

	if (isQuartoDistrito) {
		const terrenoValidado = validarInfosTerreno(formdados);
		adicionaErros(validacao, terrenoValidado.erros);
		isBloqueante = isBloqueante || terrenoValidado.isBloqueante;
		return { validacao, isBloqueante };
	}

	const { erros: errosSubunidaes, isBloqueante: bloqSubunidades } = validarSubUnidades(formdados, tipoForm);
	adicionaErros(validacao, errosSubunidaes);
	isBloqueante = isBloqueante || bloqSubunidades;

	if (!isUnifamiliarAutonoma) {
		const validInfosProjeto = validaInfosProjeto(formdados, tipoForm);
		adicionaErros(validacao, validInfosProjeto.erros);
		isBloqueante = isBloqueante || validInfosProjeto.isBloqueante;

		// const validRestricoesProjeto = validaRestricoesProjeto(formdados);
		// adicionaErros(validacao, validRestricoesProjeto.erros);
		// isBloqueante = isBloqueante || validRestricoesProjeto.isBloqueante;

		const isEnqHabitacaoColetiva = includesTipoForm([HABITACAO_COLETIVA], tipoForm);
		if (isEnqHabitacaoColetiva || isNaoResidencialOuMultifamiliar) {
			// (IA)
			const limite = get(formdados, 'infosProjeto.indiceAproveitamentoTotalPermitido.value', '0');
			// (IA) total permitido * 50%
			const limite50 = multiplica(limite, '0.5');

			if (maiorQue(totalAdensavel, limite)) {
				adicionaErros(validacao, [
					{
						campo: 'totalAdensavel',
						message: 'A Área total Ad (m²) não pode ser superior ao Índice de Aproveitamento (IA) total permitido (m²)'
					}
				]);
				isBloqueante = false;
			}
			if (maiorQue(totalNaoAdensavel, limite50)) {
				adicionaErros(validacao, [
					{
						campo: 'totalNaoAdensavel',
						message:
							'A Área total Nad (m²) não pode ser superior ao Índice de Aproveitamento (IA) total permitido (m²) * 50%'
					}
				]);
				isBloqueante = false;
			}
		}
	}

	if (isUnifamiliarAutonoma) {
		const validInfos = validaInfosDispositivosControle(formdados, tipoForm);
		adicionaErros(validacao, validInfos);
		isBloqueante = isBloqueante || validInfos.length > 0;

		if (maiorQue(formdados?.infosProjeto?.taxaOcupacaoProjeto?.value, formdados?.quotaTaxaOcupacao?.value)) {
			isBloqueante = true;
			adicionaErros(validacao, [
				{
					campo: 'taxaOcupacaoProjeto',
					message:
						'A Taxa de Ocupação do Projeto (m²) deve ser menor ou igual à Taxa de Ocupação (m²) para a Unidade Autônoma conforme a aprovação do condomínio, exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
				}
			]);
		}

		if (maiorQue(totalAdensavel?.value, formdados?.quotaIndiceAproveitamento?.value)) {
			isBloqueante = true;
			adicionaErros(validacao, [
				{
					campo: 'totalAdensavel',
					message:
						'O Índice de Aproveitamento - IA do projeto deve ser menor ou igual ao Índice de Aproveitamento - IA* para a Unidade Autônoma conforme a aprovação do condomínio'
				}
			]);
		}

		if (
			isNil(get(formdados, 'infosProjeto.taxaOcupacaoProjeto.value')) ||
			get(formdados, 'infosProjeto.taxaOcupacaoProjeto.value') === '0.00'
		) {
			adicionaErros(validacao, [
				{ campo: 'taxaOcupacaoProjeto', message: 'Taxa de Ocupação do Projeto (m²) deve ser informado.' }
			]);
		}

		if (size(get(formdados, 'infosProjeto.tipoConstrucao')) < 1) {
			adicionaErros(validacao, [{ campo: 'tipoConstrucao', message: 'Tipo de material deve ser informado.' }]);
		}
	}

	if (isUnifamiliarUmaOuDuasEconomias) {
		const terrenoValidado = validarInfosTerreno(formdados);
		adicionaErros(validacao, terrenoValidado.erros);
		isBloqueante = isBloqueante || terrenoValidado.isBloqueante;
	}

	// planilha de áreas not null
	if (
		size(formdados?.detalhesAreas?.areasPlanilha) === 1 &&
		formdados.detalhesAreas.areasPlanilha[0] === 'permanecer'
	) {
		isBloqueante = true;
		adicionaErros(validacao, [
			{ campo: 'areasPlanilha', message: 'Não é permitido selecionar apenas área a permanecer.' }
		]);
	} else if (!formdados?.detalhesAreas?.areasTotais?.total || formdados?.detalhesAreas?.areasTotais?.total === '0.00') {
		isBloqueante = true;
		adicionaErros(validacao, [
			{ campo: 'planilhaAreas', message: 'É necessário preencher ao menos um valor na planilha de áreas.' }
		]);
	}

	// Validação de Doc. Extra TPC Local + Potencial Construtivo
	const hasTPC = get(formdados, [PLANODIRETOR_CONTROLNAME, 'dadosSubunidades'], []).some(sub =>
		get(sub, ['planilhaIndices', 'tipoIndices'], []).includes('tpc')
	);
	if (hasTPC) {
		const docPlanAltimetro = get(formulario, 'documentos', []).find(d => d.idDocumento === 'plan-dmi');
		const potencialConstrutivoTpcLocalAux = get(
			formdados,
			`${INFOSPROJETO_CONTROLNAME}.planilhaIndicesTotalizada.potencialConstrutivoTpcLocal.subtotalAreaCalculada`,
			0.0
		);
		if (!docPlanAltimetro && potencialConstrutivoTpcLocalAux > 0.0) {
			adicionaErros(validacao, [
				{ campo: 'docExtra', message: 'É necessário anexar o documento Levantamento Planialtimétrico.' }
			]);
		}
	}

	return { validacao, isBloqueante };
}

/**
 * ÁREA DO TERRENO OBJETO DO PROJETO
 * @param {*} data
 * @returns
 */
function validarInfosTerreno(data) {
	let erros = [];
	let isBloqueante = false;
	let formdados = data;

	// InfosTerrenos
	const possuiAreaDeMenorPoligono = formdados?.possuiAreaDeMenorPoligono === 'sim';
	const possuiAreaAtingida = formdados?.possuiAreaAtingida === 'sim';

	if (maiorQue(formdados?.areaMenorPoligonoLote?.value, formdados?.areaTotalMatriculaLote?.value)) {
		// erro
		erros.push({
			campo: 'areaMenorPoligonoLote',
			message: 'A área do menor poligono não pode ser maior que a área da matrícula do lote'
		});
	}
	if (maiorQue(formdados?.areaAtingidaMatricula?.value, formdados?.areaTotalMatriculaLote?.value)) {
		// erro
		erros.push({
			campo: 'areaAtingidaMatricula',
			message: 'A área do atingida da matrícula não pode ser maior que a área da matrícula do lote'
		});
	}
	if (possuiAreaDeMenorPoligono) {
		if (maiorQue(formdados?.areaAtingidaMenorPoligonal?.value, formdados?.areaMenorPoligonoLote?.value)) {
			// erro
			erros.push({
				campo: 'areaAtingidaMenorPoligonal',
				message: 'A área atingida da menor poligonal não pode ser maior que a área do menor poligono'
			});
		}
	}
	if (possuiAreaDeMenorPoligono && !possuiAreaAtingida) {
		formdados.areaAplicacaoRU = formdados.areaMenorPoligonoLoteMais5;
		// verificação se área do menor polígono não é maior que a área do terreno
		if (maiorQue(formdados?.areaMenorPoligonoLote?.value, formdados.areaTotalMatriculaLote?.value)) {
			// erro
			erros.push({
				campo: 'areaMenorPoligonoLote',
				message: 'A área atingida do menor poligono não pode ser maior que a área da matrícula do lote'
			});
		}
	}

	return { erros, isBloqueante };
}

/**
 * valida campos obrigatorios, é diferente para o 4distrito
 * @param {*} data
 * @param {*} tipoForm
 * @param {*} errosNovos
 * @param {*} errosAtuais
 * @returns
 */
function validaCamposObrigatorios(data, tipoForm, errosNovos, errosAtuais) {
	errosNovos = adicionaErros(errosNovos, errosAtuais);

	camposOcultos = [];

	if (isQuartoDistrito) {
		iteraMETAFORMAP(METAFORMAP.INFOS_TERRENO, tipoForm, data);
	} else {
		// ÁREA DO TERRENO OBJETO DO PROJETO
		iteraMETAFORMAP(METAFORMAP.INFOS_TERRENO, tipoForm, data);
		const dataComDetalhesAreasParaValidacao =
			{ ...data, ...data[INFOSPROJETO_CONTROLNAME], ...data.detalhesAreas } || {};
		// PROJETO
		iteraMETAFORMAP(METAFORMAP.INFOS_PROJETO, tipoForm, dataComDetalhesAreasParaValidacao);
		// iteraMETAFORMAP(METAFORMAP.INFOS_QUOTAS, tipoForm, data);
		// iteraMETAFORMAP(METAFORMAP.INFOS_ALTURAS_MAXIMAS, tipoForm, data);

		iteraMETAFORMAP(METAFORMAP.INFOS_AMBIENTAIS, tipoForm, data);
	}

	for (let index in vetorObrigatorios) {
		errosNovos.push({
			campo: vetorObrigatorios[index].campo,
			message: `Informar "${vetorObrigatorios[index].message}"`
		});
	}

	return errosNovos;
}

const validaInfosDispositivosControle = (data, tipoForm) => {
	const erros = [];

	if (includesTipoForm([UNIFAMILIAR_AUTONOMA], tipoForm)) {
		// QUOTAS
		const quotaIndiceAproveitamento = get(data, 'quotaIndiceAproveitamento.value');
		const quotaTaxaOcupacao = get(data, 'quotaTaxaOcupacao.value');
		const quotaALP = get(data, 'quotaALP.value');
		// ALTURAS
		const alturaMaximaRegimeUrbanistico = get(data, 'alturaMaximaRegimeUrbanistico.value');
		const alturaMaximaAeroportoAerodromo = get(data, 'alturaMaximaAeroportoAerodromo.value');
		// REGRA EXTRA
		const restricoesData = get(data, 'restricoesAdministrativas', data);
		const showALP = get(restricoesData, 'restricaoALPdoCondominio') === 'sim';

		if (showALP && (isNil(quotaALP) || isEmpty(quotaALP))) {
			erros.push({
				campo: 'quotaALP',
				message: 'Área Livre Permeável - ALP (m²) deve ser informado.'
			});
		}
		if (isNil(quotaIndiceAproveitamento) || isEmpty(quotaIndiceAproveitamento)) {
			erros.push({
				campo: 'quotaIndiceAproveitamento',
				message: 'Índice de Aproveitamento - IA deve ser informado.'
			});
		}
		if (isNil(quotaTaxaOcupacao) || isEmpty(quotaTaxaOcupacao)) {
			erros.push({
				campo: 'quotaTaxaOcupacao',
				message: 'Taxa de Ocupação - TO (m²) deve ser informado.'
			});
		}
		if (isNil(alturaMaximaRegimeUrbanistico) || isEmpty(alturaMaximaRegimeUrbanistico)) {
			erros.push({
				campo: 'alturaMaximaRegimeUrbanistico',
				message: 'Altura máxima definida pelo Regime Urbanístico (m) deve ser informado.'
			});
		}
		if (isNil(alturaMaximaAeroportoAerodromo) || isEmpty(alturaMaximaAeroportoAerodromo)) {
			erros.push({
				campo: 'alturaMaximaAeroportoAerodromo',
				message: 'Altura máxima pela guia Aeroporto (DECEA) informada na DMWEB (m) deve ser informado.'
			});
		}
	}

	return erros;
};

const validaInfosProjeto = (data, tipoForm) => {
	// reproduz a regra showAvisoAreaAplicacaoRu
	const ignoraRegra =
		[MULTIFAMILIAR, MULTIFAMILIAR_NAO_RESIDENCIAL, HABITACAO_COLETIVA].includes(tipoForm) &&
		parseFloat(data?.areaAplicacaoRU?.value || 0) > 3000 &&
		data?.areaMatricula3000 === 'sim';

	let somaAreasAtingidas = '0';
	const subs = get(data?.planoDiretor, ['dadosSubunidades'], []);
	subs.forEach(sub => {
		somaAreasAtingidas = soma(sub.areaTerrenoAtingida?.value, somaAreasAtingidas);
	});

	const showAvisoAreaAplicacaoRu = !ignoraRegra && somaAreasAtingidas !== data?.areaAplicacaoRU?.value;
	//

	const erros = [];
	const formdados = get(data, [INFOSPROJETO_CONTROLNAME], {});

	const isMultifamiliar = includesTipoForm([MULTIFAMILIAR], tipoForm);
	const isMultifamiliarNaoResidencial = includesTipoForm([MULTIFAMILIAR_NAO_RESIDENCIAL], tipoForm);
	const isUnifamiliarNaoResidencial = includesTipoForm([UNIFAMILIAR_NAO_RESIDENCIAL], tipoForm);
	const isNaoResidencial = includesTipoForm([NAO_RESIDENCIAL], tipoForm);
	const isNaoResidenciais = isNaoResidencial || isMultifamiliarNaoResidencial || isUnifamiliarNaoResidencial;
	const isEnqHabitacaoColetiva = includesTipoForm([HABITACAO_COLETIVA], tipoForm);

	if (size(get(formdados, 'tipoConstrucao')) < 1) {
		erros.push({
			campo: 'tipoConstrucao',
			message: 'Tipo de material deve ser informado.'
		});
	}

	if (isNaoResidenciais) {
		if (get(formdados, 'atividadeSaudePossuiVagaAmbulancia') === 'nao') {
			erros.push({
				campo: 'atividadeSaudePossuiVagaAmbulancia',
				message:
					'A(s) atividade(s) do projeto deve(m) prever no mínimo 1 vaga especial no interior do lote para Embarque/Desembarque com dimensões para ambulância.'
			});
		}
		if (get(formdados, 'projetoAtendeRestricoesAtividades') === 'nao') {
			erros.push({
				campo: 'projetoAtendeRestricoesAtividades',
				message:
					'A(s) atividade(s) do projeto deve(m) estar de acordo com os Anexos 5.3 e 5.4 do Plano Diretor de Desenvolvimento Urbano Ambiental (PDDUA)'
			});
		}
		if (!showAvisoAreaAplicacaoRu) {
			if (size(trim(get(formdados, 'numeroEconomiasNaoResidencial'))) === 0) {
				erros.push({
					campo: 'numeroEconomiasNaoResidencial',
					message: 'Você deve informar o número de economias não residenciais.'
				});
			}
		}
	}

	if (isMultifamiliar || isUnifamiliarNaoResidencial || isMultifamiliarNaoResidencial) {
		if (!showAvisoAreaAplicacaoRu) {
			if (size(trim(get(formdados, 'numeroEconomiasResidencial'))) === 0) {
				erros.push({
					campo: 'numeroEconomiasResidencial',
					message: 'Você deve informar o número de economias residenciais.'
				});
			}
		}
	}

	if (!showAvisoAreaAplicacaoRu) {
		if (size(trim(get(formdados, 'projetoPossuiBaseCorpo'))) === 0) {
			erros.push({
				campo: 'projetoPossuiBaseCorpo',
				message: 'Você deve informar se o projeto possui base ou corpo.'
			});
		}
	}

	const numeroEconomiasNaoResidencial = get(formdados, 'numeroEconomiasNaoResidencial.value');
	const numeroEconomiasResidencial = get(data, 'infosProjeto.numeroEconomiasResidencial.value');

	if (
		isNaoResidenciais &&
		(numeroEconomiasNaoResidencial === 0 || numeroEconomiasNaoResidencial === '0' || !numeroEconomiasNaoResidencial)
	) {
		erros.push({
			campo: 'numeroEconomiasNaoResidencial',
			message: 'Número de Economias - Não Residencial: deve ser informada ao menos 1 economia não residencial.'
		});
	}

	if (
		!formdados?.trintaPorcentoIa &&
		((isNaoResidencial && parseInt(numeroEconomiasNaoResidencial) === 1) || isEnqHabitacaoColetiva)
	) {
		erros.push({
			campo: 'trintaPorcentoIa',
			message:
				'O projeto utilizará acréscimo de 30% de índice de aproveitamento conforme Art. 107, §5º do PDDUA? deve ser informado.'
		});
	}

	if (isUnifamiliarNaoResidencial && parseInt(numeroEconomiasResidencial) > 2) {
		erros.push({
			campo: 'numeroEconomiasResidencial',
			message: 'Número de Economias - Residencial: não deve ser superior a 2 economias.'
		});
	}

	if ((isMultifamiliarNaoResidencial || isMultifamiliar) && parseInt(numeroEconomiasResidencial) < 3) {
		erros.push({
			campo: 'numeroEconomiasResidencial',
			message: 'Número de Economias - Residencial: deve ser superior ou igual a 3 economias.'
		});
	}

	const isProjetoPossuiBaseCorpo =
		formdados.projetoPossuiBaseCorpo === 'sim' ? true : formdados.projetoPossuiBaseCorpo === 'nao' ? false : undefined;

	if (isProjetoPossuiBaseCorpo === true) {
		delete erros.taxaOcupacaoTotalPermitido;
		delete erros.taxaOcupacaoTotalPermitido;

		let valInformado = get(formdados, ['totalBaseProjeto', 'value']);
		// let valLimite = get(formdados, ['totalPermitidoBase', 'value']);
		if (isNil(valInformado) || valInformado === '0.00') {
			erros.push({
				campo: 'totalBaseProjeto',
				message: 'A Taxa de Ocupação (TO) total da base do projeto (m²) deve ser informada'
			});
		}
		// transformada em warning no InfosProjetoAP
		// if (maiorQue(valInformado, valLimite) && get(data, 'infosProjeto.projetoPossuiEvuValido') !== 'sim') {
		// 	erros.push({
		// 		campo: 'totalBaseProjeto',
		// 		message:
		// 			'A Taxa de Ocupação (TO) total da base do projeto (m²) deve ser menor ou igual a Taxa de Ocupação (TO) total permitido para a base (m²), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
		// 	});
		// }
		valInformado = get(formdados, ['totalCorpoProjeto', 'value']);
		// valLimite = get(formdados, ['totalPermitidoCorpo', 'value']);
		if (isNil(valInformado) || valInformado === '0.00') {
			erros.push({
				campo: 'totalCorpoProjeto',
				message: 'A Taxa de Ocupação (TO) total do corpo do projeto (m²) deve ser informada'
			});
		}
		// transformada em warning no InfosProjetoAP
		// if (maiorQue(valInformado, valLimite) && get(data, 'infosProjeto.projetoPossuiEvuValido') !== 'sim') {
		// 	erros.push({
		// 		campo: 'projetoPossuiEvuValido',
		// 		message:
		// 			'A Taxa de Ocupação (TO) total do corpo do projeto (m²) deve ser menor ou igual a Taxa de Ocupação (TO) total permitido para o corpo (m²), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
		// 	});
		// }
	}

	const alturaMaximaAeroportoAerodromo = get(formdados, 'alturaMaximaAeroportoAerodromo.value');
	const alturaTotal = get(formdados, 'alturaTotalProjeto.value');

	if (
		maiorQue(alturaTotal, alturaMaximaAeroportoAerodromo) &&
		get(data, 'dmiRestricaoAlturaProximidadeAeroporto') === 'nao'
	) {
		erros.push(
			'Há divergência nas respostas do formulário. Verificar a restrição relativa à ALTURA FACE PROXIMIDADE AEROPORTUÁRIA. A altura total do projeto é maior do que a Altura máxima definida pela guia Aeroporto informada na DMWEB, anexe o documento AUTORIZAÇÃO do DECEA (DECLARAÇÃO DE INEXIGIBILIDADE) no botão "Adicionar documento" da aba DOCUMENTOS após concluir este formulário.'
		);
	}
	if (
		maiorQue(alturaTotal, alturaMaximaAeroportoAerodromo) &&
		get(data, 'dmiRestricaoAlturaProximidadeAeroporto') === 'sim' &&
		get(data, 'edificacaoUltrapassaAlturaDECEA') === 'nao'
	) {
		erros.push(
			'Há divergência nas respostas do formulário. Verificar a restrição relativa à ALTURA FACE PROXIMIDADE AEROPORTUÁRIA. A altura total do projeto é maior do que a Altura máxima definida pela guia Aeroporto informada na DMWEB, anexe o documento AUTORIZAÇÃO do DECEA (DECLARAÇÃO DE INEXIGIBILIDADE) no botão "Adicionar documento" da aba DOCUMENTOS após concluir este formulário.'
		);
	}
	const toInformado = get(formdados, ['taxaOcupacaoTotal', 'value']);
	// const toLimite = get(formdados, ['taxaOcupacaoTotalPermitido', 'value']);
	if (isNil(toInformado) || toInformado === '0.00') {
		erros.push({
			campo: 'taxaOcupacaoTotal',
			message: 'A Taxa de Ocupação (TO) total do Projeto (m²) deve ser informada'
		});
	}
	// transformada em warning no InfosProjetoAP
	// if (maiorQue(toInformado, toLimite) && get(data, 'infosProjeto.projetoPossuiEvuValido') !== 'sim') {
	// 	erros.push({
	// 		campo: 'taxaOcupacaoTotal',
	// 		message:
	// 			'A Taxa de Ocupação (TO) total do Projeto (m²) deve ser menor ou igual a Taxa de Ocupação (TO) total permitido (m²), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
	// 	});
	// }

	const valLimiteLote = get(data, ['areaTotalMatriculaLote', 'value']);
	if (maiorQue(toInformado, valLimiteLote)) {
		erros.push({
			campo: 'taxaOcupacaoTotal',
			message:
				'A Taxa de Ocupação (TO) total do Projeto (m²) deve ser menor ou igual a Área total de matrícula do lote (m²).'
		});
	}

	//quantidade máxima de economias permitida de acordo com regra de quota ideal do plano diretor
	if ((isMultifamiliar || isMultifamiliarNaoResidencial) && data?.infosProjeto?.deveUsarQuotaIdealProjeto) {
		//totalQtdVagasCondominiais
		const totalQtdVagasCondominiais = get(data, 'infosProjeto.totalQtdVagasCondominiais.value');
		if (maiorQue(totalQtdVagasCondominiais, '400')) {
			// projetoPossuiEvu2GrauValido dever ser obrigatório
			if (isNil(get(data, 'infosProjeto.projetoPossuiEvu2GrauValido'))) {
				erros.push({
					campo: 'projetoPossuiEvu2GrauValido',
					message: 'Informar "O projeto possui Estudo de Viabilidade Urbanística de 2º grau aprovado e válido?"'
				});
			}
		}

		if (maiorQue(totalQtdVagasCondominiais, '100')) {
			// calcula os 2% do totalQtdVagasCondominiais
			const minimoVagasPCD = multiplica(totalQtdVagasCondominiais, '0.02');
			const qtdVagasDeficiencia = get(data, 'infosProjeto.qtdVagasDeficiencia.value');
			// Se não tiver 2% das vagas, mostrar a seguinte mensagem:
			if (maiorQue(minimoVagasPCD, qtdVagasDeficiencia)) {
				erros.push({
					campo: 'qtdVagasDeficiencia',
					message:
						'O número de vagas para PcD deve estar de acordo com a NBR 9050 que exige 2% das vagas totais para vagas para PcD'
				});
			}
		}
	}

	// validacoes nao residenciais conforme atividades
	if (isNaoResidenciais) {
		const subunidades = get(data, 'planoDiretor.dadosSubunidades', []);
		// decreto
		if (isDecretoEntrenimentoNoturno(subunidades)) {
			if (isNil(formdados.atividadeEntrenenimentoNoturno)) {
				erros.push({
					campo: 'atividadeEntrenenimentoNoturno',
					message:
						'Informar "A(s) atividade(s) do projeto enquadra-se como atividade de entretenimento noturno, com horário de funcionamento que se estenda após as 00h00min, de acordo com o Art. 1º do Decreto Municipal nº 18.572/2014?"'
				});
			}
			if (isNil(formdados.atividadeEntrenenimentoNoturnoComMusicaAmplificada)) {
				erros.push({
					campo: 'atividadeEntrenenimentoNoturnoComMusicaAmplificada',
					message:
						'Informar "A(s) atividade(s) do projeto enquadra-se como atividade de entretenimento noturno, com horário de funcionamento que se estenda após as 00h00min e com música amplificada (mecânica e/ou ao vivo), de acordo com o §1º do Art. 1º do Decreto Municipal nº 18.572/2014?"'
				});
			}
		}
		// ensino
		if (isAtividadesEnsino(subunidades)) {
			if (isNil(formdados.atividadeEnsinoPreEscolarInfantilMaternal)) {
				erros.push({
					campo: 'atividadeEnsinoPreEscolarInfantilMaternal',
					message:
						'Informar "A(s) atividade(s) do projeto enquadra-se como estabelecimento de ensino pré-escolar/escola de educação infantil/escola maternal?"'
				});
			}
		}
		// posto de gas
		if (isAtividadeDepositoRevendaGas(subunidades)) {
			if (isNil(formdados.atividadeDepositoPostoRevendaGas)) {
				erros.push({
					campo: 'atividadeDepositoPostoRevendaGas',
					message: 'Informar "A(s) atividade(s) do projeto enquadra-se como depósito ou posto de revenda de gás?"'
				});
			} else if (formdados.atividadeDepositoPostoRevendaGas === 'sim') {
				if (isNil(formdados.atividadeDepositoPostoRevendaGasPossuiDistanciaMinima)) {
					erros.push({
						campo: 'atividadeDepositoPostoRevendaGasPossuiDistanciaMinima',
						message:
							'Informar "O projeto atende as distâncias mínimas de segurança, conforme determina o Anexo 5.8, alterado pelo Dec. 17.892/2012, se for o caso de revenda e depósito de gás?"'
					});
				} else if (formdados.atividadeDepositoPostoRevendaGasPossuiDistanciaMinima === 'nao') {
					erros.push({
						campo: 'atividadeDepositoPostoRevendaGasPossuiDistanciaMinima',
						message:
							'O projeto deve atender as distâncias mínimas de segurança, conforme determina o Anexo 5.8, alterado pelo Dec. 17.892/2012, se for o caso de revenda e depósito de gás.'
					});
				}
			}
		}
	}
	//viabilidade urbanistica
	if (
		isNaoResidenciais &&
		formdados.projetoImpactoUrbano === 'sim' &&
		formdados.projetoViabilidaUrbanistico === 'nao'
	) {
		erros.push({
			campo: 'projetoViabilidaUrbanistico',
			message: AVISO_ESTUDO
		});
	}

	return { erros, isBloqueante: erros.length > 0 };
};

const validarSubUnidades = (data, tipoForm) => {
	const erros = [];
	const dadosSubUnidades = get(data, 'planoDiretor.dadosSubunidades', []);
	const certificadoSustentavel = get(data, 'certificadoSustentavel');

	const possuiIndicesConstrutivos = get(data, [INFOSPROJETO_CONTROLNAME, 'aquisicaoIndicesConstrutivos']) === 'sim';
	const planilhaIndicesConstrutivos = get(data, [INFOSPROJETO_CONTROLNAME, PLANILHA_PROPERTY]);

	const isEnqHabColetiva = includesTipoForm([HABITACAO_COLETIVA], tipoForm);
	const isNaoResidencial = includesTipoForm([NAO_RESIDENCIAL], tipoForm);
	const isMultifamiliar = includesTipoForm([MULTIFAMILIAR], tipoForm);
	const isMultifamiliarNaoResidencial = includesTipoForm([MULTIFAMILIAR_NAO_RESIDENCIAL], tipoForm);
	const isUnifamiliarNaoResidencial = includesTipoForm([UNIFAMILIAR_NAO_RESIDENCIAL], tipoForm);
	const isUnidadeAutonoma = includesTipoForm([UNIFAMILIAR_AUTONOMA], tipoForm);
	const isUnifamiliarUmaOuDuas = includesTipoForm(
		[UNIFAMILIAR_01_ECONO, UNIFAMILIAR_02_ECONO, HABITACAO_COLETIVA],
		tipoForm
	);
	const isNaoResidenciais = isNaoResidencial || isMultifamiliarNaoResidencial || isUnifamiliarNaoResidencial;

	if (!isUnidadeAutonoma) {
		if (dadosSubUnidades.length === 0) {
			erros.push({
				campo: 'planoDiretorVazio',
				message: 'Plano diretor deve ser preenchido'
			});
		}

		// #region VALIDAR ATIVIDADES OBRIGATÓRIAS POR TIPO DE FORMULÁRIO
		const temRequeridos = requeridos =>
			requeridos.reduce(
				(acc, req) =>
					dadosSubUnidades.reduce(
						(acc, { dadosAtividades = [] }) =>
							acc || dadosAtividades.some(atv => req.includes(trim(atv.atividadeAnexo52.item))),
						false
					),
				false
			);

		if (includesTipoForm([UNIFAMILIAR_01_ECONO, UNIFAMILIAR_02_ECONO], tipoForm)) {
			const requeridos = [ATV_UNIFAMILIAR];
			if (!temRequeridos(requeridos)) {
				erros.push({
					campo: 'atividadesObrigatorias',
					message: 'Deve ser informada uma atividade de HABITAÇÃO UNIFAMILIAR em ao menos uma subunidade"'
				});
			}
		} else if (includesTipoForm([HABITACAO_COLETIVA], tipoForm)) {
			const requeridos = [ATV_COLETIVA];
			if (!temRequeridos(requeridos)) {
				erros.push({
					campo: 'atividadesObrigatorias',
					message: 'Deve ser informada uma atividade de HABITAÇÃO COLETIVA em ao menos uma subunidade"'
				});
			}
		} else if (includesTipoForm([MULTIFAMILIAR, MULTIFAMILIAR_NAO_RESIDENCIAL], tipoForm)) {
			const requeridos = [ATV_MULTIFAMILIAR];
			if (!temRequeridos(requeridos)) {
				erros.push({
					campo: 'atividadesObrigatorias',
					message: 'Deve ser informada uma atividade de HABITAÇÃO MULTIFAMILIAR em ao menos uma subunidade"'
				});
			}
		}
		if (includesTipoForm([NAO_RESIDENCIAL, MULTIFAMILIAR_NAO_RESIDENCIAL, UNIFAMILIAR_NAO_RESIDENCIAL], tipoForm)) {
			const requeridos = [tipoForm === UNIFAMILIAR_NAO_RESIDENCIAL ? ATV_NAO_RESIDENCIAL_GERAL : ATV_NAO_RESIDENCIAL];
			if (!temRequeridos(requeridos)) {
				erros.push({
					campo: 'atividadesObrigatorias',
					message:
						'Deve ser informada uma atividade NÃO RESIDENCIAL (comércio/serviço/indústria/atividade especial) em ao menos uma subunidade'
				});
			}
		}
		// #endregion

		dadosSubUnidades.forEach(sub => {
			const subnumero = sub?.subunidade?.subunidade;
			const nome = `Subunidade ${subnumero}`;

			if (sub?.subunidade?.atividade === '99.99' && isNil(sub?.possuiRuDefinidoPorLegislacao)) {
				erros.push({
					idSub: sub?.id,
					campo: 'possuiRuDefinidoPorLegislacao',
					message: `Este projeto possui regime urbanístico definido no terreno por legislação? deve ser informada na ${nome}`
				});
			}

			if (isNil(sub?.possuiAlturaDivisa)) {
				erros.push({
					idSub: sub?.id,
					campo: 'possuiAlturaDivisa',
					message: `O projeto possui altura de divisa? deve ser informada na ${nome}`
				});
			}

			if (isNil(sub?.alturaProjeto)) {
				erros.push({
					idSub: sub?.id,
					campo: 'alturaProjeto',
					message: `Altura do projeto conforme o Art. 112, §1º, III do PDDUA (m) deve ser informada na ${nome}`
				});
			}

			if (isNil(sub?.contemplaAcomodTelhadoSotaoMezanino)) {
				erros.push({
					idSub: sub?.id,
					campo: 'contemplaAcomodTelhadoSotaoMezanino',
					message: `O projeto contempla o benefício do Art. 113, Inc. II, alínea "c" (acomodação do telhado) e/ou "e" (sótão ou mezanino, etc.) do PDDUA? deve ser informada na ${nome}`
				});
			}

			if (!isUnifamiliarUmaOuDuas && isNil(sub?.contemplaRooftops)) {
				erros.push({
					idSub: sub?.id,
					campo: 'contemplaRooftops',
					message: `O projeto contempla a existência de rooftops nos termos do Decreto Municipal nº 20.746/20? deve ser informada na ${nome}`
				});
			}

			if (
				sub?.possuiAlturaDivisa &&
				sub?.possuiAlturaDivisa === 'sim' &&
				(isNil(sub?.alturaDivisaRegimeUrbanisticoAnexo) ||
					parseFloat(sub?.alturaDivisaRegimeUrbanisticoAnexo?.value) === 0.0)
			) {
				erros.push({
					idSub: sub?.id,
					campo: 'alturaDivisaRegimeUrbanisticoAnexo',
					message: `A altura de divisa deve ser informada na ${nome}`
				});
			}

			if (isNil(sub?.possuiAlturaBase) && !sub?.hideAlturaBase) {
				erros.push({
					idSub: sub?.id,
					campo: 'possuiAlturaBase',
					message: `O projeto possui altura de base? deve ser informada na ${nome}`
				});
			}

			if (
				sub?.possuiAlturaBase &&
				sub?.possuiAlturaBase === 'sim' &&
				(isNil(sub?.alturaBaseRegimeUrbanisticoAnexo) ||
					parseFloat(sub?.alturaBaseRegimeUrbanisticoAnexo?.value) === 0.0)
			) {
				erros.push({
					idSub: sub?.id,
					campo: 'alturaBaseRegimeUrbanisticoAnexo',
					message: `A altura de base deve ser informada na ${nome}`
				});
			}

			if (isNil(sub?.possuiAnexo72)) {
				erros.push({
					idSub: sub?.id,
					campo: 'possuiAnexo72',
					message: `O terreno está listado no Anexo 7.2 do Plano Diretor? deve ser informada na ${nome}`
				});
			}

			if (isNaoResidencial || isMultifamiliar || isMultifamiliarNaoResidencial || isUnifamiliarNaoResidencial) {
				forEach(sub.dadosAtividades, a => {
					const atvItem = get(a, 'atividadeAnexo52.item');
					const atvName = get(a, 'atividadeAnexo52.atv');
					const atv = `${atvItem}${atvName}`;
					const isIndustriasInterferenciaAmbiental = ATIVIDADES_EVU.includes(
						get(a, 'atividadeAnexo52.item', '').trim()
					);

					const isRestricaoEvu = TABELA_RESTRICAO_IMPLANTACAO.filter(
						item => /estudo/i.test(item.descricao) || item.forceRestricaoEVU
					)
						.map(item => item.codigo)
						.includes(get(a, 'implantacaoAnexo53.codigo', '').trim());

					if (isIndustriasInterferenciaAmbiental || isRestricaoEvu) {
						if (isNil(a?.estudoViabilidadeUrbanisticaAprovado)) {
							erros.push(
								`A atividade possui Estudo de Viabilidade Urbanística aprovado e válido? deve ser informada na ${nome}, ${atv}`
							);
						}
						if (a?.estudoViabilidadeUrbanisticaAprovado === ' nao') {
							// erros.push(
							// 	`Devido a necessidade de Estudo de Viabilidade Urbanística prévio, esta atividade ${atv} não se enquadra na modalidade de Aprovação de Projetos`
							// );
							erros.push(
								`Devido a atividade ${
									size(atv) > 0 ? `(${atv})` : ''
								} NÃO TER ESTUDO DE VIABILIDADE URBANÍSTICO (EVU) APROVADO E VÁLIDO, deverá solicitar Estudo Viabilidade Urbanística (EVU) no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).`
							);
						}
					}
				});
			}
		}); // fim each subunidade

		// Índices construtivos
		if ((isNaoResidenciais || isMultifamiliar || isEnqHabColetiva) && possuiIndicesConstrutivos) {
			const errorsAux = validaPlanilhaIndicesConstrutivos(planilhaIndicesConstrutivos, dadosSubUnidades)?.[0];
			Object.keys(errorsAux).forEach(campo => {
				(errorsAux[campo] || []).forEach(message => erros.push({ campo, message }));
			});
		}

		// Valida areas de aplicacao do regime urbanistico e alturas divisa e base
		let somaAreasAtingidas = 0;
		dadosSubUnidades.forEach(sub => {
			somaAreasAtingidas += parseFloat(sub.areaTerrenoAtingida?.value || 0);

			const desabilitarConferenciaAltura =
				get(sub, 'contemplaAcomodTelhadoSotaoMezanino') === 'sim' || get(sub, 'contemplaRooftops') === 'sim';

			if (
				parseFloat(get(sub, 'alturaBaseRegimeUrbanisticoAnexo.value')) >
					parseFloat(get(sub, 'baseRegimeUrbanisticoAnexo.codigo')) &&
				get(data, 'infosProjeto.projetoPossuiEvuValido') !== 'sim'
			) {
				erros.push({
					idSub: sub?.id,
					campo: 'alturaBaseRegimeUrbanisticoAnexo',
					message: `A altura da base deve ser menor ou igual ao limite do Plano Diretor (${sub?.baseRegimeUrbanisticoAnexo?.descricao} m), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.`
				});
			}
			if (
				parseFloat(get(sub, 'alturaDivisaRegimeUrbanisticoAnexo.value')) >
					parseFloat(get(sub, 'divisaRegimeUrbanisticoAnexo.codigo')) &&
				get(data, 'infosProjeto.projetoPossuiEvuValido') !== 'sim'
			) {
				erros.push({
					idSub: sub?.id,
					campo: 'alturaDivisaRegimeUrbanisticoAnexo',
					message: `A altura da divisa deve ser menor ou igual ao limite do Plano Diretor (${sub?.divisaRegimeUrbanisticoAnexo?.descricao} m), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.`
				});
			}

			if (!desabilitarConferenciaAltura && get(data, 'infosProjeto.projetoPossuiEvuValido') !== 'sim') {
				let limiteRU = get(sub, 'alturaRegimeUrbanisticoAnexo.codigo');
				if (certificadoSustentavel) {
					limiteRU = multiplica(soma(certificadoSustentavel.valor, '1'), limiteRU.codigo);
				}
				if (maiorQue(get(sub, 'alturaProjeto.value'), limiteRU)) {
					erros.push({
						idSub: sub?.id,
						campo: 'alturaProjeto',
						message: `A altura do projeto deve ser menor ou igual ao limite do Plano Diretor (${trocaPontoPorVirgula(
							limiteRU
						)} m), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.`
					});
				}
			}
		});

		if (
			![HABITACAO_COLETIVA, MULTIFAMILIAR, MULTIFAMILIAR_NAO_RESIDENCIAL].includes(tipoForm) ||
			parseFloat(data?.areaAplicacaoRU?.value) <= 3000 ||
			data?.areaMatricula3000 !== 'sim'
		) {
			if (somaAreasAtingidas !== parseFloat(data?.areaAplicacaoRU?.value)) {
				erros.push({
					campo: 'areaTerrenoAtingida',
					message: `A soma das áreas de todas as subunidades deve ser igual a Área para aplicação do Regime Urbanístico (m²)${
						isDebug ? ` [${parseFloat(data?.areaAplicacaoRU?.value)} <> ${somaAreasAtingidas}]` : ''
					}`
				});
			}
		}
	}

	return { erros, isBloqueante: erros.length > 0 };
};

/**
 * Itera o objeto METAFORMAP recursivamente, verificando campos required que não estão ocultos
 *
 * @param {object} meta Objeto aninhado de METAFORMAP
 * @param {string} tipoForm Tipo do form
 * @param {object} data localdata
 */
const iteraMETAFORMAP = (meta, tipoForm, data) => {
	Object.keys(meta).forEach(key => {
		const isObjetoIteravel = typeof meta[key] === 'object' && !isNil(meta[key].label);
		if (isObjetoIteravel) {
			const tiposForm = Array.isArray(meta[key].tipoform) ? meta[key].tipoform : [meta[key].tipoform];
			const formPossuiCampo = includesTipoForm(tiposForm, tipoForm);
			const isRequired = meta[key]?.required === true;

			if (formPossuiCampo && isRequired) {
				const possuiSubCampos = !isNil(meta[key]?.subcampos) && size(meta[key]?.subcampos) > 0;
				const possuiSubCamposOcultos = possuiSubCampos && (data[key] === 'nao' || data[key] === undefined);

				const isNaoInformado = isNil(data[key]) || isEmpty(data[key]);
				const isVisivel = !camposOcultos.includes(meta[key].name);

				if (possuiSubCamposOcultos) {
					for (let index in meta[key].subcampos) {
						camposOcultos.push(meta[key].subcampos[index]);
					}
				}

				if (isNaoInformado && isVisivel) {
					vetorObrigatorios.push({ campo: meta[key].name, message: meta[key].label });
				}
			}
			iteraMETAFORMAP(meta[key], tipoForm, data);
		}
	});
};

const adicionaErros = (errosAtuais, ...errosNovos) => {
	errosNovos.forEach(es => errosAtuais.push(...es));
	return errosAtuais;
};
