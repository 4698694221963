import React, { useCallback, useState, useMemo, useEffect, Fragment, useRef } from 'react';

import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { cloneDeep, get, isEqual, set, size } from 'lodash';

import CheckboxComponent from 'components/CheckboxComponent';
import ShowDebug from 'components/ShowDebug';

import { LE_Enquadramentos } from 'containers/LicenciamentoExpresso/MetadataLE';

import { isDebug, isLocalhost } from 'utils/tools';

import { includesTipoForm } from '../InfosProjetoAP';
import { TIPOS_AREAS_LABELS_PLANILHA_ENUM, TIPOS_METRAGENS_ENUM, UAP_ENQUADRAMENTOS_ENUM } from './constantsAP';
import { LinhaTabelaAreasAP } from './LinhaTabelaAreasAP';
import useTableAreas from './useTabelaAreas';

const { UNIFAMILIAR_01_ECONO, UNIFAMILIAR_02_ECONO, HABITACAO_COLETIVA, UNIFAMILIAR_AUTONOMA } =
	UAP_ENQUADRAMENTOS_ENUM;
const {
	UNIFAMILIAR_AUTONOMA: UNIFAMILIAR_AUTONOMA_LE,
	UNIFAMILIAR_01_ECONO: UNIFAMILIAR_01_ECONO_LE,
	UNIFAMILIAR_02_ECONO: UNIFAMILIAR_02_ECONO_LE,
	UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES: UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES_LE,
	NAO_RESIDENCIAL: NAO_RESIDENCIAL_LE
} = LE_Enquadramentos;

let i = 0;

const debugLog = (...args) => isDebug && console.debug('(DOC-ESP-UAP-PLANILHA)', ++i, ...args);

export function TabelaAreasAP({
	data,
	dataAnterior,
	onChangeHandler,
	tipoForm,
	disabled = false,
	usuarioInterno,
	readOnlyVersao = null
}) {
	const [isFocoMode, setIsFocoMode] = useState(false);
	const [isFocoModeHistory, setIsFocoModeHistory] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);
	const [detalhesAreas, setDetalhesAreas] = useState(get(data, 'detalhesAreas', {}));
	const detalhesAreasAnterior = get(dataAnterior, 'detalhesAreas', {});
	const [natEspEditar, setNatEspEditar] = useState(null);
	const [descricaoNatEsp, setDescricaoNatEsp] = useState('');
	const [pavimentoEditar, setPavimentoEditar] = useState(null);
	const [descricaoPavimento, setDescricaoPavimento] = useState('');
	const [economiaEditar, setEconomiaEditar] = useState(null);
	const [descricaoEconomia, setDescricaoEconomia] = useState('');

	useEffect(() => {
		// este use effect é necessário por que o useState
		// usa o parâmetro data apenas para a primeira inicialização.
		// Ou seja, se o valor de data mudar, o detalhesAreas não atualiza.
		// Isso causa uma série de bug neste componente, pois ele precisa
		// conhecer o estado atual para funcionar, não entrar em looping,
		// apresentar dados corretos, etc.
		setDetalhesAreas(data.detalhesAreas);
	}, [data.detalhesAreas]);

	useMemo(() => {
		if (readOnlyVersao && readOnlyVersao > 0) {
			setHasChanges(!isEqual(detalhesAreasAnterior, detalhesAreas));
		}
	}, [detalhesAreas, detalhesAreasAnterior, readOnlyVersao]);

	const entrarModoFoco = (isDataAnterior = false) => {
		if (isDataAnterior && !isFocoModeHistory) {
			setIsFocoModeHistory(true);
			setDetalhesAreas(detalhesAreasAnterior);
		}
		setIsFocoMode(true);
	};
	const sairModoFoco = (isDataAnterior = false) => {
		if (isDataAnterior && isFocoModeHistory) {
			setIsFocoModeHistory(false);
			setDetalhesAreas(get(data, 'detalhesAreas', {}));
		}
		setIsFocoMode(false);
	};

	const localChangeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				/* eslint-disable-next-line no-unused-vars */
				const [valid, invalid] = changes;
				changes = valid;
			}
			const { name: controlName, value } = changes;

			if (controlName === 'errors') return;

			// set novo valor
			let newDetalhes = cloneDeep(detalhesAreas);

			// set o valor da coluna
			set(newDetalhes, controlName, value);

			// adiciona totais aos detalhesAreas (planilha)
			newDetalhes = calcularTotaisTabelaAreasAP(newDetalhes);

			// change handler
			onChangeHandler && onChangeHandler({ name: 'detalhesAreas', value: newDetalhes });
		},
		[detalhesAreas, onChangeHandler]
	);

	const colunaEconomiaRef = useRef();
	const colunaPavimentoRef = useRef();
	const colunaNatEspRef = useRef();

	const { areasPlanilha = [], economias = [] } = detalhesAreas;
	const sizeEconomias = size(economias);

	const isUnifamiliarUmaEconomia = includesTipoForm([UNIFAMILIAR_01_ECONO, UNIFAMILIAR_01_ECONO_LE], tipoForm);
	const isUnifamiliarDuasEconomias = includesTipoForm([UNIFAMILIAR_02_ECONO, UNIFAMILIAR_02_ECONO_LE], tipoForm);
	const isUnifamiliar = isUnifamiliarUmaEconomia || isUnifamiliarDuasEconomias;
	const isUnifamiliarAutonoma = includesTipoForm([UNIFAMILIAR_AUTONOMA, UNIFAMILIAR_AUTONOMA_LE], tipoForm);
	const isHabitacaoColetiva = includesTipoForm([HABITACAO_COLETIVA], tipoForm);

	const {
		config,
		setConfigDebug,
		getHeaderColspan,
		getSubtotalColspan,
		getHeaderRowspan,
		getHeaderSubtotalRowspan,
		getFillerRowspan,
		getFooterRowspan
	} = useTableAreas(tipoForm);

	const isResidencial_e_naoResidencial = config.showRes && config.showNaoRes;

	const addNatEsp = useCallback(
		({ economiaIndex, pavimentoIndex }) => {
			const economiasAux = cloneDeep(economias);
			economiasAux[economiaIndex].pavimentos[pavimentoIndex].naturezasEspecies.push({});
			localChangeHandler([{ name: 'economias', value: economiasAux }]);
		},
		[economias, localChangeHandler]
	);

	const deleteNatEsp = useCallback(
		({ economiaIndex, pavimentoIndex, natEspIndex }) => {
			const economiasAux = cloneDeep(economias);
			economiasAux[economiaIndex].pavimentos[pavimentoIndex].naturezasEspecies.splice(natEspIndex, 1);
			localChangeHandler([{ name: 'economias', value: economiasAux }]);
		},
		[economias, localChangeHandler]
	);

	const updateNatEsp = useCallback(
		({ economiaIndex, pavimentoIndex, natEspIndex }) => {
			const economiasAux = cloneDeep(economias);
			const natEsp = economiasAux[economiaIndex].pavimentos[pavimentoIndex].naturezasEspecies[natEspIndex];
			natEsp.descricao = descricaoNatEsp;
			localChangeHandler([{ name: 'economias', value: economiasAux }]);
			setDescricaoNatEsp('');
			setNatEspEditar(null);
		},
		[descricaoNatEsp, economias, localChangeHandler]
	);

	const addPavimento = useCallback(
		({ economiaIndex }) => {
			const economiasAux = cloneDeep(economias);
			economiasAux[economiaIndex].pavimentos.push({ naturezasEspecies: [{}] });
			localChangeHandler([{ name: 'economias', value: economiasAux }]);
		},
		[economias, localChangeHandler]
	);

	const deletePavimento = useCallback(
		({ economiaIndex, pavimentoIndex }) => {
			const economiasAux = cloneDeep(economias);
			economiasAux[economiaIndex].pavimentos.splice(pavimentoIndex, 1);
			localChangeHandler([{ name: 'economias', value: economiasAux }]);
		},
		[economias, localChangeHandler]
	);

	const updatePavimento = useCallback(
		({ economiaIndex, pavimentoIndex }) => {
			const economiasAux = cloneDeep(economias);
			const pavimento = economiasAux[economiaIndex].pavimentos[pavimentoIndex];
			pavimento.descricao = descricaoPavimento;
			localChangeHandler([{ name: 'economias', value: economiasAux }]);
			setDescricaoPavimento('');
			setPavimentoEditar(null);
		},
		[descricaoPavimento, economias, localChangeHandler]
	);

	const addEconomia = useCallback(
		descricao => {
			localChangeHandler([
				{ name: 'economias', value: [...(economias || []), { descricao, pavimentos: [{ naturezasEspecies: [{}] }] }] }
			]);
		},
		[economias, localChangeHandler]
	);

	const deleteEconomia = useCallback(
		({ economiaIndex }) => {
			economias.splice(economiaIndex, 1);
			localChangeHandler([{ name: 'economias', value: [...economias] }]);
		},
		[economias, localChangeHandler]
	);

	const updateEconomia = useCallback(
		({ economiaIndex }) => {
			const economiasAux = cloneDeep(economias);
			const economia = economiasAux[economiaIndex];
			economia.descricao = descricaoEconomia;
			localChangeHandler([{ name: 'economias', value: economiasAux }]);
			setDescricaoEconomia('');
			setEconomiaEditar(null);
		},
		[descricaoEconomia, economias, localChangeHandler]
	);
	useEffect(() => {
		if (size(economias) === 0) {
			if (isUnifamiliarUmaEconomia || isHabitacaoColetiva || isUnifamiliarAutonoma) {
				localChangeHandler([
					{
						name: 'economias',
						value: [...(economias || []), { descricao: 'Economia 01', pavimentos: [{ naturezasEspecies: [{}] }] }]
					}
				]);
			} else if (isUnifamiliarDuasEconomias) {
				localChangeHandler([
					{
						name: 'economias',
						value: [
							...(economias || []),
							{ descricao: 'Economia 01', pavimentos: [{ naturezasEspecies: [{}] }] },
							{ descricao: 'Economia 02', pavimentos: [{ naturezasEspecies: [{}] }] }
						]
					}
				]);
			} else {
				addEconomia('');
			}
		}
		debugLog('economias', economias);
	}, [
		addEconomia,
		economias,
		isUnifamiliarAutonoma,
		isHabitacaoColetiva,
		isUnifamiliarDuasEconomias,
		isUnifamiliarUmaEconomia,
		localChangeHandler
	]);

	const showCabecalhoResidencialNaoResidencial = useMemo(
		() => config.showRes && config.showNaoRes,
		[config.showNaoRes, config.showRes]
	);

	const qtdEconomias = size(economias);
	const qtdCols = [config.showColEco, config.showColPav, config.showColNatEsp].reduce((acc, b) => acc + b, 0);

	// 3 linhas se tiver precisar cabeçalho para tipos de economia (residencia e não residencial)
	const qtdLinhasCabecalho = useMemo(
		() => (showCabecalhoResidencialNaoResidencial ? 3 : 2),
		[showCabecalhoResidencialNaoResidencial]
	);

	const colSpanSubtotaisHorizontal = useMemo(() => {
		let largura = 1;
		if (config.showColEco) {
			largura++;
		}
		if (config.showColPav) {
			largura++;
		}
		return largura === 0 ? 1 : largura;
	}, [config.showColEco, config.showColPav]);

	const rowSpanSubtotaisRodape = useMemo(() => {
		let colSpan = qtdLinhasCabecalho;
		if (size(detalhesAreas.areasPlanilha) === 1) {
			colSpan--;
		}
		return colSpan;
	}, [detalhesAreas, qtdLinhasCabecalho]);

	const tiposEconomia = useMemo(() => {
		const saida = [];
		if (config.showRes || !config.showNaoRes) {
			saida.push('residencial');
		}
		if (config.showNaoRes) {
			saida.push('naoResidencial');
		}
		return saida;
	}, [config.showNaoRes, config.showRes]);

	const temPavimentoComMaisDeUmNatEsp = useMemo(
		() =>
			detalhesAreas?.economias?.reduce(
				(acc, { pavimentos }) =>
					acc || pavimentos.reduce((acc2, { naturezasEspecies }) => acc2 || size(naturezasEspecies) > 1, false),
				false
			),
		[detalhesAreas]
	);
	const temEconomiaComMaisDeUmPavimento = useMemo(
		() => detalhesAreas?.economias?.reduce((acc, { pavimentos }) => acc || size(pavimentos) > 1, false),
		[detalhesAreas]
	);

	const labelColunaEconomia = useMemo(() => {
		const labelUap = isUnifamiliar || isHabitacaoColetiva ? 'Economia' : 'Bloco';
		const labelUle = 'Economia';

		const isUle = [
			UNIFAMILIAR_AUTONOMA_LE,
			UNIFAMILIAR_01_ECONO_LE,
			UNIFAMILIAR_02_ECONO_LE,
			UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES_LE,
			NAO_RESIDENCIAL_LE
		].includes(tipoForm);

		return isUle ? labelUle : labelUap;
	}, [isHabitacaoColetiva, isUnifamiliar, tipoForm]);

	return (
		<>
			{isLocalhost && (
				<div style={{ border: '7px solid yellow', padding: '10px', margin: '10px 0px', display: 'flex', gap: '1rem' }}>
					<CheckboxComponent
						label="showColEco"
						name="showColEco"
						value={config.showColEco}
						onChangeHandler={(valor, name) => {
							setConfigDebug({ ...config, [name]: valor });
						}}
					/>
					<CheckboxComponent
						label="showColPav"
						name="showColPav"
						value={config.showColPav}
						onChangeHandler={(valor, name) => {
							setConfigDebug({ ...config, [name]: valor });
						}}
					/>
					<CheckboxComponent
						label="showColNatEsp"
						name="showColNatEsp"
						value={config.showColNatEsp}
						onChangeHandler={(valor, name) => {
							setConfigDebug({ ...config, [name]: valor });
						}}
					/>
					<CheckboxComponent
						label="showAddEco"
						name="showAddEco"
						value={config.showAddEco}
						onChangeHandler={(valor, name) => {
							setConfigDebug({ ...config, [name]: valor });
						}}
					/>
					<CheckboxComponent
						label="showAddPav"
						name="showAddPav"
						value={config.showAddPav}
						onChangeHandler={(valor, name) => {
							setConfigDebug({ ...config, [name]: valor });
						}}
					/>
					<CheckboxComponent
						label="showAddNatEsp"
						name="showAddNatEsp"
						value={config.showAddNatEsp}
						onChangeHandler={(valor, name) => {
							setConfigDebug({ ...config, [name]: valor });
						}}
					/>
					<CheckboxComponent
						label="showRes"
						name="showRes"
						value={config.showRes}
						onChangeHandler={(valor, name) => {
							setConfigDebug({ ...config, [name]: valor });
						}}
					/>
					<CheckboxComponent
						label="showNaoRes"
						name="showNaoRes"
						value={config.showNaoRes}
						onChangeHandler={(valor, name) => {
							setConfigDebug({ ...config, [name]: valor });
						}}
					/>
				</div>
			)}
			<div className={`tabela-areas-wrapper ${isFocoMode ? ' modo-foco' : ''}`}>
				{isDebug && !disabled && (
					<div style={{ border: '7px solid yellow', padding: '10px', margin: '10px 0px' }}>
						<h3>
							Enquadramento: {tipoForm}
							<small className="debug-message"> (somente em debug)</small>
						</h3>
						<ShowDebug data={config} label="Configuração da planilha:" />
					</div>
				)}
				{isFocoMode ? (
					<button
						type="button"
						className={`btn btn-sm btn-tabela-areas btn-modo-foco ${
							disabled ? 'btn-outline-primary2' : 'btn-outline-primary'
						}${isFocoModeHistory ? ' btn-modo-foco-historico btn-modo-foco-inside' : ''}`}
						onClick={() => sairModoFoco(isFocoModeHistory)}
					>
						Sair{' '}
						{isFocoModeHistory ? (
							<>
								da visualização do histórico &nbsp;
								<i className="fa fa-history" />
							</>
						) : (
							<>
								do modo foco &nbsp;
								<FontAwesomeIcon icon={faExpandArrowsAlt} />
							</>
						)}
					</button>
				) : (
					<>
						<button
							type="button"
							className={`btn btn-sm btn-tabela-areas btn-modo-foco ${
								disabled ? 'btn-outline-primary2' : 'btn-outline-primary'
							}`}
							onClick={() => entrarModoFoco(false)}
						>
							Expandir tabela &nbsp;
							<FontAwesomeIcon icon={faExpandArrowsAlt} />
						</button>
						{usuarioInterno && hasChanges && (
							<button
								type="button"
								className={`btn btn-sm btn-tabela-areas btn-modo-foco btn-modo-foco-historico  ${
									disabled ? 'btn-outline-primary2' : 'btn-outline-primary'
								}`}
								onClick={() => entrarModoFoco(true)}
							>
								Visualizar histórico da tabela &nbsp;
								<Tippy placement="top" content={'O requerente efetuou alterações na tabela!'}>
									<i className="fa fa-exclamation-triangle" />
								</Tippy>
							</button>
						)}
					</>
				)}
				<div className="table-sticky-container">
					<table
						className={`table table-sm table-ap${isFocoModeHistory ? ' table-ap-history-view' : ''}`}
						id="table-areas"
					>
						<tbody>
							{/* CABEÇALHO */}
							<tr>
								<th
									colSpan={getHeaderColspan(detalhesAreas)}
									className={`${
										config.showColEco || config.showColPav || config.showColNatEsp
											? 'celula-descricao-principal'
											: 'celula-filler'
									} ${
										config.showColEco || config.showColPav || config.showColNatEsp ? ' scope-index-upper' : ''
									} scope-border-right scope-border-top scope-border-left scope-border-bottom`}
									rowSpan={
										config.showColEco || config.showColPav || config.showColNatEsp
											? getHeaderRowspan(detalhesAreas)
											: getFillerRowspan(detalhesAreas)
									}
								>
									{config.showColEco ? (
										<>
											{config.showAddEco ? (
												<button
													type="button"
													className="btn btn-outline-primary btn-sm btn-tabela-areas"
													onClick={() => addEconomia()}
												>
													<i className="fa fa-plus"></i> {labelColunaEconomia.toUpperCase()}
												</button>
											) : (
												<span>{`${labelColunaEconomia}s`}</span>
											)}
										</>
									) : config.showColPav ? (
										disabled ? null : config.showAddPav ? (
											<button
												type="button"
												className="btn btn-outline-primary btn-sm btn-tabela-areas"
												onClick={() => addPavimento({ economiaIndex: 0 })}
											>
												<i className="fa fa-plus"></i> PAVIMENTO
											</button>
										) : (
											<span>Pavimentos</span>
										)
									) : config.showColNatEsp ? (
										disabled ? null : config.showAddNatEsp ? (
											<button
												type="button"
												className="btn btn-outline-primary btn-sm btn-tabela-areas"
												onClick={() => addNatEsp({ economiaIndex: 0, pavimentoIndex: 0 })}
											>
												<i className="fa fa-plus"></i> Natureza / Espécie
											</button>
										) : (
											<span>Naturezas/Espécies</span>
										)
									) : null}
								</th>

								{areasPlanilha?.map((area, index) => (
									<Fragment key={index}>
										<th
											className="scope-border-right scope-border-top"
											style={{ height: '4rem' }}
											colSpan={tiposEconomia.length * size(TIPOS_METRAGENS_ENUM)}
										>
											{TIPOS_AREAS_LABELS_PLANILHA_ENUM[area]}
										</th>
									</Fragment>
								))}
								<th
									className="subtotais box-shadow-down scope-index-upper"
									colSpan={getSubtotalColspan(detalhesAreas)}
									rowSpan={getHeaderSubtotalRowspan(detalhesAreas)}
								>
									Sub-Totais (m²)
								</th>
							</tr>
							{showCabecalhoResidencialNaoResidencial && (
								<tr>
									{areasPlanilha?.map((_, index) => (
										<Fragment key={index}>
											<>
												<th className="scope-line2" colSpan={size(TIPOS_METRAGENS_ENUM)}>
													Residencial
												</th>
												<th className="scope-line2 scope-border-right" colSpan={size(TIPOS_METRAGENS_ENUM)}>
													Não Residencial
												</th>
											</>
										</Fragment>
									))}
								</tr>
							)}
							<tr>
								{qtdCols > 1 && (
									<>
										{config.showColEco && (
											<th
												className={`celula-coluna-economia ${
													showCabecalhoResidencialNaoResidencial ? 'scope-line3' : 'scope-line2'
												} scope-index-upper${
													!config.showColPav && !config.showColEco ? ' scope-border-right' : ''
												} scope-border-bottom scope-border-left`}
											>
												{labelColunaEconomia}
											</th>
										)}
										{config.showColPav && (
											<th
												className={`celula-coluna-pavimento${config.showColEco ? ' scope-header-column2' : ''} ${
													showCabecalhoResidencialNaoResidencial ? 'scope-line3' : 'scope-line2'
												} scope-index-upper${!config.showColNatEsp ? ' scope-border-right' : ''} scope-border-bottom${
													!config.showColEco ? ' scope-border-left' : ''
												}`}
											>
												Pavimento
											</th>
										)}
										{config.showColNatEsp && (
											<th
												className={`celula-coluna-nat-esp${
													config.showColEco && config.showColPav
														? ' scope-header-column3'
														: config.showColEco || config.showColPav
														? ' scope-header-column2'
														: ''
												} ${
													showCabecalhoResidencialNaoResidencial ? 'scope-line3' : 'scope-line2'
												} scope-index-upper scope-border-right scope-border-bottom${
													!config.showEco && !config.showColPav ? ' scope-border-left' : ''
												}`}
											>
												Natureza/Espécie
											</th>
										)}
									</>
								)}
								{areasPlanilha?.map((_, index) => (
									<Fragment key={index}>
										{Object.keys(TIPOS_METRAGENS_ENUM).map((key, metragemIndex) => (
											<th
												className={`${
													!showCabecalhoResidencialNaoResidencial ? 'scope-line2' : 'scope-line3'
												} box-shadow-down ${
													!showCabecalhoResidencialNaoResidencial && size(TIPOS_METRAGENS_ENUM) - 1 === metragemIndex
														? 'scope-border-right'
														: ''
												}`}
												key={`areasPlanilha_${metragemIndex}`}
											>
												{TIPOS_METRAGENS_ENUM[key]}
											</th>
										))}
										{showCabecalhoResidencialNaoResidencial &&
											Object.keys(TIPOS_METRAGENS_ENUM).map((key, metragemIndex) => (
												<th
													className={`scope-line3 box-shadow-down ${
														size(TIPOS_METRAGENS_ENUM) - 1 === metragemIndex ? 'scope-border-right' : ''
													}`}
													key={`tiposEconomias_${metragemIndex}`}
												>
													{TIPOS_METRAGENS_ENUM[key]}
												</th>
											))}
									</Fragment>
								))}
							</tr>

							{/* CORPO */}
							{detalhesAreas?.economias?.map((economia = {}, economiaIndex) => {
								const { pavimentos = [], descricao: descEconomia } = economia;
								const qtdPavimentos = size(pavimentos);
								const economiaPrefix = `economias.${economiaIndex}`;

								return pavimentos.map((pavimento, pavimentoIndex) => {
									const { naturezasEspecies = [], descricao: descPavimento } = pavimento;
									const isFirstPavimento = pavimentoIndex === 0;
									const qtdNatEsps = size(naturezasEspecies);
									const pavimentoPrefix = `${economiaPrefix}.pavimentos.${pavimentoIndex}`;

									return naturezasEspecies.map((natEsp, natEspIndex) => {
										const { descricao: descNatEsp } = natEsp;
										const isFirstNatEsp = natEspIndex === 0;
										const natEspPrefix = `${economiaPrefix}.pavimentos.${pavimentoIndex}.naturezasEspecies.${natEspIndex}`;
										return (
											<Fragment key={`${economiaIndex}_${pavimentoIndex}_${natEspPrefix}`}>
												<tr>
													{isFirstPavimento && isFirstNatEsp && config.showColEco && (
														<td
															ref={colunaEconomiaRef}
															className={`cell-input celula-coluna-economia scope-opts-bloco scope-index-upper scope-border-left  ${
																disabled ? 'cell-disabled' : ''
															}${
																// bloco última fileira
																sizeEconomias - 1 === economiaIndex &&
																qtdPavimentos - 1 === pavimentoIndex &&
																qtdNatEsps - 1 === natEspIndex
																	? ' scope-border-bottom'
																	: ''
															}${
																// bloco unica coluna
																!config.showColPav && !config.showColNatEsp
																	? ' box-shadow-right scope-border-right'
																	: ''
															}`}
															rowSpan={pavimentos.reduce(
																(acc, { naturezasEspecies }) => acc + size(naturezasEspecies),
																0
															)}
														>
															<div className={'cell-input-wrapper flex flex-column'}>
																<span style={{ whiteSpace: 'pre-wrap', textAlign: 'start' }}>{descEconomia}</span>
																{!disabled && (
																	<div
																		style={{
																			width: '100%',
																			display: 'flex',
																			justifyContent: 'flex-end',
																			alignItems: 'baseline'
																		}}
																	>
																		{config.showAddPav && (
																			<button
																				type="button"
																				title="adicionar pavimento"
																				className="btn btn-outline-primary btn-sm btn-tabela-areas"
																				onClick={() => addPavimento({ economiaIndex })}
																			>
																				<i className="fa fa-plus"></i> Pavimento
																			</button>
																		)}
																		{config.showEdtEco && (
																			<button
																				type="button"
																				title="editar economia"
																				className="btn btn-edit-tabela-areas btn-sm"
																				onClick={() => {
																					setDescricaoEconomia(descEconomia || '');
																					return setEconomiaEditar({ economiaIndex });
																				}}
																			>
																				<i className="fa fa-pencil"></i>
																			</button>
																		)}
																		{config.showEdtEco && qtdEconomias > 1 && (
																			<button
																				type="button"
																				title="remover bloco"
																				className="btn btn-trash-tabela-areas btn-sm"
																				onClick={() => deleteEconomia({ economiaIndex })}
																			>
																				<i className="fa fa-trash"></i>
																			</button>
																		)}
																	</div>
																)}
															</div>
														</td>
													)}
													{isFirstNatEsp && config.showColPav && (
														<td
															ref={colunaPavimentoRef}
															className={`cell-input celula-coluna-pavimento scope-opts-bloco${
																config.showColEco ? ' scope-column2' : ''
															} scope-index-upper}${disabled ? ' cell-disabled' : ''}${
																sizeEconomias - 1 === economiaIndex &&
																qtdPavimentos - 1 === pavimentoIndex &&
																qtdNatEsps - 1 === natEspIndex
																	? ' scope-border-bottom'
																	: ''
															}`}
															rowSpan={qtdNatEsps}
														>
															<div className={'cell-input-wrapper flex flex-column'}>
																<span style={{ whiteSpace: 'pre-wrap', textAlign: 'start' }}>{descPavimento}</span>
																{!disabled && (
																	<div
																		style={{
																			width: '100%',
																			display: 'flex',
																			justifyContent: 'flex-end',
																			alignItems: 'baseline'
																		}}
																	>
																		{config.showAddNatEsp && (
																			<button
																				type="button"
																				title="adicionar natureza/espécie"
																				className="btn btn-outline-primary btn-sm btn-tabela-areas"
																				onClick={() => addNatEsp({ economiaIndex, pavimentoIndex })}
																			>
																				<i className="fa fa-plus"></i> Nat/Esp
																			</button>
																		)}
																		<button
																			type="button"
																			title="editar pavimento"
																			className="btn btn-edit-tabela-areas btn-sm"
																			onClick={() => {
																				setDescricaoPavimento(descPavimento || '');
																				return setPavimentoEditar({ economiaIndex, pavimentoIndex });
																			}}
																		>
																			<i className="fa fa-pencil"></i>
																		</button>
																		{qtdPavimentos > 1 && (
																			<button
																				type="button"
																				title="remover pavimento"
																				className="btn btn-trash-tabela-areas btn-sm"
																				onClick={() => deletePavimento({ economiaIndex, pavimentoIndex })}
																			>
																				<i className="fa fa-trash"></i>
																			</button>
																		)}
																	</div>
																)}
															</div>
														</td>
													)}
													{config.showColNatEsp && (
														<td
															ref={colunaNatEspRef}
															className={`cell-input box-shadow-right celula-coluna-nat-esp scope-opts-bloco${
																config.showColEco && config.showColPav
																	? ' scope-column3'
																	: config.showColEco || config.showColPav
																	? ' scope-column2'
																	: ''
															} scope-border-right  ${disabled ? 'cell-disabled' : ''}${
																sizeEconomias - 1 === economiaIndex &&
																qtdPavimentos - 1 === pavimentoIndex &&
																qtdNatEsps - 1 === natEspIndex
																	? ' scope-border-bottom'
																	: ''
															}`}
														>
															<div className={'cell-input-wrapper flex flex-column'}>
																<span style={{ whiteSpace: 'pre-wrap', textAlign: 'start' }}>{descNatEsp}</span>
																{!disabled && (
																	<div
																		style={{
																			width: '100%',
																			display: 'flex',
																			justifyContent: 'flex-end',
																			alignItems: 'baseline'
																		}}
																	>
																		<button
																			type="button"
																			title="editar natureza/espécie"
																			className="btn btn-edit-tabela-areas btn-sm"
																			onClick={() => {
																				setDescricaoNatEsp(descNatEsp || '');
																				return setNatEspEditar({ economiaIndex, pavimentoIndex, natEspIndex });
																			}}
																		>
																			<i className="fa fa-pencil"></i>
																		</button>
																		{qtdNatEsps > 1 && (
																			<button
																				type="button"
																				title="remover natureza/espécie"
																				className="btn btn-trash-tabela-areas btn-sm"
																				onClick={() => deleteNatEsp({ economiaIndex, pavimentoIndex, natEspIndex })}
																			>
																				<i className="fa fa-trash"></i>
																			</button>
																		)}
																	</div>
																)}
															</div>
														</td>
													)}

													<LinhaTabelaAreasAP
														key={`${economiaIndex}_${pavimentoIndex}_${natEspIndex}`}
														data={data}
														naturezaEspecie={natEsp}
														prefix={natEspPrefix}
														onChangeHandler={localChangeHandler}
														areasPlanilha={areasPlanilha}
														sizeAreasPlanilha={size(areasPlanilha)}
														economia={economia}
														economiaPrefix={economiaPrefix}
														tiposEconomias={tiposEconomia}
														sizeTiposEconomias={size(tiposEconomia)}
														tipoForm={tipoForm}
														disabled={disabled}
														economiaIndex={economiaIndex}
														sizeEconomias={sizeEconomias}
														pavimentoIndex={pavimentoIndex}
														natEspIndex={natEspIndex}
														sizePavimentos={qtdPavimentos}
														sizeNatEsp={qtdNatEsps}
													/>

													{/* sub-total de cada natureza/especie horizontal */}
													<td
														className={`subtotais${
															parseFloat(get(data.detalhesAreas, `${natEspPrefix}.total`, 0)) > 0
																? ' subtotais-value'
																: ''
														}`}
													>
														{formata(get(data.detalhesAreas, `${natEspPrefix}.total`, 0))}
													</td>
													{/* sub-total de cada pavimento horizontal */}
													{isFirstNatEsp && config.showColPav && temPavimentoComMaisDeUmNatEsp && (
														<td
															className={`subtotais${
																parseFloat(get(data.detalhesAreas, `${pavimentoPrefix}.total`, 0)) > 0
																	? ' subtotais-value'
																	: ''
															}`}
															rowSpan={qtdNatEsps}
														>
															{formata(get(data.detalhesAreas, `${pavimentoPrefix}.total`, 0))}
														</td>
													)}

													{/* sub-total de cada economia horizontal */}
													{isFirstPavimento &&
														isFirstNatEsp &&
														config.showColEco &&
														temEconomiaComMaisDeUmPavimento && (
															<td
																className={`subtotais${
																	parseFloat(get(data.detalhesAreas, `${economiaPrefix}.total`, 0)) > 0
																		? ' subtotais-value'
																		: ''
																}`}
																rowSpan={economia.pavimentos.reduce((acc, pav) => acc + size(pav.naturezasEspecies), 0)}
															>
																{formata(get(data.detalhesAreas, `${economiaPrefix}.total`, 0))}
															</td>
														)}
												</tr>
											</Fragment>
										);
									});
								});
							})}

							{/* RODAPE */}
							<tr>
								{/* célula "Sub-totais (m²)" */}
								<td
									className="subtotais celula-subtotais scope-index-upper"
									colSpan={getHeaderColspan(detalhesAreas)}
									rowSpan={getFooterRowspan(detalhesAreas)}
								>
									Sub-Totais (m²)
								</td>
								{/* total metragens do tipoEconomia */}
								{areasPlanilha.map(area =>
									tiposEconomia.map(tipoEconomia =>
										Object.keys(TIPOS_METRAGENS_ENUM).map(metragem => (
											<td
												key={`${area}.${tipoEconomia}.${metragem}`}
												className={`subtotais${
													parseFloat(get(detalhesAreas.areasTotais, [area, tipoEconomia, metragem, 'total'], 0)) > 0
														? ' subtotais-value'
														: ''
												}`}
											>
												{formata(
													parseFloat(get(detalhesAreas.areasTotais, [area, tipoEconomia, metragem, 'total'], 0))
												)}
											</td>
										))
									)
								)}
								<td className="total-geral" rowSpan={rowSpanSubtotaisRodape} colSpan={colSpanSubtotaisHorizontal}>
									<b>TOTAL&nbsp;GERAL</b>
									<br />
									<br />
									{formata(parseFloat(get(detalhesAreas.areasTotais, ['total'], 0)))}
								</td>
							</tr>
							{isResidencial_e_naoResidencial && (
								<>
									<tr>
										{areasPlanilha.map((area, i) =>
											tiposEconomia.map(tipoEconomia => (
												<td
													key={`${area}.${i}.${tipoEconomia}`}
													colSpan={size(TIPOS_METRAGENS_ENUM)}
													className={`subtotais${
														parseFloat(get(detalhesAreas.areasTotais, [area, tipoEconomia, 'total'], 0)) > 0
															? ' subtotais-value'
															: ''
													}`}
												>
													{formata(parseFloat(get(detalhesAreas.areasTotais, [area, tipoEconomia, 'total'], 0)))}
												</td>
											))
										)}
									</tr>
								</>
							)}
							{size(detalhesAreas.areasPlanilha) > 1 && (
								<tr>
									{areasPlanilha.map((area, i) => (
										<td
											key={`${area}.${i}`}
											colSpan={size(TIPOS_METRAGENS_ENUM) * size(tiposEconomia)}
											className={`subtotais${
												parseFloat(get(detalhesAreas.areasTotais, [area, 'total'], 0)) > 0 ? ' subtotais-value' : ''
											}`}
										>
											{formata(parseFloat(get(detalhesAreas.areasTotais, [area, 'total'], 0)))}
										</td>
									))}
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
			<Modal
				show={!!economiaEditar}
				onHide={() => setEconomiaEditar(null)}
				dialogClassName="modal-25w"
				aria-labelledby={`Edição de ${labelColunaEconomia}`}
			>
				<Modal.Header closeButton>
					<Modal.Title id="titulo">Editar descrição de {labelColunaEconomia}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Descrição:
					<textarea rows={3} value={descricaoEconomia} onChange={e => setDescricaoEconomia(e.target.value)} />
					<div className="toolbar">
						<button type="button" className="btn btn-default" onClick={() => setEconomiaEditar(null)}>
							Cancelar
						</button>
						<button type="button" className="btn btn-primary" onClick={() => updateEconomia(economiaEditar)}>
							Confirmar
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				show={!!pavimentoEditar}
				onHide={() => setPavimentoEditar(null)}
				dialogClassName="modal-25w"
				aria-labelledby="Edição do Pavimento"
			>
				<Modal.Header closeButton>
					<Modal.Title id="titulo">Editar descrição do pavimento</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Descrição:
					<textarea rows={3} value={descricaoPavimento} onChange={e => setDescricaoPavimento(e.target.value)} />
					<div className="toolbar">
						<button type="button" className="btn btn-default" onClick={() => setPavimentoEditar(null)}>
							Cancelar
						</button>
						<button type="button" className="btn btn-primary" onClick={() => updatePavimento(pavimentoEditar)}>
							Confirmar
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				show={!!natEspEditar}
				onHide={() => setNatEspEditar(null)}
				dialogClassName="modal-25w"
				aria-labelledby="Edição de Natureza/Espécie"
			>
				<Modal.Header closeButton>
					<Modal.Title id="titulo">Editar descrição de Natureza/Espécie</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Descrição:
					<textarea rows={3} value={descricaoNatEsp} onChange={e => setDescricaoNatEsp(e.target.value)} />
					<div className="toolbar">
						<button type="button" className="btn btn-default" onClick={() => setNatEspEditar(null)}>
							Cancelar
						</button>
						<button type="button" className="btn btn-primary" onClick={() => updateNatEsp(natEspEditar)}>
							Confirmar
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
export default TabelaAreasAP;
TabelaAreasAP.displayName = 'TabelaAreasAP';
TabelaAreasAP.propTypes = {
	data: PropTypes.object,
	dataAnterior: PropTypes.object,
	onChangeHandler: PropTypes.func,
	name: PropTypes.string,
	tipoForm: PropTypes.string,
	isProjetoNovo: PropTypes.bool,
	disabled: PropTypes.bool,
	usuarioInterno: PropTypes.bool,
	readOnlyVersao: PropTypes.any
};

/* calcula os totais por linha/coluna	*/
export function calcularTotaisTabelaAreasAP(detalhes, tiposEconomias = ['residencial', 'naoResidencial']) {
	if (!detalhes) {
		return detalhes;
	}
	const { areasPlanilha = [], economias = [] } = detalhes || {};

	let areasTotais = areasPlanilha.reduce((accAreas, area) => {
		const tipos = tiposEconomias.reduce((accTiposEconomias, tipoEconomia) => {
			const totalTipoEconomia = economias.reduce(
				(acc2, economia) =>
					acc2 +
					economia.pavimentos.reduce(
						(acc3, pavimento) =>
							acc3 +
							pavimento.naturezasEspecies.reduce(
								(acc4, natEsp) =>
									acc4 +
									Object.keys(TIPOS_METRAGENS_ENUM).reduce(
										(acc6, metragem) => acc6 + parseFloat(get(natEsp, [area, tipoEconomia, metragem, 'value'], 0)),
										0
									),
								0
							),
						0
					),
				0
			);

			const metragens = Object.keys(TIPOS_METRAGENS_ENUM).reduce((accMetragens, metragem) => {
				const totalMetragem = economias.reduce(
					(acc2, economia) =>
						acc2 +
						economia.pavimentos.reduce(
							(acc3, pavimento) =>
								acc3 +
								pavimento.naturezasEspecies.reduce(
									(acc4, natEsp) => acc4 + parseFloat(get(natEsp, [area, tipoEconomia, metragem, 'value'], 0)),
									0
								),
							0
						),
					0
				);
				return { ...accMetragens, [metragem]: { total: totalMetragem } };
			}, {});

			return { ...accTiposEconomias, [tipoEconomia]: { total: totalTipoEconomia, ...metragens } };
		}, {});

		const totalArea = economias.reduce(
			(acc2, economia) =>
				acc2 +
				economia.pavimentos.reduce(
					(acc3, pavimento) =>
						acc3 +
						pavimento.naturezasEspecies.reduce(
							(acc4, natEsp) =>
								acc4 +
								tiposEconomias.reduce(
									(acc5, tipoEconomia) =>
										acc5 +
										Object.keys(TIPOS_METRAGENS_ENUM).reduce(
											(acc6, metragem) => acc6 + parseFloat(get(natEsp, [area, tipoEconomia, metragem, 'value'], 0)),
											0
										),
									0
								),
							0
						),
					0
				),
			0
		);
		return { ...accAreas, [area]: { total: totalArea, ...tipos } };
	}, {});

	areasTotais.total = areasPlanilha.reduce((acc, area) => acc + areasTotais[area].total, 0);
	detalhes.areasTotais = areasTotais;

	economias.forEach(economia => {
		economia.total = economia.pavimentos.reduce((acc, pavimento) => {
			pavimento.total = pavimento.naturezasEspecies.reduce((acc2, natEsp) => {
				natEsp.total = areasPlanilha.reduce((acc3, area) => {
					const tiposEconomiaAreaTotal = tiposEconomias.reduce((acc4, tipoEconomia) => {
						const metragensTipoEconomiaAreaTotal = Object.keys(TIPOS_METRAGENS_ENUM).reduce((acc5, metragem) => {
							const val = parseFloat(get(natEsp, [area, tipoEconomia, metragem, 'value'], 0));
							return acc5 + val;
						}, 0);
						return acc4 + metragensTipoEconomiaAreaTotal;
					}, 0);
					return acc3 + tiposEconomiaAreaTotal;
				}, 0);
				return acc2 + natEsp.total;
			}, 0);
			return acc + pavimento.total;
		}, 0);
	});
	detalhes.totalPorEconomias = economias.reduce((acc, economia) => acc + economia.total, 0);

	return detalhes;
}

const formatter = new Intl.NumberFormat('pt-BR', {
	style: 'decimal',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
	useGrouping: 'always'
});

function formata(numberValue) {
	return formatter.format(numberValue);
}
