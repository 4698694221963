import React from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';

import { LE_Enquadramentos } from 'containers/LicenciamentoExpresso/MetadataLE';

import { includesTipoForm } from '../InfosProjetoAP';
import { CelulaTabelaAreasAP } from './CelulaTabelaAreasAP';
import { TIPOS_METRAGENS_ENUM, UAP_ENQUADRAMENTOS_ENUM } from './constantsAP';

export function LinhaTabelaAreasAP({
	naturezaEspecie,
	prefix,
	onChangeHandler,
	data,
	readOnly,
	areasPlanilha,
	sizeAreasPlanilha,
	tiposEconomias,
	sizeTiposEconomias,
	tipoForm,
	disabled = false,
	economiaIndex,
	sizeEconomias,
	pavimentoIndex,
	natEspIndex,
	sizePavimentos,
	sizeNatEsp
}) {
	const metragensReadOnly = getMetragensReadOnly(data, tipoForm);
	return areasPlanilha.map((area, areaIndex) =>
		tiposEconomias.map((tipoEconomia, tipoEconomiaIndex) =>
			Object.keys(TIPOS_METRAGENS_ENUM).map((metragem, metragemIndex) => {
				const name = `${prefix}.${area}.${tipoEconomia}.${metragem}`;
				return (
					<CelulaTabelaAreasAP
						key={name}
						placeHolder="0,00"
						value={get(naturezaEspecie, [area, tipoEconomia, metragem])}
						name={name}
						onChangeHandler={onChangeHandler}
						readOnly={readOnly || metragensReadOnly.includes(metragem)}
						disabled={disabled}
						sizeTiposEconomias={sizeTiposEconomias}
						sizeAreasPlanilha={sizeAreasPlanilha}
						areaIndex={areaIndex}
						tipoEconomiaIndex={tipoEconomiaIndex}
						sizeTiposMetragens={size(TIPOS_METRAGENS_ENUM)}
						metragemIndex={metragemIndex}
						keyAreasIndex={`${areaIndex}.${tipoEconomiaIndex}.${metragemIndex}`}
						keyAreasSize={`${sizeAreasPlanilha - 1}.${sizeTiposEconomias - 1}.${size(TIPOS_METRAGENS_ENUM) - 1}`}
						classNameAux={`${
							// bloco primeira fileira
							economiaIndex === 0 && pavimentoIndex === 0 && natEspIndex === 0 ? ' scope-border-top' : ''
						}${
							// bloco última fileira
							sizeEconomias - 1 === economiaIndex &&
							sizePavimentos - 1 === pavimentoIndex &&
							sizeNatEsp - 1 === natEspIndex
								? ' scope-border-bottom'
								: ''
						}${
							// célula última coluna
							sizeAreasPlanilha - 1 === areaIndex &&
							sizeTiposEconomias - 1 === tipoEconomiaIndex &&
							size(TIPOS_METRAGENS_ENUM) - 1 === metragemIndex
								? ' scope-border-right'
								: ''
						}${
							// célula primeira coluna
							tipoEconomiaIndex === 0 && metragemIndex === 0 && areaIndex > 0 ? ' scope-border-left' : ''
						}${
							// célula de bloco ímpar
							economiaIndex % 2 !== 0 ? ' scope-bloco-even' : ''
						}${
							// célula de coluna de área ímpar
							areaIndex % 2 !== 0 ? ' scope-area-even' : ''
						}`}
					/>
				);
			})
		)
	);
}
LinhaTabelaAreasAP.displayName = 'LinhaTabelaAreasAP';
LinhaTabelaAreasAP.propTypes = {
	totalPrefix: PropTypes.string,
	naturezaEspecie: PropTypes.object,
	economia: PropTypes.object,
	prefix: PropTypes.string,
	economiaPrefix: PropTypes.string,
	onChangeHandler: PropTypes.func,
	data: PropTypes.object,
	readOnly: PropTypes.bool,
	areasPlanilha: PropTypes.arrayOf(PropTypes.string),
	sizeAreasPlanilha: PropTypes.number,
	tiposEconomias: PropTypes.arrayOf(PropTypes.string),
	sizeTiposEconomias: PropTypes.number,
	tipoForm: PropTypes.string,
	bloqueiaAdNad: PropTypes.bool,
	disabled: PropTypes.bool,
	economiaIndex: PropTypes.number,
	sizeEconomias: PropTypes.number,
	pavimentoIndex: PropTypes.number,
	natEspIndex: PropTypes.number,
	sizePavimentos: PropTypes.number,
	sizeNatEsp: PropTypes.number
};

function getMetragensReadOnly(localData, tipoForm) {
	const { UNIFAMILIAR_02_ECONO, UNIFAMILIAR_01_ECONO, NAO_RESIDENCIAL } = UAP_ENQUADRAMENTOS_ENUM;
	const {
		UNIFAMILIAR_01_ECONO: UNIFAMILIAR_01_ECONO_LE,
		UNIFAMILIAR_02_ECONO: UNIFAMILIAR_02_ECONO_LE,
		NAO_RESIDENCIAL: NAO_RESIDENCIAL_LE
	} = LE_Enquadramentos;

	const isHabitacaoColetiva = localData?.infosProjeto?.projetoAtividade?.item === '1.2.';

	const isTrintaPorcentoIa = localData?.infosProjeto?.trintaPorcentoIa === 'sim';

	const isUnifamiliarUmaEconomia = includesTipoForm([UNIFAMILIAR_01_ECONO, UNIFAMILIAR_01_ECONO_LE], tipoForm);
	const isUnifamiliarDuasEconomias = includesTipoForm([UNIFAMILIAR_02_ECONO, UNIFAMILIAR_02_ECONO_LE], tipoForm);
	const isNaoResidencial = includesTipoForm([NAO_RESIDENCIAL, NAO_RESIDENCIAL_LE], tipoForm);
	const unifamiliar01EconomiaNaoColetiva = isUnifamiliarUmaEconomia && !isHabitacaoColetiva;

	// metragens que devem ficar readonly na tabela
	const metragensReadonly =
		unifamiliar01EconomiaNaoColetiva || isUnifamiliarDuasEconomias
			? ['mtAdensavel', 'mtNaoAdensavel']
			: isNaoResidencial && isTrintaPorcentoIa
			? ['mtNaoAdensavel']
			: tipoForm === UNIFAMILIAR_01_ECONO_LE && isHabitacaoColetiva && isTrintaPorcentoIa
			? ['mtNaoAdensavel']
			: [];

	return metragensReadonly;
}
