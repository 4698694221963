import React, { Fragment, useMemo, useState } from 'react';
import { useEffect } from 'react';

import PropTypes from 'prop-types';

import { flatMap, get, isNil, omit, pick, size } from 'lodash';

import Comentario from 'components/Comentario';
import ErrorMessages from 'components/ErrorMessages';
import HistoricoRespostaAnterior from 'components/HistoricoRespostaAnterior';
import Loader from 'components/Loader';
import ShowDebug from 'components/ShowDebug';

import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE';

import useErrors from 'custom-hooks/useErrors';

import { accessApi } from 'utils/injectApi';
import { isDebug } from 'utils/tools';

function RestricoesAdministrativasForm({
	idMetadata,
	metadataParaEditor,
	data: dataProps,
	dataAnterior,
	changeHandler,
	readOnly,
	readOnlyVersao = null,
	filter,
	changeComentarioHandler = null,
	usuarioInterno = false,
	origem,
	showTextoCondicionante = false,
	showTextoRevisor = true
}) {
	const [perguntasMetadata, setPerguntasMetadata] = useState([]);
	const [data, setData] = useState({});
	const [errors] = useErrors();
	const [localErrors, setLocalErrors] = useState();
	const [loadingMetadata, setLoadingMetadata] = useState(false);
	const [liberaLoadMetadata, setLiberaLoadMetadata] = useState(false);

	const perguntasFlatKeys = useMemo(() => {
		const ps = size(perguntasMetadata) > 0 ? flatPerguntas(perguntasMetadata) : {};
		return flatMap(Object.keys(ps), k => [k, `${k}AlteradaRevisor`, `${k}AlteradaRequerente`, `${k}Comentario`]);
	}, [perguntasMetadata]);

	useEffect(() => {
		if (size(dataProps) > 0 && size(perguntasFlatKeys) > 0) {
			const cleanData = pick(dataProps, perguntasFlatKeys);
			setData(cleanData || {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataProps, perguntasFlatKeys]);

	useEffect(() => {
		if (size(metadataParaEditor) > 0) {
			setPerguntasMetadata([...metadataParaEditor]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [liberaLoadMetadata]);

	useEffect(() => {
		async function fetchMetadata(idMetadata) {
			try {
				setLoadingMetadata(true);
				const url = `collections/metadata/${idMetadata}`;
				const retorno = (await accessApi(url))?.data;
				const meta = retorno || {};
				setPerguntasMetadata(meta?.perguntas || []);
				setLiberaLoadMetadata(true);
				// console.log('flat', flatPerguntas(meta?.perguntas))
				setLocalErrors(old => omit(old, ['loadingMetadata']));
			} catch (e) {
				setPerguntasMetadata([]);
				setLocalErrors(old => ({
					...old,
					loadingMetadata: [`Erro carregando metadados de id "${idMetadata}"`]
				}));
				console.error(`Erro no acesso aos metadados de id "${idMetadata}"`, e);
			} finally {
				setLoadingMetadata(false);
			}
		}

		if (size(perguntasMetadata) === 0 && !loadingMetadata) {
			if (idMetadata && size(metadataParaEditor) === 0) {
				fetchMetadata(idMetadata);
			} else if (size(metadataParaEditor) === 0) {
				setLocalErrors(old => ({
					...old,
					loadingMetadata: ['Metadata não fornecido (props.metadata) nem identificado para carga (props.idMetadata).']
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idMetadata]);

	const onChangeHandler = ({ name, value }) => {
		let newData = { ...data, [name]: value };
		newData = limpaSubs(newData, name, value);
		setData(newData);
		// setErrors(old => omit(old, [name]));
		changeHandler(newData);
	};

	const limpaSubs = (data, name, value) => {
		const limpaPerguntaRecursivamente = pergunta => {
			if (pergunta.campo !== name) {
				// não pode ser delete por que em alguns formulários em que o RestriçõesAdmistrativas
				// é um componente de outro formulário, o componente pai junta o data do filho com o dele
				// através de um assign, e isso acaba trazendo o valor anterior de volta
				data[pergunta.campo] = undefined;
			}
			pergunta.respostas.forEach(r => {
				if (size(r.perguntas) > 0) {
					if (pergunta.campo !== name || r.codigo !== value) {
						r.perguntas.forEach(p => limpaPerguntaRecursivamente(p));
					}
				}
			});
		};

		let achou = false;

		const encontraPerguntaParaLimparRecursivamente = perguntas => {
			perguntas.forEach(p => {
				if (p.campo === name) {
					limpaPerguntaRecursivamente(p);
					achou = true;
				} else if (!achou) {
					p.respostas
						.filter(r => size(r.perguntas) > 0)
						.forEach(r => encontraPerguntaParaLimparRecursivamente(r.perguntas));
				}
			});
		};

		encontraPerguntaParaLimparRecursivamente(perguntasMetadata);
		return data;
	};

	const sortPerguntaPorOrdem = (p1, p2) => (p1.ordem > p2.ordem ? 1 : -1);

	return (
		<>
			<fieldset>
				<legend>1. Restrições administrativas</legend>
				{origem === 'aprovacao-projetos' && (
					<p>
						Para verificar as restrições administrativas é necessário consultar a{' '}
						<a href="https://dmweb.procempa.com.br/dmweb/searchBox.seam" target="_blank" rel="noopener noreferrer">
							DMWEB <i className="fa fa-external-link"></i>
						</a>
					</p>
				)}
				{loadingMetadata ? <Loader msg="Carregando metadados" /> : null}
				{size(localErrors?.loadingMetadata) > 0 && <ErrorMessages errorList={localErrors.loadingMetadata} />}
				{size(localErrors?.loadingFormData) > 0 && <ErrorMessages errorList={localErrors.loadingFormData} />}
				{data && perguntasMetadata && (
					<div className="restricoes-administrativas-le">
						{(perguntasMetadata || []).sort(sortPerguntaPorOrdem).map(p => (
							<Fragment key={p.id}>
								<Pergunta
									pergunta={p}
									data={data}
									dataAnterior={dataAnterior}
									errors={errors}
									onChangeHandler={onChangeHandler}
									readOnly={readOnly}
									filter={filter}
									onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? onChangeHandler : null}
									usuarioInterno={usuarioInterno}
									readOnlyVersao={readOnlyVersao}
									showTextoCondicionante={showTextoCondicionante}
									showTextoRevisor={showTextoRevisor}
									origem={origem}
								/>
							</Fragment>
						))}
					</div>
				)}
			</fieldset>
			<ShowDebug data={data} />
		</>
	);
}
RestricoesAdministrativasForm.displayName = 'RestricoesAdministrativasForm';
RestricoesAdministrativasForm.propTypes = {
	idMetadata: PropTypes.string,
	metadataParaEditor: PropTypes.arrayOf(PropTypes.object),
	idFormData: PropTypes.string,
	data: PropTypes.object,
	changeHandler: PropTypes.func,
	readOnly: PropTypes.bool,
	readOnlyVersao: PropTypes.any,
	filter: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	changeComentarioHandler: PropTypes.func,
	usuarioInterno: PropTypes.bool,
	dataAnterior: PropTypes.object,
	origem: PropTypes.string,
	showTextoCondicionante: PropTypes.bool,
	showTextoRevisor: PropTypes.bool
};

export default RestricoesAdministrativasForm;

function Pergunta({
	pergunta: p,
	data,
	dataAnterior,
	errors: errorsProps = [],
	onChangeHandler,
	margin: marginProps = -15,
	readOnly,
	readOnlyVersao,
	filter: filterProps,
	onChangeComentarioHandler = null,
	usuarioInterno,
	showTextoCondicionante = false,
	showTextoRevisor = true,
	origem
}) {
	const filter = useMemo(
		() => (filterProps ? (Array.isArray(filterProps) ? filterProps : [filterProps]) : []),
		[filterProps]
	);
	const sortPerguntaPorOrdem = (p1, p2) => (p1.ordem > p2.ordem ? 1 : -1);
	const margin = useMemo(() => marginProps + 15, [marginProps]);
	const options = p.respostas.map(r => ({
		codigo: r.codigo,
		descricao: r.resposta
		// defaultValue: isDebug ? r.codigo === 'nao' : false: causando bugs pois o radio emite diversos eventos de change e confunde o componente pai (formulario uap)
	}));
	const value = data[p.campo];
	const campoComentario = `${p.campo}Comentario`;
	const valueComentario = data?.[campoComentario];
	const alteradaRevisor = !!data[`${p.campo}AlteradaRevisor`];
	const alteradaRequerente = !!data[`${p.campo}AlteradaRequerente`];
	const respostas = (p?.respostas || []).reduce((acc, r) => ({ ...acc, [r.codigo]: r }), {});
	const errors = Array.isArray(errorsProps)
		? errorsProps.filter(e => e.campo === p.campo).map(e => e.message)
		: undefined;
	const [temComentario, setTemComentario] = useState(false);

	const filtrada = pergunta => {
		if (size(filter) === 0 || size(pergunta.filter) === 0) {
			return true;
		}
		return filter.reduce((acc, f) => acc || (pergunta.filter || []).includes(f), false);
	};

	return readOnly ? (
		<>
			{filtrada(p) ? (
				<>
					<div
						className={`row container-resposta-ap ${
							temComentario ? 'resposta-com-comentario pin-comentario' : usuarioInterno ? 'resposta-com-comentario' : ''
						}`}
						style={{ position: 'relative' }}
					>
						<span>{p.pergunta}</span>
						<span className="resposta-ap">{value === 'nao' ? 'não' : value}</span>
						<div className="b-wrapper">
							{dataAnterior && (
								<HistoricoRespostaAnterior
									dataAnterior={dataAnterior?.[p.campo]}
									dataAtual={data[p.campo]}
									usuarioInterno={usuarioInterno}
								/>
							)}
							<Comentario
								campoComentario={campoComentario}
								valueComentario={valueComentario}
								valueComentarioAnterior={dataAnterior?.[campoComentario]}
								onChangeComentarioHandler={onChangeComentarioHandler}
								setTemComentario={setTemComentario}
								temComentario={temComentario}
								readOnly={!usuarioInterno || !!readOnlyVersao}
							/>
						</div>
					</div>
					<div className="textos-condicionantes">
						{!usuarioInterno && get(respostas, `${value}.textoEntrada`) && (
							<InfoMessage
								message={get(respostas, `${value}.textoEntrada`)}
								bloqueante={get(respostas, `${value}.bloqueante`)}
							/>
						)}
						{!usuarioInterno && origem === 'doc0' && get(respostas, `${value}.textoDocumento0`) && (
							<InfoMessage
								message={get(respostas, `${value}.textoDocumento0`)}
								bloqueante={get(respostas, `${value}.bloqueante`)}
							/>
						)}
						{usuarioInterno && showTextoRevisor && get(respostas, `${value}.textoRevisor`) && (
							<InfoMessage
								message={get(respostas, `${value}.textoRevisor`)}
								bloqueante={get(respostas, `${value}.bloqueante`)}
							/>
						)}
						{!usuarioInterno && origem === 'termo' && get(respostas, `${value}.textoSumario`) && (
							<InfoMessage
								message={get(respostas, `${value}.textoSumario`)}
								bloqueante={get(respostas, `${value}.bloqueante`)}
							/>
						)}
						{usuarioInterno && showTextoCondicionante && get(respostas, `${value}.textoCondicionante`) && (
							<InfoMessage
								message={get(respostas, `${value}.textoCondicionante`)}
								bloqueante={get(respostas, `${value}.bloqueante`)}
							/>
						)}
					</div>
					{size(get(respostas, `${value}.perguntas`)) > 0 &&
						get(respostas, `${value}.perguntas`)
							.sort(sortPerguntaPorOrdem)
							.map(pr => (
								<Pergunta
									key={pr.id}
									pergunta={pr}
									data={data}
									dataAnterior={dataAnterior}
									errors={errorsProps}
									onChangeHandler={onChangeHandler}
									readOnly={readOnly}
									readOnlyVersao={readOnlyVersao}
									filter={filter}
									onChangeComentarioHandler={onChangeComentarioHandler}
									usuarioInterno={usuarioInterno}
									showTextoCondicionante={showTextoCondicionante}
									showTextoRevisor={showTextoRevisor}
									origem={origem}
								/>
							))}
				</>
			) : isDebug ? (
				<p>
					removida {p.campo} por que o filtro é [{filter.join(', ')}] e a pergunta requer [{p.filter.join(', ')}]
				</p>
			) : null}
		</>
	) : filtrada(p) ? (
		<div
			className={`pergunta ${
				temComentario ? 'resposta-com-comentario pin-comentario' : usuarioInterno ? 'resposta-com-comentario' : ''
			}`}
			style={{ marginLeft: `${margin}px` }}
		>
			<RadioFieldLE
				name={p.campo}
				label={p.pergunta}
				options={options}
				required={true}
				onChangeHandler={({ name, value }) => (name === 'errors' ? null : onChangeHandler({ name, value }))}
				value={value}
			/>

			<Comentario
				campoComentario={campoComentario}
				valueComentario={valueComentario}
				valueComentarioAnterior={dataAnterior?.[campoComentario]}
				onChangeComentarioHandler={onChangeComentarioHandler}
				setTemComentario={setTemComentario}
				temComentario={temComentario}
				readOnly={!usuarioInterno || !!readOnlyVersao}
			/>

			{alteradaRevisor && <AvisoAlteracaoRevisor />}
			{alteradaRequerente && <AvisoAlteracaoRequerente />}
			{get(respostas, `${value}.textoEntrada`) && (
				<InfoMessage
					message={get(respostas, `${value}.textoEntrada`)}
					bloqueante={get(respostas, `${value}.bloqueante`)}
				/>
			)}
			{size(get(respostas, `${value}.perguntas`)) > 0 &&
				get(respostas, `${value}.perguntas`)
					.sort(sortPerguntaPorOrdem)
					.map(pr => (
						<Pergunta
							key={pr.id}
							pergunta={pr}
							data={data}
							dataAnterior={dataAnterior}
							errors={errorsProps}
							onChangeHandler={onChangeHandler}
							filter={filter}
							onChangeComentarioHandler={onChangeComentarioHandler}
							readOnly={readOnly}
							readOnlyVersao={readOnlyVersao}
							showTextoCondicionante={showTextoCondicionante}
							showTextoRevisor={showTextoRevisor}
							origem={origem}
						/>
					))}
			{isDebug && (
				<div className="textos">
					{get(respostas, `${value}.textoEntrada`) && (
						<div className="observacao-le labeled">
							<span>{get(respostas, `${value}.textoEntrada`)}</span>
							<label>texto formulário de entrada</label>
						</div>
					)}
					{get(respostas, `${value}.textoDocumento0`) && (
						<div className="observacao-le labeled">
							<span>{get(respostas, `${value}.textoDocumento0`)}</span>
							<label>texto documento 0</label>
						</div>
					)}
					{get(respostas, `${value}.textoRevisor`) && (
						<div className="observacao-le labeled">
							<span>{get(respostas, `${value}.textoRevisor`)}</span>
							<label>texto revisor</label>
						</div>
					)}
					{get(respostas, `${value}.textoSumario`) && (
						<div className="observacao-le labeled">
							<span>{get(respostas, `${value}.textoSumario`)}</span>
							<label>texto sumário</label>
						</div>
					)}
					{get(respostas, `${value}.textoCondicionante`) && (
						<div className="observacao-le labeled">
							<span>{get(respostas, `${value}.textoCondicionante`)}</span>
							<label>texto sumário</label>
						</div>
					)}
				</div>
			)}
			{size(errors) > 0 && <ErrorMessages errorList={errors} />}
		</div>
	) : isDebug ? (
		<p>
			removida {p.campo} por que o filtro é [{filter.join(', ')}] e a pergunta requer [{p.filter.join(', ')}]
		</p>
	) : null;
}
Pergunta.displayName = 'Pergunta';
Pergunta.propTypes = {
	pergunta: PropTypes.object,
	data: PropTypes.object,
	dataAnterior: PropTypes.object,
	errors: PropTypes.any,
	margin: PropTypes.number,
	onChangeHandler: PropTypes.func,
	readOnly: PropTypes.bool,
	readOnlyVersao: PropTypes.any,
	filter: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	onChangeComentarioHandler: PropTypes.func,
	usuarioInterno: PropTypes.bool,
	showTextoCondicionante: PropTypes.bool,
	showTextoRevisor: PropTypes.bool,
	origem: PropTypes.string
};

function AvisoAlteracaoRevisor() {
	return (
		<div className="warning-message ">
			<i
				className="fa fa-exclamation-triangle"
				title={'Esta resposta foi alterada pelo revisor.'}
				style={{ fontSize: '18px', marginRight: '8px' }}
			/>
			Esta resposta foi alterada pelo revisor.
		</div>
	);
}
AvisoAlteracaoRevisor.displayName = 'AvisoAlteracaoRevisor';

function InfoMessage({ message, bloqueante }) {
	return (
		<p className={bloqueante ? 'observacao-le-error' : 'observacao-le'}>
			{bloqueante && <i className="fa fa-exclamation-circle" style={{ marginRight: '4px' }} />}
			{message}
		</p>
	);
}
InfoMessage.displayName = 'InfoMessage';
InfoMessage.propTypes = {
	message: PropTypes.string,
	bloqueante: PropTypes.bool
};

function AvisoAlteracaoRequerente() {
	return (
		<div className="warning-message ">
			<i
				className="fa fa-exclamation-triangle"
				title={'Esta resposta foi alterada pelo requerente.'}
				style={{ fontSize: '18px', marginRight: '8px' }}
			/>
			Esta resposta foi alterada pelo requerente.
		</div>
	);
}

AvisoAlteracaoRequerente.displayName = 'AvisoAlteracaoRequerente';

export const validarRestricoes = async (dados, tipoForm, idMetadata) => {
	const { restricoesAdministrativas, restricoesAdministrativasMetadata } = dados;
	const restricoesMeta =
		size(restricoesAdministrativasMetadata) > 0
			? restricoesAdministrativasMetadata
			: await fetchMetadataRestricoesAdministrativas(idMetadata);
	const { perguntas = [] } = restricoesMeta;

	const erros = [];
	const dfs = p => {
		const tiposForm = size(tipoForm) > 0 ? tipoForm.split(',').map(tf => tf.trim()) : [];
		if (size(p.filter) > 0 && !tiposForm.reduce((acc, tf) => acc || p.filter.includes(tf), false)) return;
		if (p.respostas) p.r = p.respostas.reduce(redutorRespostas, {});

		if (restricoesAdministrativas?.[p.campo] === undefined) {
			erros.push({ campo: p.campo, message: `Informar "${p.pergunta}"` });
		} else {
			const vr = restricoesAdministrativas[p.campo];
			const resposta = p.r[vr];
			if (resposta && resposta.bloqueante && resposta.textoEntrada) {
				erros.push({ campo: p.campo, message: resposta.textoEntrada });
				// console.log(p.campo, resposta, vr);
			}
			if (resposta && resposta.perguntas) {
				resposta.perguntas.forEach(dfs);
			}
		}
	};
	perguntas.forEach(dfs);
	return erros;
};

export const termoRestricoes = async (restricoesAdministrativas = {}, idMetadata) => {
	const restricoesMeta = await fetchMetadataRestricoesAdministrativas(idMetadata);
	const { perguntas = [] } = restricoesMeta;
	// const pReduced = perguntas.reduce(redutorPergunta, {});
	// const redutorPergunta = (acc, p) => ({ ...acc, [p.campo]: p });

	const avalia = (perguntas, pReduced) =>
		perguntas.reduce((acc, p) => {
			acc[p.campo] = p;
			if (p.respostas) {
				p.respostas.forEach(r => {
					if (r.perguntas) {
						avalia(r.perguntas, pReduced);
					}
				});
			}
			return acc;
		}, pReduced);
	const pReduced = avalia(perguntas, {});

	//console.log('preduced: ', pReduced);

	let textoTermo = '';
	Object.keys(pReduced).forEach(key => {
		const resposta = get(restricoesAdministrativas, `${key}`);
		const respostas = get(pReduced, `${key}.respostas`, []);
		const respostaMeta = respostas.find(r => r.codigo === resposta);

		if (!isNil(resposta) && get(respostaMeta, 'textoSumario')) {
			const { textoSumario = '' } = respostaMeta;
			let firstReplaceDone = false; // Indicador para verificar se o primeiro "DECLARO QUE" já foi substituído
			const updatedTextoSumario = textoSumario.replace(/DECLARO QUE/g, match => {
				if (!firstReplaceDone) {
					firstReplaceDone = true; // Marca que o primeiro "DECLARO QUE" foi substituído
					return `<br><strong>${match}</strong>`; // Formata os subsequentes sem adicionar quebras de linha
				} else {
					return `<br><br><strong>${match}</strong>`;
				}
			});
			textoTermo = `${textoTermo}<p style="margin-bottom:5px" >${
				isDebug && size(updatedTextoSumario.trim()) > 0 ? `(${key})<br>` : ''
			}${updatedTextoSumario}</p>`;
		}
	});

	//console.log('✌️textoTermo --->', textoTermo);

	return textoTermo;
};

export const fetchMetadataRestricoesAdministrativas = async idMetadata => {
	try {
		const url = `collections/metadata/${idMetadata}`;
		const retorno = (await accessApi(url))?.data;
		return retorno || {};
	} catch (e) {
		console.error(`Erro no acesso aos metadados de id "${idMetadata}"`, e);
	}
};
const flatPerguntas = (perguntas = []) =>
	perguntas.reduce((pv, cv) => {
		const { respostas = [], ...p } = cv;
		pv[cv.campo] = { ...p };
		if (respostas && respostas.length) {
			respostas.forEach(curr => {
				if (curr.perguntas) {
					const rr = flatPerguntas(curr.perguntas);
					Object.assign(pv, rr);
				}
			});
		}
		return pv;
	}, {});
const redutorRespostas = (acc, r) => ({ ...acc, [r.codigo]: r });
//const redutorPergunta = (acc, p) => ({ ...acc, [p.campo]: p });
