import React from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';

import { isDebug } from 'utils/tools';

export default function SelectField({
	label,
	labelEnum,
	required,
	name,
	value,
	isInvalid,
	disabled,
	readOnly,
	options,
	onChangeHandler,
	dataSet,
	dataPath = 'codigo'
}) {
	// mapeia para `codigo` o value
	let val = value && dataSet ? get(value, `${dataPath}`) : value;
	// value normal
	val = val && size(val) ? val : 'none';

	function onChangeSelect(ev) {
		let { value } = ev.target;
		value = value === 'none' ? undefined : value;
		// se informou um dataset busca nele o objeto completo como value
		value = dataSet !== undefined && value !== undefined ? dataSet.find(o => value === get(o, `${dataPath}`)) : value;

		onChangeHandler({ name, value });
		onChangeHandler({ name: 'errors', value: { [name]: validate(value, required, label) } });
	}

	return (
		<>
			<label className="control-label" htmlFor={`id_${name}`}>
				{labelEnum || label} {isDebug && name && <span className="debug-message">({name})</span>}
				{required && <span className="required">*</span>}
			</label>
			<select
				className={isInvalid ? 'form-control form-control-error' : 'form-control'}
				name={name}
				id={`id_${name}`}
				value={val}
				onChange={onChangeSelect}
				disabled={disabled || readOnly}
			>
				{options && (
					<>
						<option value="none">Selecione uma opção</option>
						{options.map(opt => (
							<option value={opt.codigo} key={opt.codigo}>
								{opt.descricao}
							</option>
						))}
					</>
				)}
			</select>
		</>
	);
}

SelectField.displayName = 'SelectField';

SelectField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.any,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func,
	forTemplate: PropTypes.bool,
	isInvalid: PropTypes.bool,
	readOnly: PropTypes.bool,
	dataPath: PropTypes.string,
	dataSet: PropTypes.array
};

function validate(value, required, label) {
	let errors = [];
	if (required) {
		if (!value || value.length === 0) {
			errors.push(`${label} deve ser informado`);
		}
	}
	return errors;
}
