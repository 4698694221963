import React from 'react';

import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import { size, trim } from 'lodash';

import 'tippy.js/dist/tippy.css'; // optional
import { isDebug } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const TextField = ({
	label,
	labelEnum,
	required,
	placeHolder,
	name,
	value,
	readOnly,
	disabled,
	onChangeHandler,
	isInvalid,
	tippy,
	links,
	style
}) => {
	const onChangeText = e => {
		const { value } = e.target;
		onChangeHandler([
			{ name, value },
			{ name: 'errors', value: { [name]: validate(value, required, label) } }
		]);
	};

	return (
		<>
			{(labelEnum || label) && (
				<label className="control-label">
					{labelEnum || label}
					{required && <span className="required">*</span>}{' '}
					{isDebug && name && <span className="debug-message">({name})</span>}
				</label>
			)}
			<div style={{ position: 'relative' }}>
				<input
					className={
						isInvalid === true
							? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
							: `${CLASS_NAME_FORM_CONTROL}`
					}
					style={style}
					type="text"
					placeholder={placeHolder}
					name={name}
					value={value || ''}
					onChange={onChangeText}
					readOnly={readOnly}
					disabled={disabled}
				/>
				{tippy && (
					<Tippy content={tippy} placement="right" animation="fade" duration={200}>
						<i className="fa fa-info-circle tippy-help-fields" aria-hidden="true"></i>
					</Tippy>
				)}
			</div>
			{size(links) > 0 && (
				<div className="links-help-fields">
					{links.map((link, index) => (
						<a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
							{link.label}
						</a>
					))}
				</div>
			)}
		</>
	);
};
TextField.displayName = 'TextField';

TextField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool,
	tippy: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	links: PropTypes.arrayOf(PropTypes.object),
	style: PropTypes.object
};

export default TextField;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		} else if (label.toLowerCase().startsWith('nome')) {
			if (size(trim(value).split(' ').map(trim)) < 2) {
				errors.push('Nome completo deve ser informado');
			}
		}
	}
	return errors;
};
