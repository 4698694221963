import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import useAxios from 'axios-hooks';
import { forEach, get, maxBy, size } from 'lodash';

import { EXPEDIENTES_API_URL } from 'environments';

import { CERTIFICADOS_SUSTENTAVEL } from 'utils/constants';
import { formatCodigoEU } from 'utils/tools';
import { montaURL } from 'utils/tools';

import seloBronze from '../../assets/images/selo_bronze.png';
import seloDiamante from '../../assets/images/selo_diamante.png';
import seloOuro from '../../assets/images/selo_ouro.png';
import seloPrata from '../../assets/images/selo_prata.png';

export function InfosExpedienteAP({ formulario }) {
	const expediente = get(formulario, 'formData.data.expediente');
	const enderecos = get(formulario, 'formData.data.enderecoCdlList', []);
	const isQuartoDistrito = enderecos.some(cdl => cdl.regiao === 'quarto_distrito');
	const isCentroHistorico = enderecos.some(cdl => cdl.regiao === 'centro_historico');
	const [certificado, setCertificado] = useState();

	// const tiposForm = (tipoForm.split(','): []).map(tf => tf.trim());
	// const showAreaPrivativa = !tiposForm.reduce((acc, tf) => acc || ['le2', 'le3'].includes(tf), false);
	const showAreaPrivativa = true;

	const [{ data: exped, loading: euLoading, error: euError }] = useAxios(
		`${EXPEDIENTES_API_URL}/eu/${get(expediente, '_id', '0')}`
	);

	useEffect(() => {
		if (exped && !euLoading && !euError) {
			// 2234 - bronze
			// 2235 - prata
			// 2236 - ouro
			// 2237 - diamante
			const codigosCertificacoes = {
				2234: CERTIFICADOS_SUSTENTAVEL.bronze,
				2235: CERTIFICADOS_SUSTENTAVEL.prata,
				2236: CERTIFICADOS_SUSTENTAVEL.ouro,
				2237: CERTIFICADOS_SUSTENTAVEL.diamante
			};
			const certificacoes = [];
			forEach(exped?.etapas, e => {
				const codigo = get(e, 'ocorrencia.codigo', '');
				const cert = get(codigosCertificacoes, `${codigo}`);
				if (cert) certificacoes.push(cert);
			});
			let certificado = maxBy(certificacoes, 'valor');
			setCertificado(certificado);
			// onCertificadoSustentavel && onCertificadoSustentavel(certificado);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exped, euLoading, euError]);

	return (
		<>
			{!!certificado && (
				<div className="selo">
					<img
						src={
							certificado.nivel === CERTIFICADOS_SUSTENTAVEL.diamante.nivel
								? seloDiamante
								: certificado.nivel === CERTIFICADOS_SUSTENTAVEL.ouro.nivel
								? seloOuro
								: certificado.nivel === CERTIFICADOS_SUSTENTAVEL.prata.nivel
								? seloPrata
								: seloBronze
						}
						alt={`selo sustentável ${certificado.nivel}`}
					/>
				</div>
			)}
			<fieldset>
				{/* <legend>Informações de Expediente Único / Endereços</legend> */}
				{formulario?.formData?.data?.expediente && (
					<div className="row id-ap id-ap-dinamico">
						<div className="col-auto exp-un">
							<label>Expediente Único </label>
							{size(get(expediente, 'numero')) > 0 ? (
								<a
									href={`${montaURL('expedientes')}/consultapublica/${formulario.formData.data.expediente._id}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									<span>{formatCodigoEU(get(expediente, 'numero'), true)}</span>
									<i style={{ marginLeft: '8px' }} className="fa fa-external-link" aria-hidden="true" />
								</a>
							) : (
								<span className="nao-informado">Não informado</span>
							)}
						</div>

						{showAreaPrivativa && (
							<div className="col area-priv">
								<label>
									Área Privativa <small>(unidade privativa/autônoma)</small>
								</label>
								{size(get(expediente, 'codigo')) > 0 ? (
									<span>{get(expediente, 'codigo', '').substr(12, 5)}</span>
								) : (
									<span className="nao-informado">Não informado</span>
								)}
							</div>
						)}
					</div>
				)}
				<div className="id-ap" style={formulario?.formData?.data?.expediente ? {} : { paddingTop: '20px' }}>
					<label>Endereço(s) da solicitação </label>
					{size(enderecos) > 0 && (
						<div className="endereco">
							<ul>
								{enderecos.map((item, index) => (
									<li key={index}>{item.enderecoFormatadoCurto}</li>
								))}
							</ul>
						</div>
					)}
					{isQuartoDistrito && <span>{'O endereço informado está localizado em área do 4º Distrito'}</span>}
					{isCentroHistorico && <span>{'O endereço informado está localizado em área do Centro Histórico'}</span>}
				</div>
				{!!certificado && (
					<div className="id-le">
						<label>Certificação em Sustentabilidade Ambiental</label>
						<p>O expediente possui o Certificação em Sustentabilidade Ambiental nível {certificado.nivel}</p>
					</div>
				)}
			</fieldset>
		</>
	);
}

InfosExpedienteAP.displayName = 'InfosExpedienteAP';
InfosExpedienteAP.propTypes = {
	formulario: PropTypes.object,
	onChangeHandler: PropTypes.func,
	data: PropTypes.object,
	tipoForm: PropTypes.string
};
