import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';

import { defaultTo, get } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import HistoricoRespostaAnterior from 'components/HistoricoRespostaAnterior';
import ShowDebug from 'components/ShowDebug';

import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE';

import { TIPOS_AREAS_LABELS_PLANILHA_ENUM } from './constantsAP';

const ZERO = { value: '0.00' };

function ResumoAreasAP({ data: dataProp, dataAnterior, errosSubmissao: errosSubmissaoProps, usuarioInterno }) {
	const errosSubmissao = useMemo(() => {
		if (!errosSubmissaoProps) {
			return [];
		}
		if (Array.isArray(errosSubmissaoProps)) {
			return errosSubmissaoProps;
		}
		let ouput = [];
		Object.keys(errosSubmissaoProps).forEach(key => {
			let newErro = {
				campo: key,
				message: errosSubmissaoProps[key]
			};
			ouput.push(newErro);
		});
		return ouput;
	}, [errosSubmissaoProps]);

	const detalhesAreas = useMemo(() => get(dataProp, 'detalhesAreas', {}), [dataProp]);
	const detalhesAreasAnterior = useMemo(() => get(dataAnterior, 'detalhesAreas', {}), [dataAnterior]);
	const areasPlanilha = useMemo(() => get(detalhesAreas, 'areasPlanilha'), [detalhesAreas]);
	const areasTotais = useMemo(() => get(detalhesAreas, 'areasTotais'), [detalhesAreas]);
	const areasTotaisAnterior = useMemo(() => get(detalhesAreasAnterior, 'areasTotais'), [detalhesAreasAnterior]);
	const economias = useMemo(() => get(detalhesAreas, 'economias'), [detalhesAreas]);
	const economiasAnterior = useMemo(() => get(detalhesAreasAnterior, 'economias'), [detalhesAreasAnterior]);
	const areaDemolir = useMemo(() => get(detalhesAreas, 'areaDemolir'), [detalhesAreas]);
	const areaDemolirAnterior = useMemo(() => get(detalhesAreasAnterior, 'areaDemolir'), [detalhesAreasAnterior]);
	const obtemTotal = useCallback(
		(property, obterHistorico = false) => {
			const total = ((obterHistorico ? economiasAnterior : economias) || []).reduce((accEco, economia) => {
				const valor = (economia.pavimentos || []).reduce((accPav, pavimento) => {
					const valor = (pavimento.naturezasEspecies || []).reduce((accNat, naturezaEspecie) => {
						const valor = Object.keys(naturezaEspecie).reduce((accArea, areaKey) => {
							const area = naturezaEspecie[areaKey];
							const valor = Object.keys(area).reduce((accTipo, tipoKey) => {
								const tipo = area[tipoKey];
								return accTipo + parseFloat(tipo?.[property]?.value || 0);
							}, 0);
							return accArea + valor;
						}, 0);
						return accNat + valor;
					}, 0);
					return accPav + valor;
				}, 0);
				return accEco + valor;
			}, 0);
			return { value: total.toFixed(2) };
		},
		[economias, economiasAnterior]
	);

	const totalAdensavel = obtemTotal('mtAdensavel');
	const totalAdensavelAnterior = obtemTotal('mtAdensavel', true);
	const totalNaoAdensavel = obtemTotal('mtNaoAdensavel');
	const totalNaoAdensavelAnterior = obtemTotal('mtNaoAdensavel', true);
	const totalIsenta = obtemTotal('mtIsenta');
	const totalIsentaAnterior = obtemTotal('mtIsenta', true);
	const areaTotal = { value: get(areasTotais, 'total', 0).toFixed(2) };
	const areaTotalAnterior = { value: get(areasTotaisAnterior, 'total', 0).toFixed(2) };

	return (
		<>
			<legend className="legenda-titulo-secundario">Resumo de Áreas</legend>
			<table style={{ width: '100%', border: '1px solid cdcdcd', marginTop: '25px', margintBottom: '15px' }}>
				<tbody>
					{(areasPlanilha || [])
						.filter(areaKey => !!areasTotais?.[areaKey]?.total && areasTotais?.[areaKey]?.total !== 0)
						.map(areaKey => {
							const label = TIPOS_AREAS_LABELS_PLANILHA_ENUM[areaKey];
							const value = { value: areasTotais?.[areaKey]?.total?.toFixed(2) || '0,00' };
							const valueAnterior = { value: areasTotaisAnterior?.[areaKey]?.total?.toFixed(2) || '0,00' };
							return (
								<tr key={areaKey} style={{ verticalAlign: 'text-top' }}>
									<td>{label} (m²)</td>
									<td className="pergunta metragem">
										<NumberFieldLE value={defaultTo(value, ZERO)} decimals={2} readOnly={true} innerClass="flex-1" />
										<HistoricoRespostaAnterior
											dataAtual={value != null ? defaultTo(value, ZERO) : null}
											dataAnterior={valueAnterior != null ? defaultTo(valueAnterior, ZERO) : null}
											usuarioInterno={usuarioInterno}
										/>
										<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === areaKey)} />
									</td>
								</tr>
							);
						})}
					{totalAdensavel && totalAdensavel.value !== '0.00' && (
						<tr style={{ verticalAlign: 'text-top' }}>
							<td>Área total adensável (m²)</td>
							<td className="pergunta metragem">
								<NumberFieldLE
									value={defaultTo(totalAdensavel, ZERO)}
									decimals={2}
									readOnly={true}
									innerClass="flex-1"
								/>
								<HistoricoRespostaAnterior
									dataAtual={totalAdensavel != null ? defaultTo(totalAdensavel, ZERO) : null}
									dataAnterior={totalAdensavelAnterior != null ? defaultTo(totalAdensavelAnterior, ZERO) : null}
									usuarioInterno={usuarioInterno}
								/>
								<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'totalAdensavel')} />
							</td>
						</tr>
					)}
					{totalNaoAdensavel && totalNaoAdensavel.value !== '0.00' && (
						<tr style={{ verticalAlign: 'text-top' }}>
							<td>Área total não adensável (m²)</td>
							<td className="pergunta metragem">
								<NumberFieldLE
									value={defaultTo(totalNaoAdensavel, ZERO)}
									decimals={2}
									readOnly={true}
									innerClass="flex-1"
								/>
								<HistoricoRespostaAnterior
									dataAtual={totalNaoAdensavel != null ? defaultTo(totalNaoAdensavel, ZERO) : null}
									dataAnterior={totalNaoAdensavelAnterior != null ? defaultTo(totalNaoAdensavelAnterior, ZERO) : null}
									usuarioInterno={usuarioInterno}
								/>
								<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'totalNaoAdensavel')} />
							</td>
						</tr>
					)}
					{totalIsenta && totalIsenta.value !== '0.00' && (
						<tr style={{ verticalAlign: 'text-top' }}>
							<td>Área total isenta (m²)</td>
							<td className="pergunta metragem">
								<NumberFieldLE value={defaultTo(totalIsenta, ZERO)} decimals={2} readOnly={true} innerClass="flex-1" />
								<HistoricoRespostaAnterior
									dataAtual={totalIsenta != null ? defaultTo(totalIsenta, ZERO) : null}
									dataAnterior={totalIsentaAnterior != null ? defaultTo(totalIsentaAnterior, ZERO) : null}
									usuarioInterno={usuarioInterno}
								/>
								<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'totalIsenta')} />
							</td>
						</tr>
					)}
					{areaTotal && areaTotal.value !== '0.00' && (
						<tr style={{ verticalAlign: 'text-top' }}>
							<td>Área total (m²)</td>
							<td className="pergunta metragem">
								<NumberFieldLE value={defaultTo(areaTotal, ZERO)} decimals={2} readOnly={true} innerClass="flex-1" />
								<HistoricoRespostaAnterior
									dataAtual={areaTotal != null ? defaultTo(areaTotal, ZERO) : null}
									dataAnterior={areaTotalAnterior != null ? defaultTo(areaTotalAnterior, ZERO) : null}
									usuarioInterno={usuarioInterno}
								/>
								<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'areaTotal')} />
							</td>
						</tr>
					)}
					{areaDemolir && areaDemolir.value !== '0.00' && (
						<tr style={{ verticalAlign: 'text-top' }}>
							<td>Área total a demolir (m²)</td>
							<td className="pergunta metragem">
								<NumberFieldLE value={defaultTo(areaDemolir, ZERO)} decimals={2} readOnly={true} innerClass="flex-1" />
								<HistoricoRespostaAnterior
									dataAtual={areaDemolir != null ? defaultTo(areaDemolir, ZERO) : null}
									dataAnterior={areaDemolirAnterior != null ? defaultTo(areaDemolirAnterior, ZERO) : null}
									usuarioInterno={usuarioInterno}
								/>
								<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'areaDemolir')} />
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<ShowDebug data={{ areasPlanilha, areasTotais, economias }} console />
		</>
	);
}
ResumoAreasAP.displayName = 'ResumoAreasAP';
ResumoAreasAP.propTypes = {
	data: PropTypes.object,
	dataAnterior: PropTypes.object,
	tipoForm: PropTypes.string,
	errosSubmissao: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]),
	usuarioInterno: PropTypes.bool
};

export default ResumoAreasAP;
