import { LE_Enquadramentos } from 'containers/LicenciamentoExpresso/MetadataLE';

import { includesTipoForm } from '../InfosProjetoAP';

// planilha
export const TIPOS_AREAS_ENUM = {
	permanecer: 'permanecer',
	reciclar: 'reciclar',
	regularizarGrpddua: 'regularizarGrpddua',
	regularizar: 'regularizar',
	construir: 'construir'
};
export const TIPOS_AREAS = Object.keys(TIPOS_AREAS_ENUM).map(key => TIPOS_AREAS_ENUM[key]);

const TIPOS_AREAS_LABELS_ENUM = {
	[TIPOS_AREAS_ENUM.permanecer]: {
		planilha: 'Área a Permanecer (m²)',
		opcao: {
			descricao: 'Área existente a permanecer',
			observacao:
				'Edificação que possui habite-se (carta de habitação) ou é comprovadamente existente há mais de 20 ' +
				'(vinte) anos, pelos registros dos cadastros do Município ou por documentos comprobatórios conforme o art. ' +
				'159-B da LC 434/1999 alterada pela LC 646/2010.',
			observacaoAlways: true
		}
	},
	[TIPOS_AREAS_ENUM.reciclar]: {
		planilha: 'Área a Reciclar (m²)',
		opcao: {
			descricao: 'Área existente a reciclar',
			observacao:
				'Edificação que possui habite-se (carta de habitação) ou é comprovadamente existente há mais de 20 ' +
				'(vinte) anos, pelos registros dos cadastros do Município ou por documentos comprobatórios conforme o art. ' +
				'159-B da LC 434/1999 alterada pela LC 646/2010 que irá alterar sua atividade.',
			observacaoAlways: true
		}
	},
	[TIPOS_AREAS_ENUM.regularizarGrpddua]: {
		planilha: 'Área a Regularizar - GRIPDDUA (m²)',
		opcao: {
			descricao: 'Área a regularizar conforme parecer 04/2023 do GRIPDDUA',
			observacao:
				'Parecer do Grupo de Regulamentação e Interpretação do Plano Diretor de Desenvolvimento Urbano Ambiental nº 004/2023 - $link',
			observacaoLink: {
				url: 'https://prefeitura.poa.br/sites/default/files/usu_doc/sites/escritorio-de-licenciamento/Parecer%20GRIPDDUA%20n%C2%BA%20004-2023%20-%20Aplica%C3%A7%C3%A3o%20art.%20159-B%20PDDUA.pdf',
				label: 'acessar documento',
				rel: 'noopener noreferrer',
				target: '_blank'
			},
			observacaoAlways: true
		}
	},
	[TIPOS_AREAS_ENUM.regularizar]: {
		planilha: 'Área a Regularizar (m²)',
		opcao: {
			descricao: 'Área a regularizar',
			observacao: 'Área já construída, não regular e que não se enquadra nos critérios de "Existente".',
			observacaoAlways: true
		}
	},
	[TIPOS_AREAS_ENUM.construir]: {
		planilha: 'Área a construir (m²)',
		opcao: { descricao: 'Área a construir' }
	}
};
export const TIPOS_AREAS_LABELS_PLANILHA_ENUM = Object.keys(TIPOS_AREAS_LABELS_ENUM).reduce(
	(acc, key) => ({
		...acc,
		[key]: TIPOS_AREAS_LABELS_ENUM[key].opcao.descricao
	}),
	{}
);

const TIPOS_AREAS_LABELS_OPCOES_ENUM = Object.keys(TIPOS_AREAS_LABELS_ENUM).reduce(
	(acc, key) => ({
		...acc,
		[key]: TIPOS_AREAS_LABELS_ENUM[key].opcao
	}),
	{}
);

export const OPT_AREAS_PLANILHA = Object.keys(TIPOS_AREAS_ENUM).map(key => ({
	codigo: key,
	...TIPOS_AREAS_LABELS_OPCOES_ENUM[key]
}));

export const TIPOS_METRAGENS_ENUM = {
	mtAdensavel: 'Ad (m²)',
	mtNaoAdensavel: 'Nad (m²)',
	mtIsenta: 'Isenta (m²)'
};

export const TIPOS_ECONOMIAS_ENUM = {
	residencial: 'residencial',
	naoResidencial: 'naoResidencial'
};

export const TIPOS_ECONOMIAS = [TIPOS_ECONOMIAS_ENUM.residencial];

export const TIPOS_ECONOMIAS_COM_NAO_RESIDENCIAL = [
	TIPOS_ECONOMIAS_ENUM.residencial,
	TIPOS_ECONOMIAS_ENUM.naoResidencial
];

// enquadramentos
const UNIFAMILIAR_AUTONOMA = 'unifamiliarAutonoma';
const UNIFAMILIAR_01_ECONO = 'unifamiliar01economia';
const UNIFAMILIAR_02_ECONO = 'unifamiliar02economias';
const MULTIFAMILIAR = 'multifamiliar';
const NAO_RESIDENCIAL = 'naoresidencial';
const UNIFAMILIAR_NAO_RESIDENCIAL = 'unifamiliar_nao_residencial';
const MULTIFAMILIAR_NAO_RESIDENCIAL = 'multifamiliar_nao_residencial';
const HABITACAO_COLETIVA = 'habitacaoColetiva';

export const UAP_ENQUADRAMENTOS_ENUM = {
	UNIFAMILIAR_AUTONOMA,
	UNIFAMILIAR_01_ECONO,
	UNIFAMILIAR_02_ECONO,
	MULTIFAMILIAR,
	NAO_RESIDENCIAL,
	UNIFAMILIAR_NAO_RESIDENCIAL,
	MULTIFAMILIAR_NAO_RESIDENCIAL,
	HABITACAO_COLETIVA
};

export const UAP_LABELS_ENUM = {
	[UNIFAMILIAR_AUTONOMA]: 'Habitação Unifamiliar em Condomínio (Unidade Autônoma)',
	[UNIFAMILIAR_01_ECONO]: 'Formulário Habitação Unifamiliar 1 economia',
	[UNIFAMILIAR_02_ECONO]: 'Formulário Habitação Unifamiliar 2 economias',
	[MULTIFAMILIAR]: 'Formulário Habitação Multifamiliar',
	[NAO_RESIDENCIAL]: 'Formulário Não Residencial',
	[UNIFAMILIAR_NAO_RESIDENCIAL]: 'Formulário Habitação Unifamiliar + Não Residencial',
	[MULTIFAMILIAR_NAO_RESIDENCIAL]: 'Formulário Habitação Multifamiliar + Não Residencial',
	[HABITACAO_COLETIVA]: 'Formulário Habitação Coletiva'
};
export const TODOS_ENQUADRAMENTOS = Object.values(UAP_ENQUADRAMENTOS_ENUM);
export const ENQUADRAMENTOS_SEM_AUTONOMA = Object.values(UAP_ENQUADRAMENTOS_ENUM).filter(
	e => e !== UNIFAMILIAR_AUTONOMA
);

export const AVISO_ESTUDO =
	'Devido a atividade estar listada no ANEXO 11 e NÃO TER ESTUDO DE VIABILIDADE URBANÍSTICO (EVU) APROVADO E VÁLIDO, deverá solicitar Estudo Viabilidade Urbanística (EVU) no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).';
export const ITEM_HABITACAO_UNIFAMILIAR = '1.1.';
export const ITEM_HABITACAO_COLETIVA = '1.2.';

// atividades do Decreto Municipal nº 18.572/2014
export const ATV_DECRETO_ENTRETENIMENTO_NOTURNO = ['2.1.2.', '3.3.'];
// atividades de interferência ambiental
export const ATV_INTERFERENCIA_AMBIENTAL = ['3.1.', '3.2.'];
// atividades ensino escolar
export const ATV_ENSINO_ESCOLAR = ['3.2.'];
// atividades revenda/posto gas
export const ATV_REVENDA_POSTO_GAS = [
	'2.1.2.',
	'2.1.2.3.',
	'2.1.3.',
	'2.1.3.3.',
	'2.2.1.',
	'2.2.2.',
	'2.2.2.2.',
	'2.2.3.',
	'2.2.3.2.'
];

// ATIVIDADES PARA OS FORMULARIOS (SUBUNIDADES)
export const ATV_NAO_RESIDENCIAL_GERAL = [
	'2.1.1.',
	'2.1.2.',
	'2.1.3.',
	'2.2.1.',
	'2.2.2.',
	'2.2.3.',
	'3.1.',
	'3.2.',
	'3.2.',
	'3.3.',
	'3.4.',
	'4.1.1.',
	'4.1.2.'
];
export const ATV_NAO_RESIDENCIAL_ESPECIAIS_E_INDUSTRIA = [
	'4.2.',
	'5.1.',
	'5.2.',
	'5.3.',
	'5.4.',
	'5.5.',
	'5.6.',
	'5.7.',
	'5.8.',
	'5.9.',
	'5.10.',
	'5.11.',
	'5.12.',
	'5.13.',
	'5.14.',
	'5.15.',
	'5.16.'
];
export const ATV_UNIFAMILIAR = ['1.1.'];
export const ATV_COLETIVA = ['1.2.'];
export const ATV_MULTIFAMILIAR = ['1.3.'];
export const ATV_NAO_RESIDENCIAL = [...ATV_NAO_RESIDENCIAL_GERAL, ...ATV_NAO_RESIDENCIAL_ESPECIAIS_E_INDUSTRIA];
export const ATV_UNIFAMILIAR_NAO_RESIDENCIAL = [...ATV_UNIFAMILIAR, ...ATV_NAO_RESIDENCIAL_GERAL];
export const ATV_MULTIFAMILIAR_NAO_RESIDENCIAL = [...ATV_MULTIFAMILIAR, ...ATV_NAO_RESIDENCIAL];

// CONFIGURAÇÕES DE FORMULARIOS
const {
	UNIFAMILIAR_AUTONOMA: UNIFAMILIAR_AUTONOMA_LE, // le1
	UNIFAMILIAR_01_ECONO: UNIFAMILIAR_01_ECONO_LE, // le2
	UNIFAMILIAR_02_ECONO: UNIFAMILIAR_02_ECONO_LE, // le3
	UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES: UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES_LE, // le4
	NAO_RESIDENCIAL: NAO_RESIDENCIAL_LE // le5
} = LE_Enquadramentos;
export const TIPO_FORM_CONFIG = [...Object.values(UAP_ENQUADRAMENTOS_ENUM), ...Object.values(LE_Enquadramentos)].reduce(
	(acc, codigo) => {
		// variáveis auxiliares
		/*
		Enquadramentos UAP
		- UNIFAMILIAR_AUTONOMA = 'unifamiliarAutonoma'
		- UNIFAMILIAR_01_ECONO = 'unifamiliar01economia'
		- UNIFAMILIAR_02_ECONO = 'unifamiliar02economias'
		- MULTIFAMILIAR = 'multifamiliar'
		- NAO_RESIDENCIAL = 'naoresidencial'
		- UNIFAMILIAR_NAO_RESIDENCIAL = 'unifamiliar_nao_residencial'
		- MULTIFAMILIAR_NAO_RESIDENCIAL = 'multifamiliar_nao_residencial'
		- HABITACAO_COLETIVA = 'habitacaoColetiva'

		Enquadramentos LE
		- UNIFAMILIAR_AUTONOMA_LE = 'le1'
		- UNIFAMILIAR_01_ECONO_LE = 'le2'
		- UNIFAMILIAR_02_ECONO_LE = 'le3'
		- UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES_LE = 'le4'
		- NAO_RES = 'le5'
		*/

		const isUnifamiliarEconomiasFixas = includesTipoForm(
			[
				UNIFAMILIAR_01_ECONO,
				UNIFAMILIAR_02_ECONO,
				UNIFAMILIAR_AUTONOMA,
				HABITACAO_COLETIVA,
				UNIFAMILIAR_01_ECONO_LE,
				UNIFAMILIAR_02_ECONO_LE,
				UNIFAMILIAR_AUTONOMA_LE
			],
			codigo
		);
		// const isUnifamiliarAutonomaUle = includesTipoForm([UNIFAMILIAR_AUTONOMA_LE], codigo);
		// const isUnifamiliarAutonomaUap = includesTipoForm([UNIFAMILIAR_AUTONOMA], codigo);
		// const isUnifamiliarAutonoma = isUnifamiliarAutonomaUle || isUnifamiliarAutonomaUap;
		const includesNaoResidencial = includesTipoForm(
			[
				NAO_RESIDENCIAL,
				UNIFAMILIAR_NAO_RESIDENCIAL,
				MULTIFAMILIAR_NAO_RESIDENCIAL,
				NAO_RESIDENCIAL_LE,
				UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES_LE
			],
			codigo
		);

		// configurações de colunas

		const showColEco = !includesTipoForm(
			[UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES_LE, NAO_RESIDENCIAL_LE, UNIFAMILIAR_AUTONOMA_LE, UNIFAMILIAR_AUTONOMA],
			codigo
		);
		const showAddEco = showColEco && !isUnifamiliarEconomiasFixas;
		const showEdtEco = showColEco && !isUnifamiliarEconomiasFixas;
		const showColPav = true;
		const showAddPav = showColPav;
		const showColNatEsp = true;
		const showAddNatEsp = showColNatEsp;

		return {
			...acc,
			[codigo]: {
				tipoForm: codigo,
				showColEco,
				showAddEco,
				showEdtEco,
				showColPav,
				showAddPav,
				showColNatEsp,
				showAddNatEsp,
				showRes: ![NAO_RESIDENCIAL, NAO_RESIDENCIAL_LE].includes(codigo),
				showNaoRes: includesNaoResidencial
			}
		};
	},
	{}
);
