import React, { Fragment, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { get, pick, size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import useErrors from 'custom-hooks/useErrors';

import { isDebug } from 'utils/tools';

const CheckboxComponent = ({
	label,
	observacao,
	required,
	name: propsName,
	value: propsValue,
	options: propsOptions = [{ codigo: true, descricao: '' }],
	readOnly,
	readonlyClassName,
	labelClassName,
	disabled: disabledProps,
	onChangeHandler,
	stackOptions,
	showCodigo,
	layoutNovo,
	appendText,
	appendTextStyle
}) => {
	const [options] = useState((propsOptions || []).map(o => (typeof o === 'string' ? { codigo: o, descricao: o } : o)));

	const concentrado = useMemo(() => size(options) === 1 && !options[0].descricao, [options]);

	useEffect(() => {
		if (size(propsValue?.toString()) === 0) {
			if (size(propsOptions) === 1) {
				const defaultValue = typeof propsOptions[0] === 'object' ? propsOptions[0].defaultValue || false : false;
				if (defaultValue) {
					onChangeHandler(true, propsName);
				}
			} else {
				const newValue = [];
				propsOptions.forEach(o => {
					const defaultValue = typeof o === 'object' ? o.defaultValue || false : false;
					if (defaultValue) {
						newValue.push(o.codigo);
					}
				});
				onChangeHandler(newValue, propsName);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onCheckItem = e => {
		const { checked, value, name } = e.target;
		let valor;
		if (options.length === 1) {
			valor = checked;
		} else {
			valor = propsValue ? (Array.isArray(propsValue) ? (propsValue || []).filter(v => v !== value) : []) : [];
			if (checked) {
				valor.push(value);
			} else {
				const option = options.find(o => o.codigo === value);
				if (option) {
					if (size(option.options) > 0) {
						option.options.forEach(so => {
							if (valor.includes(so.codigo)) {
								const index = valor.findIndex(v => v === so.codigo);
								valor.splice(index, 1);
							}
						});
					}
				}
			}
			valor = valor.sort((v1, v2) => (v1 < v2 ? -1 : 1));
		}
		onChangeHandler(valor, name);
	};

	const resposta =
		size(options) === 1
			? propsValue
				? 'sim'
				: 'não'
			: options.reduce((acc, o) => {
					const codigo = typeof o === 'object' ? o.codigo : o;
					const descricao = typeof o === 'object' ? o.descricao : o;
					return propsValue ? ((propsValue || []).includes(codigo) ? [...acc, descricao] : acc) : acc;
			  }, []);

	return size(options) > 0 ? (
		<>
			{readOnly && (
				<>
					{size(options) === 1 && typeof options[0].codigo === 'boolean' && (
						<div className={readonlyClassName}>
							<span>{label}</span>
							{required && <span className="required">*</span>}
						</div>
					)}
					<div className="row container-resposta-ap">
						<>
							{size(options) === 1 ? (
								typeof options[0].codigo === 'boolean' ? null : (
									<>
										<span>{options[0].descricao || label}</span>
										<span className="resposta-ap">{resposta}</span>
									</>
								)
							) : size(resposta) === 0 ? (
								<>
									<div>
										<span>{label}</span>
										{required && <span className="required">*</span>}
									</div>
									<span className="resposta-ap">não respondido</span>
								</>
							) : (
								<>
									<div>
										<span>{label}</span>
										{required && <span className="required">*</span>}
									</div>
									<span className="resposta-ap">{resposta.join(', ')}</span>
								</>
							)}
						</>
					</div>
				</>
			)}
			{observacao && (
				<div style={{ marginTop: '5px' }}>
					Observação: <span style={{ fontSize: '14px', fontWeight: 'bold', color: 'red' }}>{observacao}</span>
				</div>
			)}
			{!readOnly && (
				<>
					{!concentrado && (
						<>
							<span>{label}</span>
							{required && <span className="required">*</span>}
						</>
					)}
					<div
						className={`checkboxes-list${stackOptions ? ' stack' : ''}`}
						style={layoutNovo ? { marginBottom: '0' } : {}}
						id="div3"
					>
						{options.map((o, m) => (
							<CheckboxItem
								key={`${o.codigo ? o.codigo : 'CheckboxItem'}_checkbox_item_${m}`}
								keyValue={`${o.codigo ? o.codigo : 'CheckboxItem'}_checkbox_item_${m}`}
								option={concentrado ? { ...o, descricao: label } : o}
								readOnly={readOnly}
								stackOptions={stackOptions}
								propsValue={propsValue}
								propsName={propsName}
								labelClassName={labelClassName}
								disabledProps={disabledProps}
								qtdOptions={size(options)}
								onCheckItem={onCheckItem}
								showCodigo={showCodigo}
								appendText={appendText}
								appendTextStyle={appendTextStyle}
								layoutNovo={layoutNovo}
							/>
						))}
					</div>
				</>
			)}
		</>
	) : null;
};
CheckboxComponent.displayName = 'CheckboxComponent novo';

CheckboxComponent.propTypes = {
	label: PropTypes.string,
	observacao: PropTypes.string,
	required: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.bool]),
	readOnly: PropTypes.bool,
	readonlyClassName: PropTypes.string,
	labelClassName: PropTypes.string,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
	onChangeHandler: PropTypes.func,
	stackOptions: PropTypes.bool,
	showCodigo: PropTypes.bool,
	appendText: PropTypes.string,
	appendTextStyle: PropTypes.object,
	layoutNovo: PropTypes.bool
};

export default CheckboxComponent;

export const validate = () => {
	let errors = [];
	return errors;
};

const CheckboxItem = ({
	option,
	readOnly,
	onCheckItem,
	propsName,
	propsValue,
	labelClassName,
	disabledProps,
	stackOptions,
	qtdOptions,
	showCodigo,
	isSubitem,
	appendText,
	appendTextStyle,
	layoutNovo,
	options
}) => {
	const [errors] = useErrors();

	const codigo = typeof option === 'object' ? option.codigo : option;
	const descricao = typeof option === 'object' ? option.descricao : option;
	const descricaoComplementar = typeof option === 'object' ? option.descricaoComplementar : null;
	const warning = typeof option === 'object' ? option.warning : null;
	const disabled = typeof option === 'object' ? option.disabled || false : false;
	const suboptions = typeof option === 'object' ? option.options : null;
	const observacao = typeof option === 'object' ? option.observacao : null;
	const observacaoAlways = typeof option === 'object' ? option.observacaoAlways : false;
	const checked =
		qtdOptions === 1
			? propsValue === true
				? true
				: propsValue === false
				? false
				: size(propsValue) === 0
				? false
				: true
			: (propsValue || []).includes(codigo);

	let paiMarcado = false;
	if (isSubitem) {
		const pai = options.find(o => o.codigo === option.codigo) || {};
		paiMarcado = pai.checked;
	}

	return (
		<>
			{layoutNovo ? (
				<>
					{!isSubitem || (isSubitem && paiMarcado) ? (
						<div className="container-checkboxes" key={codigo}>
							<label
								className={`label-checkbox${isSubitem && paiMarcado ? ' subitem' : ''}${
									labelClassName ? ` ${labelClassName}` : ''
								}`}
							>
								<input
									type="checkbox"
									className="checkbox-input"
									value={codigo}
									id={`check-${propsName}-${codigo}`}
									name={propsName}
									readOnly={readOnly}
									disabled={disabled || disabledProps}
									checked={checked}
									onClick={onCheckItem}
									onChange={() => false}
								/>
								<span style={{ whiteSpace: 'normal' }}>
									{showCodigo ? `${codigo}. ` : ''}
									{descricao} {isDebug && propsName && <span className="debug-message">({propsName})</span>}
									{warning && checked && (
										<i
											className="fa fa-exclamation-triangle"
											title={warning}
											style={{
												fontSize: '18px',
												color: 'cornflowerblue'
											}}
										/>
									)}
								</span>
								{descricaoComplementar && <div style={{ fontSize: 'small' }}>{descricaoComplementar}</div>}
							</label>
							{(checked || observacaoAlways) && (
								<Observacao
									observacao={option.observacao}
									observacaoLink={option.observacaoLink}
									stackOptions={stackOptions}
								/>
							)}
							{size(errors) > 0 && <ErrorMessages errorList={get(errors, propsName, false)} />}
						</div>
					) : null}
				</>
			) : !isSubitem || (isSubitem && paiMarcado) ? (
				<Fragment key={codigo}>
					<label htmlFor={`check-${propsName}-${codigo}`} className="form-check-label">
						<div
							className={`checkbox-group${stackOptions ? ' wrap' : ''}`}
							key={codigo}
							style={isSubitem ? { marginLeft: '30px' } : {}}
						>
							<input
								type="checkbox"
								value={codigo}
								id={`check-${propsName}-${codigo}`}
								name={propsName}
								readOnly={readOnly}
								disabled={disabled || disabledProps}
								onClick={onCheckItem}
								onChange={() => false}
								checked={checked}
								className="form-check-input form-radio"
							/>
							<span className="checkmark"></span>
							<span style={{ whiteSpace: 'normal' }}>
								{showCodigo ? `${codigo}. ` : ''}
								{descricao} {isDebug && propsName && <span className="debug-message">({propsName})</span>}
								{warning && checked && (
									<i
										className="fa fa-exclamation-triangle"
										title={warning}
										style={{
											fontSize: '18px',
											color: 'cornflowerblue'
										}}
									/>
								)}
							</span>
							{descricaoComplementar && <div style={{ fontSize: 'small' }}>{descricaoComplementar}</div>}
							{appendText ? (
								<span className={'append-text'} style={appendTextStyle}>
									{appendText}
								</span>
							) : null}
						</div>
					</label>
					{observacao && checked && (
						<div style={stackOptions ? { marginTop: '-10px', marginLeft: '20px' } : { marginTop: '5px' }}>
							Observação:{' '}
							<span
								style={{
									fontSize: '14px',
									fontWeight: 'bold',
									color: 'red'
								}}
							>
								{observacao}
							</span>
						</div>
					)}
					{size(errors) > 0 && <ErrorMessages errorList={get(errors, propsName, false)} />}
				</Fragment>
			) : null}
			{checked &&
				size(suboptions) > 0 &&
				suboptions.map((o, j) => (
					<CheckboxItem
						key={`${o.codigo ? o.codigo : 'CheckboxItem'}_checkbox_checked_${j}`}
						keyValue={`${o.codigo ? o.codigo : 'CheckboxItem'}_checkbox_checked_${j}`}
						option={o}
						readOnly={readOnly}
						stackOptions={stackOptions}
						propsValue={propsValue}
						labelClassName={labelClassName}
						disabledProps={disabledProps}
						qtdOptions={size(suboptions)}
						showCodigo={showCodigo}
						onCheckItem={onCheckItem}
						isSubitem={true}
						appendText={appendText}
						appendTextStyle={appendTextStyle}
						layoutNovo={layoutNovo}
						options={options}
					/>
				))}
		</>
	);
};
CheckboxItem.displayName = 'CheckboxItem';
CheckboxItem.propTypes = {
	option: PropTypes.object,
	readOnly: PropTypes.bool,
	onCheckItem: PropTypes.func,
	propsName: PropTypes.string,
	propsValue: PropTypes.any,
	labelClassName: PropTypes.string,
	disabledProps: PropTypes.bool,
	stackOptions: PropTypes.bool,
	qtdOptions: PropTypes.number,
	showCodigo: PropTypes.bool,
	isSubitem: PropTypes.bool,
	appendText: PropTypes.string,
	appendTextStyle: PropTypes.object,
	layoutNovo: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
};

const Observacao = ({ observacao, observacaoLink, stackOptions }) => {
	if (!observacao) return null;
	const partes = observacao.split('$link');
	return (
		<div style={stackOptions ? { marginTop: '-10px', marginLeft: '26px' } : { marginTop: '5px' }}>
			<span className="checkbox-component-novo-layout-observacao-option">
				{partes[0]}
				{size(partes) > 1 && (
					<>
						<a href={`${observacaoLink.url}`} {...pick(observacaoLink, ['rel', 'target'])}>
							{observacaoLink.label}{' '}
							{observacaoLink.target === '_blank' && <i className="fa fa-external-link" aria-hidden="true"></i>}
						</a>
						{partes[1]}
					</>
				)}
			</span>
		</div>
	);
};
Observacao.displayName = 'Observacao';
Observacao.propTypes = {
	observacao: PropTypes.string,
	observacaoLink: PropTypes.object,
	stackOptions: PropTypes.bool
};
