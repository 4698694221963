import React from 'react';

import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';

import TooltipIcon from 'components/TooltipIcon';

import { isDebug } from 'utils/tools';

import 'tippy.js/dist/tippy.css';

const lineBreak = text =>
	text.split('\n').map((item, key) => (
		<span key={key}>
			{item}
			<br />
		</span>
	));

const CheckboxFieldLE = ({
	label,
	required,
	name,
	value,
	options,
	readOnly,
	disabled,
	onChangeHandler,
	labelEnum,
	stacked,
	tooltip,
	sempreEnviaCodigo
}) => {
	const onCheckItem = (e, codigo) => {
		const { checked } = e.target;
		let valor;
		if (options.length === 1 && !sempreEnviaCodigo) {
			valor = checked;
		} else {
			valor = (value || []).filter(v => v !== codigo);
			if (checked) {
				valor.push(codigo);
			}
		}

		onChangeHandler({ name, value: valor });
		onChangeHandler({ name: 'errors', value: { [name]: validate(codigo) } });
	};

	let infos = [];
	return (
		<>
			<div className="d-flex w-full">
				<label>
					{labelEnum || label} {isDebug && name && <span className="debug-message">({name})</span>}
					{required && <span className="required">*</span>}
					{tooltip && (
						<Tippy
							content={
								<p className="text-left px-3" style={{ whiteSpace: 'normal' }}>
									{lineBreak(tooltip)}
								</p>
							}
							delay={100}
							animation="fade"
						>
							<i className="fa fa-info-circle" aria-hidden="true" style={{ marginLeft: '4px' }}></i>
						</Tippy>
					)}
				</label>
				<div className={`d-flex ${stacked ? 'flex-column' : ''} `}>
					{options.map(o => {
						let codigo = typeof o === 'object' ? o.codigo : o;
						const descricao = typeof o === 'object' ? o.descricao : o;
						const iconWarning = typeof o === 'object' ? o.warning : null;

						const infoOnChecked = typeof o === 'object' ? o.infoOnChecked : null;

						const checked = Array.isArray(value)
							? !!(value || []).find(v => (typeof v === 'object' ? v.codigo === codigo : v === codigo))
							: !!value;

						if (infoOnChecked && !infos.includes(infoOnChecked) && checked) {
							infos.push(infoOnChecked);
						}

						return (
							<div className="form-check" key={codigo}>
								<label className="form-check-label">
									<input
										className="form-check-input"
										type="checkbox"
										name={name}
										onClick={e => onCheckItem(e, codigo)}
										readOnly={readOnly}
										disabled={disabled}
										onChange={() => false}
										checked={checked}
									/>
									{iconWarning && value === codigo ? (
										<TooltipIcon
											label={descricao}
											text={iconWarning}
											icon="fa-exclamation-triangle"
											iconColor="#b39d5b"
										/>
									) : (
										descricao
									)}
								</label>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};
CheckboxFieldLE.displayName = 'CheckboxFieldLE';

CheckboxFieldLE.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool,
	stacked: PropTypes.bool,
	tooltip: PropTypes.string,
	sempreEnviaCodigo: PropTypes.bool
};

export default CheckboxFieldLE;

export const validate = () => {
	let errors = [];
	return errors;
};
